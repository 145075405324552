import React, {useState } from "react";
import CasinoListNav from "../../components/CasinoListNav/CasinoListNav";
import Footer from "../../components/Footer/Footer";
import {useSelector } from "react-redux";
import Loader from "../../components/Loader/Loader";
import RightSidebar from "../../components/RightSidebar/RightSidebar";

function LiveCasino() {
    const {loading} = useSelector((state) => state.authentication);
    const [activeCasinoTab, setActiveCasinoTab] = useState(5000);

    return (
        <>
        {loading && <Loader/>}
            <div className="row">
                <div className="score-tabcontent-work">
                    <div>
                        {/* <CasinoImages activeCasinoTab={activeCasinoTab} /> */}
                        <CasinoListNav activeCasinoTab={activeCasinoTab} setActiveCasinoTab={setActiveCasinoTab} />
                    </div>
                    <Footer/>
                </div>

                {/* <div className="col-lg-3 score-tabbets ">
                    <RightSidebar />
                </div> */}
            </div>
        </>
    )
}

export default LiveCasino;
