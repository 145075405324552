import { Route, Routes } from "react-router-dom"
import Header from "../components/Header/Header"
import Sidebar from "../components/Sidebar/Sidebar"

import Inplay from "../pages/PublicPages/Inplay/Inplay"
import AllSportsHighlight from "../pages/PublicPages/AllSportsHighlight/AllSportsHighlight"
import Layout from "./Layout"
import PrivateRoute from "./PrivateRoute"
import RightSidebar from "../components/RightSidebar/RightSidebar"
import Dashboard from "../pages/PublicPages/Dashboard/Dashboard"
import TipsAndPreview from "../pages/PublicPages/TipsAndPreview/TipsAndPreview"
import Lottery from "../pages/PublicPages/Lottery/Lottery"
import { BalanceProvider } from "../ContextApi"
import Soccer from "../pages/Soccer/soccer"
import LiveCasino from "../pages/LiveCasino/Livecasino"
import Iframe from "../pages/Iframe"
import { useState } from "react"
import Footer from "../components/Footer/Footer"
import MobileMenu from "../components/Mobile-Menu/Mobilemenu"
import InplayPage from "../pages/Inplay/InplayPage.js"
import Signup from "../components/Signup/signup.js"
import ViewDetailsPageLogOut from "../pages/PublicPages/ViewDetailsPageLogOut/ViewDetailsPageLogOut.js"
import AccountPage from "../pages/AccountPage/AccountPage.js"
import SportsPage from "../pages/SportsPage/SportsPage.js"

function ParentRoute() {

  return (
    <>
      <BalanceProvider >
        <div className="div overflow-hidden">
          <div className="headerWrapper">
            <Header />
          </div>
          <div className="mainDiv">
            <div className="row bg-red mx-0">
              <div className="col-2  score-tab sideBarWrapper">
                <Sidebar/>
              </div>
              <div className="col-lg-10  scoretabcontent px-0">
                <div className="scrollPart">
                  <Routes>
                    <Route path="/:sportsId?" element={<Dashboard />} />
                    {/* <Route path="home" element={<Dashboard />} /> */}
                    {/* <Route path="inplay" element={<Inplay />} /> */}
                    <Route path="lottery" element={<Lottery />} />
                    <Route path="tips-and-preview" element={<TipsAndPreview />} />
                    <Route path="allsportshighlight/:sportsId?" element={<AllSportsHighlight />} />
                    <Route path="soccer" element={<Soccer />}></Route>
                    <Route path="live-casino" element={<LiveCasino />}></Route>
                    <Route path="app/*" element={<PrivateRoute element={<Layout />} />} />
                    <Route path="/iframe/:providerName/:gameId" element={<Iframe />}></Route>
                    {/* <Route path="/iframe" element={<Iframe />}></Route> */}
                    <Route path="inplay" element={<InplayPage/>}></Route>
                    <Route path="accountpage" element={<AccountPage />}></Route>
                    <Route path="sportspage" element={<SportsPage />}></Route>

                    <Route path="signUp" element={<Signup/>}></Route>
                    <Route path="viewdetail/:sportId/:matchId" element={<ViewDetailsPageLogOut/>}></Route>
                    {/* <Route path="accountpage" element={<AccountPage />}></Route>
                    <Route path="sportspage" element={<SportsPage />}></Route> */}
                  </Routes>
                </div>
              </div>

            </div>
          </div>
        </div>
        {/* <Footer/> */}
        <MobileMenu />
      </BalanceProvider>
    </>
  )
}

export default ParentRoute
