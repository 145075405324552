export const CONST = {
  // BACKEND_URL: 'https://api.allexchbets.com/v1/',
  // BACKEND_URL: 'https://api.sikander777.com/v1/',
  // BACKEND_URL: 'https://trovetown.in/api/v5/',
  // CASINO_URL: 'https://drem999.com/api/',
  // BACKEND_URL: 'https://drem999.com/api/v5/',
  // PAYMENT_URL: 'https://admin.drem999.com/storage/app/public/payment/',
  // IMAGE_URL: 'https://admin.drem999.com/storage/app/public/',
  CASINO_URL: 'https://cricindia.in/api/',
  BACKEND_URL: 'https://cricindia.in/api/v5/',
  IMAGE_URL: 'https://admin.cricindia.in/storage/app/public/',
  PAYMENT_URL: 'https://admin.cricindia.in/storage/app/public/payment/',
  // BACKEND_URL: 'https://leoxpro.com/api/v5/',
  // BACKEND_URL: 'https://api.crickexpo.pro/v1/',
  // SOCKET_URL: 'https://api.sikander777.com/',
};




