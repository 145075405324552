import React from 'react';
import './blink.css'; // Import the CSS file for styling

const Blink = () => {
    return (
        <div className="blinking-container">
            <span className="blinking-text">Inplay</span>
        </div>
    );
};

export default Blink;
