function Reward({ handleRollingModal }) {
    return (
        <div>
            <div className="reward-player">
                <h4 className="text-center mb-0">Player Level </h4>
                <div className="reward-player-data">
                    <div className="reward-player-datacopy d-flex flex-wrap justify-content-center ">
                        <div className=""><img src="/images/coin-1.jpg" /></div>
                        <div className=""><img src="/images/coin-2.png" /></div>
                    </div>
                </div>
            </div>
            <div className="roling-table border m-0">
                <div className="rolling-commission-div">
                    <h4> Withdraw 24*7 </h4>
                </div>

                <div className="commission-table">

                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col"><p>Total Turnover </p></th>
                                <th scope="col"><p>Total Reward</p></th>
                                <th scope="col"><p>Action</p></th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr>
                                <td scope="col"><p className="text-center">0</p></td>
                                <td scope="col"><p className="text-center">0</p></td>
                                <td scope="col"><button className="reward-settle">Settle</button></td>
                            </tr>
                        </tbody>

                    </table>
                </div>
            </div>
        </div>
    )
}

export default Reward;
