export const GameConstants = [
  {
    sportName: "cricket",
    sportId: 4,
  },
  {
    sportName: "soccer",
    sportId: 1,
  },
  {
    sportName: "tenis",
    sportId: 2,
  },
  // {
  //   sportName: "Lucky 7",
  //   sportId: 900021,
  //   providerName:'RG'
  // },
  // {
  //   sportName: "Baccarat",
  //   sportId: 100049,
  //   providerName:'EZUGI'
  // },
];
