import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { customerDetails, userchatlist, depositRequest } from "../../redux/_reducers/_reducers";
import moment from "moment";
import { toast } from "react-toastify";
import Loader from "../../components/Loader/Loader";

function Withdraw({ WithdrawClose }) {
    const [Isdeposit, Setdeposit] = useState(0);
    const [defaultAllData, setDefaultAllData] = useState()
    const [accountNumber, setAccountNumber] = useState("");
    const [ifscCode, setIfscCode] = useState("");
    const [accountHolderName, setAccountHolderName] = useState("");
    const [bankName, setBankName] = useState("");
    const [amount, setAmount] = useState("");
    const [phoneNo, setPhoneNo] = useState("");
    const [accountName, setAccountName] = useState("");
    const [amountUpi, setAmountUpi] = useState("");

    const [accountNumberError, setAccountNumberError] = useState('');
    const [ifscCodeError, setIfscCodeError] = useState('');
    const [accountHolderNameError, setAccountHolderNameError] = useState('');
    const [bankNameError, setBankNameError] = useState('');
    const [amountError, setAmountError] = useState('');

    const [phoneNoError, setPhoneNoError] = useState('');
    const [accountNameError, setAccountNameError] = useState('');
    const [amountUpiError, setAmountUpiError] = useState('');
    const [loading, setLoading] = useState(false)

    const dispatch = useDispatch();

    const handleAccountNumberChange = (e) => {
        setAccountNumber(e.target.value);
    };
    const handleIfscCodeChange = (e) => {
        setIfscCode(e.target.value);
    };
    const handleAccountHolderNameChange = (e) => {
        setAccountHolderName(e.target.value);
    };
    const handleBankNameChange = (e) => {
        setBankName(e.target.value);
    };
    const handleAmountChange = (e) => {
        const numericValue = Number(e.target.value);
        if (!isNaN(numericValue)) {
            setAmount(numericValue);
        } else {
            setAmount(0);
        }
    };
    const handlePhoneNoChange = (e) => {
        setPhoneNo(e.target.value);
    };

    const handleAccountNameChange = (e) => {
        setAccountName(e.target.value);
    };

    const handleAmountChangeUpi = (e) => {
        setAmountUpi(e.target.value);
    };

    const DepositClick = (index) => {
        Setdeposit(index);
        setPhoneNoError('');
        setAccountNameError('');
        setAmountUpiError('');
        setAccountNumberError('');
        setIfscCodeError('');
        setAccountHolderNameError('');
        setBankNameError('');
        setAmountError('');
    };

    const { defaultSettingData, customerDetailsData, userchatlistData } = useSelector((state) => state.authentication);


    const customerData = () => {
        const payload = { "type": "D" };
        dispatch(customerDetails(payload));
    }

    const userChatListData = () => {
        const data = {
            "limit": 15,
            "page": 1,
            "status": "AL",
            "type": "W"
        };
        dispatch(userchatlist(data));
    }

    useEffect(() => {
        userChatListData();
        customerData();
    }, [dispatch]);

    useEffect(() => {
        if (defaultSettingData) {
            setDefaultAllData(defaultSettingData);
        }
    }, [defaultSettingData]);

    const handleSubmitUpi = async (element) => {
        let isValid = true;
        setPhoneNoError('');
        setAccountNameError('');
        setAmountUpiError('');

        if (!phoneNo || phoneNo.length < 10) {
            setPhoneNoError('Please enter a valid Phone No./UPI with at least 10 digits.');
            isValid = false;
        }

        if (!accountName) {
            setAccountNameError('Please enter the account name.');
            isValid = false;
        }

        if (!amountUpi) {
            setAmountUpiError('Please enter a valid amount.');
            isValid = false;
        } else if (Number(amountUpi) < 100) {
            setAmountUpiError('Amount must be at least 100.');
            isValid = false;
        }

        if (!isValid) {
            console.error('Form validation failed');
            return;
        }
        setLoading(true)
        const data = {
            "amount": amountUpi,
            "account_number": phoneNo,
            "ifsc": '',
            "account_name": accountName,
            'type': "W",
            'user_id': 0,
            'transaction_id': '',
            'sender': '',
            'reciver': '',
            'dwtype': 'normal',
            'method': "UPI",
            "attachment": '',
        };

        try {
            await dispatch(depositRequest(data));
            userChatListData();

        } catch (error) {
            console.error("API call failed", error);
            toast.error("Submission failed. Please try again.");
        }

        setLoading(false)
        setPhoneNoError('');
        setAccountNameError('');
        setAmountUpiError('');

    };



    const handleSubmit = async (element) => {
        let isValid = true;

        setAccountNumberError('');
        setIfscCodeError('');
        setAccountHolderNameError('');
        setBankNameError('');
        setAmountError('');

        if (!accountNumber) {
            setAccountNumberError('Please enter an account number.');
            isValid = false;
        }

        if (!ifscCode) {
            setIfscCodeError('Please enter an IFSC code.');
            isValid = false;
        }

        if (!accountHolderName) {
            setAccountHolderNameError('Please enter the account holder name.');
            isValid = false;
        }

        if (!bankName) {
            setBankNameError('Please enter the bank name.');
            isValid = false;
        }
        if (!amount) {
            setAmountError('Please enter a valid amount.');
            isValid = false;
        } else if (Number(amount) < 100) {
            setAmountError('Amount must be at least 100.');
            isValid = false;
        }


        if (!isValid) {
            console.error('Form validation failed');
            return;
        }

        setLoading(true)
        const data = {
            "amount": Number(amount),
            "account_number": accountNumber,
            "ifsc": ifscCode,
            "account_name": accountHolderName,
            'type': "W",
            'user_id': 0,
            'transaction_id': '',
            'sender': '',
            'reciver': '',
            'dwtype': 'normal',
            'method': element?.account_type,
            "attachment": bankName
        };

        try {
            await dispatch(depositRequest(data));
            userChatListData();

        } catch (error) {
            console.error("API call failed", error);
            toast.error("Submission failed. Please try again.");
        }

        setLoading(false)
        setAccountNumberError('');
        setIfscCodeError('');
        setAccountHolderNameError('');
        setBankNameError('');
        setAmountError('');
    };

    return (
        <>
            {loading && <Loader />}
            <div className="row mx-auto w-100">
                <div className="col-lg-9 col-12 pe-lg-0 mt-3">
                    <div className="row mx-0">
                            <div className="col-12 px-0 score-tabcontent-work d-flex flex-column align-items-start ">
                                <div className="tabs w-100">
                                    <div className="tab-headers d-flex flex-wrap justify-content-center py-2 gap-2 bg-white rounded-2">
                                        {customerDetailsData && customerDetailsData.length > 0 ? customerDetailsData.map((element, index) => (
                                            <div
                                                onClick={() => DepositClick(index)}
                                                className={`deposit-tab cursor-pointer ${Isdeposit === index ? 'active-deposit text-white' : 'bg-gray-300'}`}>
                                                {element.account_type === "IMPS" ?
                                                    <>
                                                        <h4 className="text-uppercase">Account</h4>
                                                        <img src="/images/bank-account.png" />
                                                    </> :
                                                    <>
                                                        <h4 className="text-uppercase">upi</h4>
                                                        <img src="/images/upi-icon.png" />
                                                    </>}
                                            </div>
                                        ))
                                            : null}

                                    </div>
                                    {customerDetailsData && customerDetailsData.length > 0 ? customerDetailsData.map((element, index) => (
                                        <div className="tab-content mt-2">
                                            {Isdeposit === index &&
                                                <div>
                                                    {element.account_type === "IMPS" ?
                                                        <>
                                                            <div className="">
                                                                <div className='depositbank bg-white p-4 rounded-2'>
                                                                    <h3>BANK ACCOUNT</h3>
                                                                    <div className='row'>
                                                                        <div className="col-md-9 col-12 mx-auto mt-4 mt-sm-0">
                                                                            <div>
                                                                                <div className='depositform-data'>
                                                                                    <div className='input'>
                                                                                        <label>Account Number <span>*</span></label>
                                                                                        <input type='text' placeholder='Account Number'
                                                                                            className='d-block depositInp'
                                                                                            value={accountNumber}
                                                                                            onChange={handleAccountNumberChange}
                                                                                        />
                                                                                        {/* <span>Enter account number.</span> */}
                                                                                        {accountNumberError && <span>{accountNumberError}</span>}
                                                                                    </div>
                                                                                    <div className='input'>
                                                                                        <label>IFSC Code  <span>*</span></label>
                                                                                        <input type='text' placeholder='IFSC Code'
                                                                                            className='d-block depositInp'
                                                                                            value={ifscCode}
                                                                                            onChange={handleIfscCodeChange}
                                                                                        />
                                                                                        {/* <span>Enter IFSC code.</span> */}
                                                                                        {ifscCodeError && <span>{ifscCodeError}</span>}
                                                                                    </div>
                                                                                    <div className='input'>
                                                                                        <label> Account Holder Name   <span>*</span></label>
                                                                                        <input type='text' placeholder=' Account Holder Name '
                                                                                            className='d-block depositInp'
                                                                                            value={accountHolderName}
                                                                                            onChange={handleAccountHolderNameChange}
                                                                                        />
                                                                                        {/* <span>Enter account holder name.</span> */}
                                                                                        {accountHolderNameError && <span>{accountHolderNameError}</span>}
                                                                                    </div>
                                                                                    <div className='input'>
                                                                                        <label> Bank Name   <span>*</span></label>
                                                                                        <input type='text' placeholder='Bank Name  '
                                                                                            className='d-block depositInp'
                                                                                            value={bankName}
                                                                                            onChange={handleBankNameChange}
                                                                                        />
                                                                                        {/* <span>Enter bank Name .</span> */}
                                                                                        {bankNameError && <span>{bankNameError}</span>}
                                                                                    </div>
                                                                                    <div className='input'>
                                                                                        <label> Amount    <span>*</span></label>
                                                                                        <input type='text' placeholder=' Amount  '
                                                                                            className='d-block depositInp'
                                                                                            value={amount}
                                                                                            onChange={handleAmountChange}
                                                                                        />
                                                                                        {/* <span>Enter amount </span> */}
                                                                                        {amountError && <span>{amountError}</span>}
                                                                                    </div>
                                                                                    <div><div onClick={() => handleSubmit(element)} className="submitBtn submitBtnUpi">SUBMIT</div></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <ul className="noteWrapper mt-3 rounded-2">
                                                                    <li className="list">1.  Deposit money only in the below available accounts to get the fastest credits and avoid possible delays. </li>
                                                                    <li className="list">2.Deposits made 45 minutes after the account removal from the site are valid & will be added to their wallets.</li>
                                                                    <li className="list">3. Site is not responsible for money deposited to Old, Inactive or Closed accounts.</li>
                                                                    <li className="list">4. After deposit, add your UTR and amount to receive balance.</li>
                                                                    <li className="list">5 .NEFT receiving time varies from 40 minutes to 2 hours.</li>
                                                                    <li className="list">6. In case of account modification: payment valid for 1 hour after changing account details in deposit page.</li>
                                                                </ul>
                                                            </div>
                                                        </> : <>
                                                            <div className="">
                                                                <div className='depositbank bg-white p-4 rounded-2'>
                                                                    <h3>UPI</h3>
                                                                    <div className='row'>
                                                                        <div className="col-md-9 col-12 mx-auto mt-4 sm-mt-0">
                                                                            <div >
                                                                                <div className='depositform-data'>
                                                                                    <div className='input'>
                                                                                        <label>Phone No./UPI <span>*</span></label>
                                                                                        <input type='text' placeholder='Phone No./UPI '
                                                                                            className='d-block depositInp'
                                                                                            value={phoneNo}
                                                                                            onChange={handlePhoneNoChange}
                                                                                        />
                                                                                        {/* <span>Enter phone No./UPI .</span> */}
                                                                                        {phoneNoError && <span>{phoneNoError}</span>}
                                                                                    </div>
                                                                                    <div className='input'>
                                                                                        <label>Account Name  <span>*</span></label>
                                                                                        <input type='text' placeholder='Account Name '
                                                                                            className='d-block depositInp'
                                                                                            value={accountName}
                                                                                            onChange={handleAccountNameChange}
                                                                                        />
                                                                                        {/* <span>Enter account name .</span> */}
                                                                                        {accountNameError && <span>{accountNameError}</span>}
                                                                                    </div>
                                                                                    <div className='input'>
                                                                                        <label>Amount <span>*</span></label>
                                                                                        <input type='text' placeholder='Amount '
                                                                                            className='d-block depositInp'
                                                                                            value={amountUpi}
                                                                                            onChange={handleAmountChangeUpi}
                                                                                        />
                                                                                        {/* <span>Enter amount .</span> */}
                                                                                        {amountUpiError && <span>{amountUpiError}</span>}
                                                                                    </div>
                                                                                    <div><div onClick={() => handleSubmitUpi(element)} type="submit" className="submitBtn submitBtnUpi">SUBMIT</div></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <ul className="noteWrapper mt-3 rounded-2">
                                                                    <li className="list">1.  Deposit money only in the below available accounts to get the fastest credits and avoid possible delays. </li>
                                                                    <li className="list">2.Deposits made 45 minutes after the account removal from the site are valid & will be added to their wallets.</li>
                                                                    <li className="list">3. Site is not responsible for money deposited to Old, Inactive or Closed accounts.</li>
                                                                    <li className="list">4. After deposit, add your UTR and amount to receive balance.</li>
                                                                    <li className="list">5 .NEFT receiving time varies from 40 minutes to 2 hours.</li>
                                                                    <li className="list">6. In case of account modification: payment valid for 1 hour after changing account details in deposit page.</li>
                                                                </ul>
                                                            </div>
                                                        </>}
                                                </div>}
                                            {/* {Isdeposit === 1 &&
                                            <div>
                                                <>
                                                    <div className="">
                                                        <div className='depositbank bg-white p-4 rounded-2'>
                                                            <h3>UPI</h3>
                                                            <div className='row'>
                                                                <div className="col-sm-6 col-12">
                                                                    <div className='account-detail-deposit'>
                                                                        <div className='depositbank-detail'>
                                                                            <ul>
                                                                                <li className='d-flex flex-wrap justify-content-between'>
                                                                                    <div className='d-flex flex-wrap bankname'>
                                                                                        <div className="bankName">Name :</div>
                                                                                        <span className="bankSubName">Dataniya Rajesh</span>
                                                                                    </div>
                                                                                    <div className='bankcopy text-end'><IoCopyOutline /></div>
                                                                                </li>
                                                                                <li className='d-flex flex-wrap justify-content-between'>
                                                                                    <div className='d-flex flex-wrap bankname'>
                                                                                        <div className="bankName">UPI ID :</div>
                                                                                        <span className="bankSubName">dataniyarajesh@axl</span>
                                                                                    </div>
                                                                                    <div className='bankcopy text-end'><IoCopyOutline /></div>
                                                                                </li>
                                                                                <li className='d-flex flex-wrap justify-content-between'>
                                                                                    <div className='d-flex flex-wrap bankname'>
                                                                                        <div className="bankName">Min Amount :</div>
                                                                                        <span className="bankSubName">100</span>
                                                                                    </div>
                                                                                    <div className='bankcopy text-end'><IoCopyOutline /></div>
                                                                                </li>
                                                                                <li className='d-flex flex-wrap justify-content-between'>
                                                                                    <div className='d-flex flex-wrap bankname'>
                                                                                        <div className="bankName">Max Amount :</div>
                                                                                        <span className="bankSubName">1000000</span>
                                                                                    </div>
                                                                                    <div className='bankcopy text-end'><IoCopyOutline /></div>
                                                                                </li>
                                                                                <li>
                                                                                    <img className="qrimage" src={qr} alt="" />
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                    <div className="wpBtnWrapper">
                                                                        <button className="wpBtn w-100 d-flex flex-column align-items-center">FOR PAYMENT RELATED ISSUES <span className="wpAnimation"><RiWhatsappLine /></span></button>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-6 col-12 mt-4 sm-mt-0">
                                                                    <div>
                                                                        <div className='depositform-data'>
                                                                            <div className='input'>
                                                                                <label>Unique Transaction Reference <span>*</span></label>
                                                                                <input type='text' placeholder='6 to 12 Digits UTR Number' className='d-block depositInp' />
                                                                                <span>Enter valid UTR number.</span>
                                                                            </div>

                                                                            <div className="my-3 upload-container">
                                                                                <label htmlFor="paymentProof" className="form-label">
                                                                                    Upload Your Payment Proof{" "}
                                                                                    <span className="required-text">[Required]</span>
                                                                                </label>
                                                                                <input type="hidden" name="hiddenInput" />
                                                                                <input
                                                                                    type="file"
                                                                                    className="form-control custom-file-input"
                                                                                    id="paymentProof"
                                                                                    aria-describedby="paymentProofHelp"
                                                                                />
                                                                                <div></div>
                                                                                <div>
                                                                                    <p></p>
                                                                                </div>
                                                                            </div>

                                                                            <div className='input'>
                                                                                <label>Amount  <span>*</span></label>
                                                                                <input type='number' placeholder='6 to 12 Digits UTR Number' className='d-block depositInp' />
                                                                                <span>Enter valid UTR number.</span>
                                                                            </div>
                                                                            <div><button className="submitBtn">SUBMIT</button></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            </div>
                                        } */}
                                        </div>)) : null}
                                </div>
                            </div>
                    </div>
                </div>
                <div className="col-lg-3 col-12 pe-lg-0 mt-3">
                    <div className="row mx-0">
                        <div className="col-12 px-0">
                            <div className="scoreTabBest sideTable">
                                <div className="table-responsive overflow-y-auto h-100">
                                    <table className="table-bordered border-black">
                                        <thead>
                                            <tr className="bg-black text-white text-nowrap text-center">
                                                <th className="p-2">Account No.</th>
                                                <th>Amount</th>
                                                <th>Status</th>
                                                <th>Date</th>
                                                <th>Reason</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {userchatlistData && userchatlistData.length > 0 ? userchatlistData.map((element, index) => (
                                                <tr key={index} style={{ backgroundColor: '#f3f2f6', textWrap: 'nowrap' }}>
                                                    <td className="px-2">{element.accountNumber}</td>
                                                    <td className="px-2">{element.amount}</td>
                                                    <td className="px-2">
                                                        <div className="d-flex justify-content-center align-items-center m-2"
                                                            style={{
                                                                border: "2px solid",
                                                                borderColor:
                                                                    element.status === "D"
                                                                        ? "#FF0000"
                                                                        : element.status === "S"
                                                                            ? "#008000"
                                                                            : element.status === "A"
                                                                                ? "#0000FF"
                                                                                : "#FF0000",
                                                                color: "black",
                                                                padding: "5px",
                                                                borderRadius: "5px",
                                                            }}
                                                        >
                                                            {element.status === "D"
                                                                ? "Decline"
                                                                : element.status === "S"
                                                                    ? "Success"
                                                                    : element.status === "A"
                                                                        ? "Approve"
                                                                        : "Pending"}
                                                        </div>
                                                        {/* {element.status === "D" ? "Decline" : element.status === "S" ? "Success" : "Pending"} */}
                                                    </td>
                                                    <td className="px-2">{moment.unix(element.created_at).format("DD-MM-YYYY HH:MM:SS A")}</td>
                                                    <td className="px-2">{element.reason}</td>
                                                </tr>
                                            )) : null}


                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>                           
                </div>
            </div>
        </>
    )
}

export default Withdraw
