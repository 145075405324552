import { Route, Routes } from "react-router-dom";
import MyProfile from "../pages/AuthPages/MyProfile/MyProfile";
import RollingCommission from "../pages/AuthPages/RollingCommission/RollingCommission";
import BetHistory from "../pages/AuthPages/BetHistory/BetHistory";
import ProfitAndLoss from "../pages/AuthPages/ProfitAndLoss/ProfitAndLoss";
import PasswordHistory from "../pages/AuthPages/PasswordHistory/PasswordHistory";
import ActivityLog from "../pages/AuthPages/ActivityLog/ActivityLog";
import Settings from "../pages/AuthPages/Settings/Settings";
import Multimarket from "../pages/AuthPages/Multimarket/Multimarket";
import VirtualSports from "../pages/AuthPages/VirtualSports/VirtualSports";
import AccountStatement from "../pages/AuthPages/AccountStatement/AccountStatement";
import ViewDetailsPage from "../pages/PublicPages/ViewDetailsPage/ViewDetailsPage";
import NextLottery from "../pages/LotteryNext/LotteryNext";
import Payment from "../pages/Paymentdetails/Payment";
import Withdraw from "../pages/Withdraw/withdraw";
import Reward from "../pages/Reward/Reward";
import ChangePasswordModal from "../components/ChangePasswordModal/ChangePasswordModal";
import AccountPage from "../pages/AccountPage/AccountPage";
import SportsPage from "../pages/SportsPage/SportsPage";
import Signup from "../components/Signup/signup";
import Deposit from "../pages/Deposit/Deposit";
import ViewDetailsPageLogOut from "../pages/PublicPages/ViewDetailsPageLogOut/ViewDetailsPageLogOut";
import DepositeWithdrawHistory from "../pages/AuthPages/DepositeWithdrawHistory/DepositeWithdrawHistory";

function Layout() {

  return (
    <Routes>
      <Route path="my-profile" element={<MyProfile />} />
      <Route path="changePassword" element={<ChangePasswordModal />} />
      <Route path="account-statement" element={<AccountStatement />} />
      <Route path="rolling-commission" element={<RollingCommission />} />
      <Route path="bet-history/:type?" element={<BetHistory />} />
      <Route path="Profit-loss" element={<ProfitAndLoss />} />
      <Route path="password-history" element={<PasswordHistory />} />
      <Route path="activity-log" element={<ActivityLog />} />
      <Route path="settings" element={<Settings />} />
      <Route path="multi-market" element={<Multimarket />} />
      <Route path="virtual-sports" element={<VirtualSports />} />
      <Route path="view-details/:sportId?/:matchId?" element={<ViewDetailsPage />}></Route>
      <Route path="next-lottery" element={<NextLottery />}></Route>
      <Route path="payment" element={<Payment />}></Route>
      <Route path="withdraw" element={<Withdraw />}></Route>
      <Route path="depositWithdraw" element={<DepositeWithdrawHistory />}></Route>
      <Route path="reward" element={<Reward />}></Route>
      <Route path="deposite" element={<Deposit />}></Route>
      {/* <Route path="signUp" element={<Signup />}></Route> */}
    </Routes>
  );
}

export default Layout;
