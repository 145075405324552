import React, { useEffect, useState } from 'react';
import { IoMdSettings } from "react-icons/io";
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { getstackUpdate } from '../../../redux/_reducers/_reducers';
import RightSidebar from '../../../components/RightSidebar/RightSidebar';

function Settings() {

    const { stackUpdateData } = useSelector(
        (state) => state.authentication
    );
    const [matchStack, setMatchStack] = useState([]);
    const [inputValues, setInputValues] = useState([]);
    const dispatch = useDispatch();
    useEffect(() => {
        let storedMatchStack = localStorage.getItem('match_stack');
        if (!storedMatchStack) {
            storedMatchStack = "500,1000,5000,10000,50000,100000";
            localStorage.setItem('match_stack', storedMatchStack);
        }
        const matchStackArray = storedMatchStack.split(',');
        setMatchStack(matchStackArray);
        setInputValues(matchStackArray); // Initialize inputValues with matchStack values
    }, []);

    const handleInputChange = (e, index) => {
        const newValues = [...inputValues];
        newValues[index] = e.target.value;
        setInputValues(newValues);
    };


    const handleSubmit = async () => {
        const dataToPost = {
            "one_click_stack": "0",
            "match_stack": inputValues.join(','),
            "sport_id": "4"
        };

        try {
            const response = await dispatch(getstackUpdate(dataToPost)).unwrap();
            if (response.error === false) {
                localStorage.setItem('match_stack', inputValues.join(','))
                toast.success('Stack updated successfully.');
            } else {
                toast.error(`Stack updated Failed.`);
            }
        } catch (error) {
            toast.error(`Stack updated Failed.`);
        }
    };

    return (
        <div className='row mx-0'>
            <div className='col-12 px-0 settings-box px-2 pb-2'>
                <div className='setting-icon px-2'>
                    <p className='d-flex flex-wrap align-items-center mb-0'><IoMdSettings size={20} /><span className='ps-2'>Setting</span></p>
                </div>
                {/* <div className='setting-theme-heading'><h4>Theme</h4></div> */}
                {/* <div className='themes d-flex flex-wrap'>
                <div className='theme-data'>
                    <div className='theme-datawork d-flex justify-content-center'>
                        <div className='theme-dataround'><span></span></div>
                    </div>
                    <div className='theme-datawork d-flex justify-content-center'>
                        <div className='theme-dataround'><span></span></div>
                    </div>
                </div>

                <div className='theme-data'>
                    <div className='theme-datawork d-flex justify-content-center'>
                        <div className='theme-dataround'><span></span></div>
                    </div>
                    <div className='theme-datawork d-flex justify-content-center'>
                        <div className='theme-dataround'><span></span></div>
                    </div>
                </div>

                <div className='theme-data'>
                    <div className='theme-datawork d-flex justify-content-center'>
                        <div className='theme-dataround'><span></span></div>
                    </div>
                    <div className='theme-datawork d-flex justify-content-center'>
                        <div className='theme-dataround'><span></span></div>
                    </div>
                </div>

                <div className='theme-data'>
                    <div className='theme-datawork d-flex justify-content-center'>
                        <div className='theme-dataround'><span></span></div>
                    </div>
                    <div className='theme-datawork d-flex justify-content-center'>
                        <div className='theme-dataround'><span></span></div>
                    </div>
                </div>

                <div className='theme-data'>
                    <div className='theme-datawork d-flex justify-content-center'>
                        <div className='theme-dataround'><span></span></div>
                    </div>
                    <div className='theme-datawork d-flex justify-content-center'>
                        <div className='theme-dataround'><span></span></div>
                    </div>
                </div>

            </div> */}
                <div className='setting-theme-heading'><h4>Stakes</h4></div>

                <div className='quick-stack'>
                    <h5>Quick Stakes</h5>

                    <div className='d-flex flex-wrap gap-2'>
                        {inputValues.map((value, index) => (
                            <div key={index} className='quick-stackbtn'>
                                <input type="number"
                                    value={value}
                                    onChange={(e) => handleInputChange(e, index)} />
                            </div>
                        ))}
                    </div>

                    <button className='save' onClick={() => handleSubmit()}>Save</button>
                </div>
            </div>
            {/* <div className="col-lg-3 score-tabbets ">
            <RightSidebar />
            </div> */}
        </div>
    )
}

export default Settings


{/* <div className='d-flex flex-wrap'>
                <div className='quick-stackbtn'>
                   <div><input type='number'className='text-center'/></div>
                   <div className='mt-2'><input type='number'className='text-center'/></div>
                </div>
                <div className='quick-stackbtn'>
                   <div><input type='number'className='text-center'/></div>
                   <div className='mt-2'><input type='number'className='text-center'/></div>
                </div>
                <div className='quick-stackbtn'>
                   <div><input type='number'className='text-center'/></div>
                   <div className='mt-2'><input type='number'className='text-center'/></div>
                </div>
                <div className='quick-stackbtn'>
                   <div><input type='number'className='text-center'/></div>
                   <div className='mt-2'><input type='number'className='text-center'/></div>
                </div>
            </div> */}