import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux"; // Assuming you are using Redux for state management
import { login } from "../../../redux/_reducers/_reducers";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../../../components/Loader/Loader";
import { CONST } from "../../../middelware/Const";

function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [fieldsLogin, setFieldsLogin] = useState({
    username: "",
    password: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [usernameErrorMessage, setUsernameErrorMessage] = useState("");
  const {loading, defaultSettingData} = useSelector(
    (state) => state.authentication
  );
  const sitelogo = defaultSettingData?.find((item) => item.key === "site.LOGO");
  const inputChange = (e) => {
    const { name, value } = e.target;
    setFieldsLogin((prevFields) => ({
      ...prevFields,
      [name]: value,
    }));
    if (name === "username") {
      setUsernameErrorMessage("");
    } else if (name === "password") {
      setErrorMessage("");
    }
  };

  const loginSubmit = async (e) => {
    e.preventDefault();
    try {
      if (fieldsLogin.username.length === 0) {
        setUsernameErrorMessage("Username can't be empty");
        return;
      }
      if (fieldsLogin.password.length === 0) {
        setErrorMessage("Password can't be empty");
        return;
      }
      const data = {
      
          "user_name":fieldsLogin.username ,
          "password": fieldsLogin.password
    
        // ...fieldsLogin,
        // isClient: true,
        // host: window.location.host,
      };

      dispatch(login(data)).then((response) => {
        if (response && response?.payload?.userinfo) {
          setFieldsLogin({ username: "", password: "" });
          setErrorMessage("");
          navigate("/home");
        }
      });
    } catch (error) {
      console.error("Login error:", error);
    }
  };

  const handleDemoSubmit = (e) => {
    e.preventDefault();
    const data = {
      password: "123456",
      user_name: "user01",
      // isClient: true,
      // host: window.location.host,
    };

    dispatch(login(data)).then((response) => {
      if (response && response?.payload?.userinfo) {
        setFieldsLogin({ username: "", password: "" });
        setErrorMessage("");
        navigate("/home");
      }
    });
  };

  return (
    <>
    {loading && <Loader />}
      <section className="log-in-sec">
        <div className="login-off-btn">
          <Link to="/">
            <i className="fa fa-times" aria-hidden="true"></i>
          </Link>
        </div>
        <div className="login-container">
          <div className="container">
            <div className="log-in-work">
              <div className="log-in-logo">
                <img src={`${CONST.IMAGE_URL}${sitelogo?.value}`} alt="" />
              </div>
              <form onSubmit={loginSubmit}>
                <div className="log-in-input">
                  <div className="log-in-user position-relative">
                    <input
                      type="text"
                      placeholder="Username"
                      name="username"
                      value={fieldsLogin.username}
                      onChange={inputChange}
                    />
                    {usernameErrorMessage && (
                      <p
                        className="position-absolute text-danger"
                        style={{
                          color: "white",
                          height: "10px",
                          fontSize: "13px",
                          top: "40px",
                        }}
                      >
                        {usernameErrorMessage}
                      </p>
                    )}
                  </div>
                  <div className="log-in-password position-relative">
                    <input
                      type="password"
                      placeholder="Password"
                      name="password"
                      value={fieldsLogin.password}
                      onChange={inputChange}
                    />
                    {errorMessage && (
                      <p
                        className="position-absolute text-danger"
                        style={{
                          color: "white",
                          height: "10px",
                          fontSize: "13px",
                          top: "40px",
                        }}
                      >
                        {errorMessage}
                      </p>
                    )}
                  </div>
                </div>
                <div className="log-in-btn">
                  <button className="login-button" type="submit">
                    Login <i className="fa fa-sign-out" aria-hidden="true"></i>
                  </button>
                
                  <button onClick={handleDemoSubmit} className="mt-1">
                    Login With Demo ID
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Login;
