import { useEffect, useState } from "react"
import { myBetList } from "../../../redux/_reducers/_reducers"
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Loader from "../../../components/Loader/Loader";
import { useParams } from "react-router-dom";

function BetHistory() {
    const { type } = useParams();
    const dispatch = useDispatch();
    const [fromDate, setFromDate] = useState(moment().subtract(7, 'days').unix());
    const [toDate, setToDate] = useState(moment().unix());
    const [page, setPage] = useState(100);
    const [betHistoryData, setBetHistoryData] = useState([]);
    const [fetchedData, setFetchedData] = useState([]);
    const [betType, setBetType] = useState(type === "exposure" ? "P" : 'AL');
    const [sports, setSports] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const { myBetListData, loading } = useSelector(
        (state) => state.authentication
    );

    const handleBetTypeChange = (e) => {
        setBetType(e.target.value);
    };

    const handleSportsChange = (e) => {
        setSports(e.target.value);
    };

    useEffect(() => {
        if (myBetListData) {
            setBetHistoryData(myBetListData);
        }
    }, [myBetListData]);

    const changeData = (fetchedData) => {
        if (fetchedData) {
            const updatedLedgerData = fetchedData.map((data, index) => {
                return {
                    Date: moment.unix(data.Placed).utcOffset("+05:30").format("DD-MM-YYYY"),
                    SrNo: index + 1,
                    Eventname: data.matchName,
                    Nation: data.SelectionName,
                    Result: data.winResult ? data.winResult : "Not Declered",
                    Side: data.Type,
                    Odds: data.Odds,
                    Stack: data.Stack,
                    PL: data.Liability,
                    BetStatus: data.Liability > 0 ? "Win" : "Loss",
                    IpAddress: data.ipAddress,
                };
            });
            setFetchedData(updatedLedgerData);
        }
    };

    const handleSaveDates = async (e) => {
        if (e) e.preventDefault();
        if (fromDate && toDate) {
            const fetchData = async () => {
                try {
                    let reqData =
                    {
                        "from_date": fromDate,
                        "to_date": toDate,
                        "limit": page,
                        "sport_id": sports,
                        "betType": betType,
                        "pageno": 1,
                        "market_id": "0",
                        "match_id": 0
                    }
                    const response = await dispatch(myBetList(reqData));
                    setFetchedData(response.payload?.data);
                    changeData(response.payload?.data)
                } catch (err) {
                    console.error('Failed to fetch statement:', err);
                }
            };
            fetchData();
        }
    };

    useEffect(() => {
        handleSaveDates();
    }, [page]);

const handleDateChange = (e, type) => {
    const value = e.target.value; // Format is YYYY-MM-DD
    const unixTimestamp = Math.floor(new Date(value).getTime() / 1000); // Convert to Unix timestamp
    if (type === 'from') {
        setFromDate(unixTimestamp);
    } else if (type === 'to') {
        setToDate(unixTimestamp);
    }
};


    const handlePageNumber = (event) => {
        setPage(event.target.value);
    };


    return (
        <>
            <div className="score-tabcontent-work px-2">
                {loading && <Loader />}
                <form className="row rollingform1">
                    <div className="col-lg-6 col-md-6 col-6 my-profile-select">
                        <select value={betType} onChange={handleBetTypeChange}>
                            <option value="AL">Bet Type</option>
                            <option value="C">Complete(C)</option>
                            <option value="P">Pending(P)</option>
                        </select>
                    </div>
                    <div className="col-lg-6 col-md-6 col-6 my-profile-select  my-profile-select2">
                        <select value={sports} onChange={handleSportsChange}>
                            <option value="0">Sports</option>
                            <option value="4">Cricket</option>
                            <option value="2">Tennis</option>
                            <option value="1">Football</option>
                            <option value="111">Casino</option>
                        </select>
                    </div>
                    
                    <div className="col-lg-4 col-md-4  col-12  my-profile-select mt-3">
                        <input className="position-relative abc"
                            type="date" id="date"
                            value={moment.unix(fromDate).format("YYYY-MM-DD")}
                            onChange={(e) => handleDateChange(e, 'from')}
                        />
                    </div>

                    <div className="col-lg-4 col-md-4 col-12 my-profile-select mt-3">
                        <input className="position-relative abc" type="date" id="date"
                            value={moment.unix(toDate).format("YYYY-MM-DD")}
                            onChange={(e) => handleDateChange(e, 'to')}
                        />
                    </div>

                    <div className="col-lg-4 col-md-4 col-12 my-profile-select mt-3">
                        <button onClick={handleSaveDates} className="form-btn border-0">Get Commission</button>
                    </div>

                </form>
                <div className="account-statment-tablwork">
                    <div className="account-statment-table">
                        <div className="roling-table border">
                            <div className="rolling-commission-div">
                                <h4>Bet History</h4>
                            </div>
                            <div className="commission-table account-statment-tables">
                                <div className="account-lable d-flex flex-wrap align-items-center">
                                    <p className="mb-0">Show</p>
                                    <label className="mx-1">
                                        <select value={page}
                                            onChange={handlePageNumber}
                                        >
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select>
                                    </label>
                                    <p className="mb-0">entries</p>
                                </div>
                                <table className="table mb-0">
                                    <thead>
                                        <tr>
                                            <th scope="col">
                                                <div className="d-flex flex-nowrap align-items-center justify-content-center">
                                                    <p className="text-nowrap">Sports Name</p>
                                                    <i className="fa fa-sort" aria-hidden="true"></i>
                                                </div>
                                            </th>
                                            <th scope="col">
                                                <div className="d-flex flex-nowrap align-items-center justify-content-center">
                                                    <p className="text-nowrap">Event Name</p>
                                                    <i className="fa fa-sort" aria-hidden="true"></i>
                                                </div>
                                            </th>
                                            <th scope="col">
                                                <div className="d-flex flex-nowrap align-items-center justify-content-center">
                                                    <p className="text-nowrap">MarketName</p>
                                                    <i className="fa fa-sort" aria-hidden="true"></i>
                                                </div>
                                            </th>
                                            <th scope="col">
                                                <div className="d-flex flex-nowrap align-items-center justify-content-center">
                                                    <p className="text-nowrap">Slection</p>
                                                    <i className="fa fa-sort" aria-hidden="true"></i>
                                                </div>
                                            </th>
                                            <th scope="col">
                                                <div className="d-flex flex-nowrap align-items-center justify-content-center">
                                                    <p className="text-nowrap">Type</p>
                                                    <i className="fa fa-sort" aria-hidden="true"></i>
                                                </div>
                                            </th>
                                            <th scope="col">
                                                <div className="d-flex flex-nowrap align-items-center justify-content-center">
                                                    <p className="text-nowrap">Odds Req.</p>
                                                    <i className="fa fa-sort" aria-hidden="true"></i>
                                                </div>
                                            </th>
                                            <th scope="col">
                                                <div className="d-flex flex-nowrap align-items-center justify-content-center">
                                                    <p className="text-nowrap">Stack</p>
                                                    <i className="fa fa-sort" aria-hidden="true"></i>
                                                </div>
                                            </th>
                                            <th scope="col">
                                                <div className="d-flex flex-nowrap align-items-center justify-content-center">
                                                    <p className="text-nowrap">Place Time</p>
                                                    <i className="fa fa-sort" aria-hidden="true"></i>
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {betHistoryData && betHistoryData.length > 0 ? (
                                            betHistoryData.map((ele, i) => (

                                                <tr key={i} style={{
                                                    backgroundColor: ele.Type === "Back" ? "#beddf4" : "#f3dce2",
                                                    color: "#3b5160",
                                                    fontSize: "10px",
                                                }}>
                                                    <td><p className="text-center">{ele.sportName}</p></td>
                                                    <td><p className="text-center">{ele.seriesName}</p></td>
                                                    <td><p className="text-center">{ele.matchName}</p></td>
                                                    <td><p className="text-center">{ele.SelectionName}</p></td>
                                                    <td><p className="text-center">{ele.Type}</p></td>
                                                    <td><p className="text-center">{ele.Odds}</p></td>
                                                    <td><p className="text-center">{ele.Stack}</p></td>
                                                    <td><p className="text-center">{moment.unix(ele.Placed).format("DD-MM-YYYY HH:MM A")}</p></td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colspan="8" className="text-center">
                                                    <p>No data!</p>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BetHistory
