import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Link, useNavigate } from "react-router-dom";
import Loader from "../Loader/Loader";


function SecondBets() {
    const [matchlistData, setMatchlistData] = useState("");
    const [activeNav, setActiveNav] = useState(4);
    const navigate = useNavigate();
    let user = JSON.parse(localStorage.getItem('clientUser'));
    const { sportmatchlist,loading } = useSelector((state) => state.authentication);

    useEffect(() => {
        if (sportmatchlist !== undefined) {
            setMatchlistData(sportmatchlist?.sportlist?.data?.filter((item) => item.sportId === activeNav));
        }
    }, [sportmatchlist, activeNav]);

    const handleClick = (eventId, marketId) => {
        if (!user) {
            return navigate('/');
        }
        navigate(`/match-view-details/${marketId}/${eventId}`);
    };

    return (
        <>
        {loading && <Loader/>}
            <div>
                <table className="bet-table-chart" id="bet-table-chart">
                    <thead>
                        {matchlistData.length > 0 && (
                            <tr>
                                <th></th>
                                <th></th>
                                <th>
                                    <div className="width">
                                        <span>1</span>
                                    </div>
                                </th>
                                <th>
                                    <div className="width">
                                        <span>2</span>
                                    </div>
                                </th>
                                <th>
                                    <div className="width">
                                        <span>x</span>
                                    </div>
                                </th>
                                <th></th>
                            </tr>
                        )}
                    </thead>
                    <tbody>
                        {matchlistData && matchlistData?.length > 0
                            ? matchlistData.map((element, index) => (
                                <tr key={index}>
                                    <td className="bet-table-chart-p">
                                        <span>
                                            <Link to={`/app/view-details`}>
                                                <p onClick={() => handleClick(element.eventId, element.marketId)}>
                                                    <span>{element.matchName}</span> {element.matchDate}
                                                    <span className="inplay-blink blink-inplay">
                                                        {element.status === "INPLAY" ? "In-Play" : ""}
                                                    </span>
                                                </p>
                                            </Link>
                                        </span>
                                    </td>
                                    <td>
                                        <div className="d-flex flex-wrap gap-2 justify-content-center bm-s-f">
                                        
                                        </div>
                                    </td>
                                    <td>
                                        <div className="width">
                                            <span>{Math.floor(Math.random() * 100)}</span>
                                            <span>{Math.floor(Math.random() * 100)}</span>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="width">
                                            <span>{Math.floor(Math.random() * 100)}</span>
                                            <span>{Math.floor(Math.random() * 100)}</span>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="width">
                                            <span>{Math.floor(Math.random() * 100)}</span>
                                            <span>{Math.floor(Math.random() * 100)}</span>
                                        </div>
                                    </td>
                                    <td className="pin">
                                        <a>
                                            <i className="fa fa-thumb-tack" aria-hidden="true"></i>
                                        </a>
                                    </td>
                                </tr>
                            ))
                            : null}
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default SecondBets;
