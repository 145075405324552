import React from 'react'

const InfoModal = ({infoModalClose}) => {
    return (
        <>
            <div className="privacy position-fixed d-block">
                <div className="privacy-start overflow-hidden">
                    <div className="privacy-startcopy privacyModal p-2">
                        <div className="privacy-startdata h100">
                            <div className="pb-2">
                                <div>
                                    <p>1. Cricket General :- ➢ If a ball is not bowled during a competition, series or match then all bets will be void except for those on any market that has been unconditionally determined (e.g. in the 'Completed Match' market).
                                    </p>
                                    <p>2. Cricket General :- If a match is shortened by weather, all bets will be settled according to the official result (including for limited overs matches, the result determined by the Duckworth Lewis method).
                                    </p>
                                    <p>3. Cricket General :- In the event of a match being decided by a bowl-off or toss of the coin, all bets will be void except for those on markets that have been unconditionally determined.
                                    </p>
                                    <p>4. Cricket Test matches :- If a match starts but is later abandoned for any reason other than weather (which may include but is not limited to: dangerous or unplayable wicket or outfield; pitch vandalism; strike or boycott; crowd protests/violence; stadium damage; acts of terrorism; and acts of God), Betfair reserves the right to void all bets, except for those on markets that have been unconditionally determined.
                                    </p>
                                    <p>5. In case anyone is found using 2 different IDs and logging in from same IP his winning in both accounts will be cancelled.
                                    </p>
                                    <p>6. Cricket Test matches :- If the match is not scheduled to be completed within five days after the original scheduled completion date, then all bets on markets for this event will be void, except for bets on any markets that have been unconditionally determined.
                                    </p>
                                    <p>7. Cricket Limited Over matches :- If a match is declared, bets will be void on all markets for the event except for those markets which have been unconditionally determined or where the minimum number of overs have been bowled as laid out in the market specific information.
                                    </p>
                                    <p>8. Cricket Limited Over matches :- In the event of a new toss taking place on a scheduled reserve day for a limited overs match all bets that were placed after 30 minutes before the original scheduled start of play on the first day will be made void. This rule relates to all markets except those that have been unconditionally determined (e.g. in the win the toss and toss combination markets).
                                    </p>
                                    <p>9. Multiple Bets :- Multiple Bets With Same Time And Same User Will Be Voided Immediately.
                                    </p>
                                </div>
                            </div>
                            <div className='pb-4'>
                                <h5>Cricket Bookmaker</h5>
                                <div>
                                    <table className='border border-black'>
                                        <thead>
                                            <tr>
                                                <th className='text-center' colSpan={12}>BOOKMAKER RATE PATTERN</th>
                                            </tr>
                                            <tr>
                                                <td className='text-center'>BETFAIR RATES</td>
                                                <td className='text-center'>BOOKMAKER RATE</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className='text-center'>1.54</td>
                                                <td className='text-center'>54 = 54 PAISA</td>
                                            </tr>
                                            <tr>
                                                <td className='text-center'>1.58</td>
                                                <td className='text-center'>58 = 58 PAISA</td>
                                            </tr>
                                            <tr>
                                                <td className='text-center'>2.72</td>
                                                <td className='text-center'>172 = 1 RUPEE 72 PAISA</td>
                                            </tr>
                                            <tr>
                                                <td className='text-center'>2.84</td>
                                                <td className='text-center'>184 = 1 RUPEE 84 PAISA</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="pb-2">
                                <div>
                                    <p>1. Due to any reason any team will be getting advantage or disadvantage we are not concerned.
                                    </p>
                                    <p>2. We will simply compare both teams 25 overs score higher score team will be declared winner in ODI (25 over comparison)
                                    </p>
                                    <p>3. We will simply compare both teams 10 overs higher score team will be declared winner in T20 matches (10 over comparison)
                                    </p>
                                    <p>4. Any query about the result or rates should be contacted within 7 days of the specific event, the same will not be considered valid post 7 days from the event.
                                    </p>
                                </div>
                            </div>
                            <div className="pb-2">
                                <div>
                                    <p><strong>➣ Next Man Out/Caught Out</strong>
                                    </p>
                                    <p>1.Advance Market wil be Valid in Both Innings
                                    </p>
                                    <p>2.If Player does not Come Then All Bets Will Be Voided.
                                    </p>
                                    <p>3.If Both Player Remain Not Out till Innings end, Then All Bets will be voided.
                                    </p>
                                </div>
                            </div>
                            <div className="pb-2">
                                <div>
                                    <p><strong>➣Luch Favourite Market</strong>
                                    </p>
                                    <p>1.In the innings Break Which Team is Favourite will be Considered as lunch Favourite.
                                    </p>
                                    <p>2.If Match gets TIE All bets will be voided.
                                    </p>
                                    <p>3.In case Overs are reduecd in a match, the team wich favourite at the inning break will be considerd as Lunch Favourite.
                                    </p>
                                </div>
                            </div>
                            <div className="pb-2">
                                <div>
                                    <h5><strong>Rules Of Fancy Bets</strong>
                                    </h5>
                                    <p>1.Once all session/fancy bets are completed and settled there will be no reversal even if the Match is Tied or is Abandoned.
                                    </p>
                                    <p>2.All Advance Fancy Bets Market will be Suspended 60 mins prior to Match and will be Settled.
                                    </p>
                                    <p>3.Under the Rules of Fancy Market if Market Gets Suspended for any reason and does not resume rhen all Previous Bets will remain Valid and become Har-Jeet bets.
                                    </p>
                                    <p>4. All decisions relating to settlement of wrong market being offered will be taken by management. Management will consider all actual facts and decision taken will be full and final.
                                    </p>
                                    <p>5.Due to any technical error market is open and result has came all bets after result will be Voided.
                                    </p>
                                    <p>6. If any case wrong rate has been given in fancy ,that particular bets will be Voided (Wrong Commentary).
                                    </p>
                                    <p>7.Should there be a power failure or a problem with the Internet Connection at our end and Fancy Market does not get suspended then our decision on the outcome is final.
                                    </p>
                                    <p>8.In case customer make bets in wrong fancy we are not liable to delete. No changes will be made and bets will be consider as confirm bet.
                                    </p>
                                    <p>9.In any circumstances management decision will be final related to all exchange items. Our scorecard will be considered as valid if there is any mismatch in online portal.
                                    </p>
                                    <p>10.Any bets which are deemed of being suspicious, including bets which have been placed from the stadium or from a source at the stadium maybe voided at anytime. The decision of whether to void the particular bet in question or to void the entire market will remain at the discretion of Company. The final decision of whether bets are suspicious will be taken by Company and that decision will be full and final.
                                    </p>
                                    <p>11.Any sort of cheating bet , any sort of Matching (Passing of funds), Court Siding (Ghaobaazi on commentary), Sharpening, Commission making is not allowed in Company, If any company User is caught in any of such act then all the funds belonging that account would be seized and confiscated. No argument or claim in that context would be entertained and the decision made by company management will stand as final authority.
                                    </p>
                                    <p>12.Fluke hunting/Seeking is prohibited in Company , All the fluke bets will be reversed. Cricket Commentary and Live Streaming is just an additional feature and facility for company user but company is not responsible for any delay or mistake in Commentary and Live Streaming.
                                    </p>
                                    <p>13.Penalty Runs - Any Penalty Runs in the Match Will be Counted While Settling in our Exchange.
                                    </p>
                                </div>
                            </div>
                            <div className='pb-2'>
                                <div className='mb-2'><strong>➣ Test Match Fancy Rules</strong></div>
                                <div className='mb-2'><strong>1. Session Market (Test Match)</strong></div>
                                <p>1.1 Complete session valid in test.</p>
                                <p>1.2 In Case of team all out, running session will be settled at current score</p>
                                <p>1.3 Due to Innings Declaration or Team all out, middle session and session is not completed so that particular over is considerd complited and remaining over will bet counted in next inning.
                                For Example:-</p>
                                <ul>
                                    <ol className='infoLi' style={{listStyle:'disk'}}>. You are Placing Advance Bets in 1st innigs 80 over runs ADV in case team-A declares or all-out at 70 over next 10 over counted in Team-B's 1st inning</ol>
                                    <ol className='infoLi mt-1' style={{listStyle:'disk'}}>. Current over is 70.4 and 75 Over Session is Running and Inning Has been Declared or All out, Following Condition Will be Applied
                                        <ol>. Over Will Be Rounded 71.</ol>
                                        <ol>. Remaining 4 Over Will Be Counted From Next Inning.</ol>
                                    </ol>
                                </ul>
                                <p>1.4 Test match both advance session is valid on Both Team's 1st Innings.</p>
                                <p>1.5 1st day 1st session Runs minimum 25 over will be played then result will given otherwise 1st day 1st session will be Voided.</p>
                                <p>1.6 1st day 2nd session Runs minimum Total 50 over will be played then result will given otherwise 1st day 2nd session Runs will be Voided.</p>
                                <p>1.7 1st Day Total Runs minimum total 80 over will be played then result will given otherwise 1st day total Runs will be Voided.</p>
                                <p></p>
                            </div>
                            <div className='pb-2'>
                                <p><strong>2. Lambi Market/Inning Runs (Test Match)</strong></p>
                                <p>2.1 If Any Team Announce Declaration or All Out Then Lambi Market/Inning Runs Will Be Valid</p>
                                <p>2.2 Team Has to Play Mandotary 70 Over For Lambi Market/Inning Runs</p>
                                <p>2.3 In case due to weather situation match has been stopped all lambi trades will be Voided</p>
                                <p>2.4 both advance Lambi Market/Inning Runs is valid on Both Team's 1st Innings.</p>
                            </div>
                            <div className='pb-2'>
                                <p><strong>3. 1 Over Session Market (Test Match)</strong></p>
                                <p>3.1 Match 1st over run advance fancy only 1st innings run will be counted</p>
                                <p>3.2 Due to Innings Declaration Following Condition Will Be Applied.
                                </p>
                                <p><i>For Example</i>:- Current over is 70.4 and 71 Over Session Runs is Running and Score is 180/4 (70.4 Over)and Inning Has been Declared 71 Over Session Runs Will Be Voided.</p>
                                <p>3.3 Due to Innings All Out Following Condition Will Be Applied.
                                </p>
                                <p><i>For Example</i>:- Current Over is 55.3 and 56 Over Runs is Running and Score is 111/10 Then Result Of 56 Over Runs will be 111 Runs.</p>
                                <p>3.4 In Case of Weather Will Affected Between Match and result will be given.
                                </p>
                                <p><i>For Example</i> :- Current over is 70.4 and 71 Over Session Runs is Running and Score is 180/4 (70.4 Over)and Weather will Affected match then 71 Over Session Runs Will Be Voided.</p>
                            </div>
                            <div className='pb-2'>
                                <p><strong>4. Only Over Session Market (Test Match)</strong></p>
                                <p>4.1 Due to Innings Declaration/All Out/Weather Situation Running Only Over Session Will Be settled</p>
                                <div>For Example:-</div>
                                <ul>
                                    <ol>55.1 Over has been played and innning ends then 56th only Over Runs Will Be settled</ol>
                                    <ol>55 Over has been played and innning ends then 56th only Over Runs Will Be Voided.</ol>
                                </ul>
                            </div>
                            <div>
                                <p><strong>5. Fall of Wicket Market  (Test Match)</strong></p>
                                <p>5.1 In Fall of Wicket Market one Batsman is Injured Or Retired Out Then Market Will Be Continue with Next Batsman.</p>
                                <p>5.2 In Case Due to Weather Condition or Declaration of Inning,  Market Result will Be Given As Per Score.</p>
                                <p>5.3 Advance Market is Valid In Case Both Player Diffrent or Same.</p>
                                <p>5.4 Advance Market is valid on Both Team's 1st Innings.</p>
                            </div>
                            <div>
                                <p><strong>6. Wicket Partnership Boundaries (Test Match )</strong></p>
                                <p>6.1 In Fall of Wicket Market one Batsman is Injured Or Retired Out Then Market Will Be Continue with Next Batsman.</p>
                                <p>6.2 In Case Due to Weather Condition or Declaration of Inning,  Market Result will Be Given As Per Score.</p>
                                <p>6.3 Advance Market is Valid In Case Both Player Diffrent or Same.</p>
                                <p>6.4 Advance Market is valid on Both Team's 1st Innings.</p>
                            </div>
                            <div>
                                <p><strong>7. Player/Batsman Market  (Test Match)</strong></p>
                                <p>7.1 In case Player is injured he/she has made 18 runs the market will be settled at 18 Runs</p>
                                <p>7.2 Player 50/100 run market will be settled at current Player runs if batsman is injured, inning has been declared or team is all out.</p>
                                <p>7.3 In Advance Market If Same Player Will Came in The Opening Then Market Will be Valid Otherwise It will be Voided.</p>
                                <p>7.4 Advance Market is valid on Both Team's 1st Innings.</p>
                            </div>
                            <div>
                                <p><strong>8. Player/Batsman Boundaries Market  (Test Match)</strong></p>
                                <p>8.1 In case Player is injured he/she has made 2 Boundaries the market will be settled at 2 Boundaries.</p>
                                <p>8.2 In Advance Market If Same Player Will Came in The Opening Then Market Will be Valid Otherwise It will be Voided.</p>
                                <p>8.3  Advance Market is valid on Both Team's 1st Innings.</p>
                            </div>
                            <div>
                                <p><strong>9. Advance Special Market</strong></p>
                                <p>9.1 TOTAL MATCH FOURS,SIXES,WIDES, WICKETS, EXTRA RUNS, TOTAL RUNS, HIGEST OVER, PARTNERSHIP  and TOP BATSMAN RUNS is valid only if 300 overs has been played or the match has been won by any team otherwise all these fancy will be Voided. Additionally all events are valid only for 1st innings.</p>
                            </div>
                            <div>
                                <p><strong>➣ ONE DAY/T20/T10 Match FANCY RULES</strong></p>
                                <p><strong>1.1 Advance Session is Valid only 1st Innings.</strong></p>
                                <p>1.1 Advance Session is Valid only 1st Innings.</p>
                                <p>1.2 In Case of Running Match Getting Cancelled/No Result/Abandoned but the Session is complete it will be settle.</p>
                                <p>
                                    <div>For Example</div>
                                    <ul>
                                        <ol>. Current over is 20.4 and 25 Over Session Runs is Running and Score is 180/4 (20.4 Over)and Match Cancelled/No Result/Abonded will Affected match then 25 Over Session Runs Will Be Voided.</ol>
                                        <ol> . After Bet Minimum 1 Ball to Bowl Other wise that Particular Bet Will Be Voided.</ol>
                                    </ul>
                                </p>
                            </div>
                            <div>
                                {/* <p><strong>➣ ONE DAY/T20/T10 Match FANCY RULES</strong></p> */}
                                <p><strong>2. 1 Over Session Market (One Day/T20/T10 Match)</strong></p>
                                <p>2.1 Match 1st over run advance fancy only 1st innings run will be counted</p>
                                <p>2.2 Due to Innings All Out Following Condition Will Be Applied
                                For Example:- Current Over is 45.3 and 46 Over Runs is Running and Score is 111/10 Then Result Of 46 Over Runs will be 111 Runs.</p>
                                <p>2.3 In Case of Running Match Getting Cancelled/No Result/Abandoned but the Market is complete it will be settle</p>
                                <p>
                                    <div>For Example</div>
                                    <ul>
                                        <ol>. 
                                            Current over is 45.3 and 46 Over Session Runs is Running and Score is 180/4 (45.3 Over) then 46 Over Session Runs Will Be Voided.
                                        </ol>
                                        <ol> .
                                            After Bet Minimum 1 Ball to Bowl Other wise that Particular Bet Will Be Voided.
                                        </ol>
                                    </ul>
                                </p>
                            </div>
                            <div>
                                <p><strong>3. Only Over/ Session Market (One Day/T20/T10 Match)</strong></p>
                                <p>3.1 Due to Innings Declaration/All Out/Weather Situation Running Only Over Session Will Be settled</p>
                                <p>2.2 Due to Innings All Out Following Condition Will Be Applied
                                For Example:- Current Over is 45.3 and 46 Over Runs is Running and Score is 111/10 Then Result Of 46 Over Runs will be 111 Runs.</p>
                                <p>
                                    <div>For Example</div>
                                    <ul>
                                        <ol>. 
                                            55 Over has been played and innning ends then 56th only Over Runs Will Be Voided.
                                        </ol>
                                        <ol> .After Bet Minimum 1 Ball to Bowl Other wise that Particular Bet Will Be Voided.
                                        </ol>
                                    </ul>
                                </p>
                            </div>
                            <div>
                                <p><strong>4. Fall of Wicket Market (One Day/T20/T10 Match)</strong></p>
                                <p>4.1 In Fall of Wicket Market one Batsman is Injured Or Retired Out Then Market Will Be Continue with Next Batsman.</p>
                                <p>4.2 In Case of Running Match Getting Cancelled/No Result/Abandoned,  Market Result will Be Given As Per Score.
                                <div>
                                Note:-After Bet Minimum 1 Ball to Bowl Other wise that Particular Bet Will Be Voided.</div>
                                </p>
                                <p>4.3 Advance Market is Valid In Case Both Player Diffrent or Same.</p>
                                <p>4.4 Both Team Advance Market Valid in Particular Match.
                                <div>
                                    Note:- Fall of 2nd wicket ADV and Fall Of 3rd Wicket Adv Will be Voided in 2nd innings</div>
                                </p>
                                <p></p>
                            </div>
                            <div>
                                <p><strong>5. Wicket Partnership Boundaries (One Day/T20/T10 Match)</strong></p>
                                <p>5.1 In Fall of Wicket Market one Batsman is Injured Or Retired Out Then Market Will Be Continue with Next Batsman.</p>
                                <p>5.2 In Case of Running Match Getting Cancelled/No Result/Abandoned, Market Result will Be Given As Per Score.
                                <div>Note:-After Bet Minimum 1 Ball to Bowl Other wise that Particular Bet Will Be Voided.</div>
                                </p>
                                <p>5.3 Advance Market is Valid In Case Both Player Diffrent or Same.</p>
                                <p>5.4 Both Team Advance Market Valid in Particular Match.
                                </p>
                            </div>
                            <div>
                                <p><strong>6. Player/Batsman Market (One Day/T20/T10 Match)</strong></p>
                                <p>6.1 In case Player is injured he/she has made 18 runs the market will be settled at 18 Runs</p>
                                <p>6.2 In Advance Market If Same Player Will Came in The Opening Then Market Will be Valid Otherwise It will be Voided.
                                </p>
                                <p>6.3 Advance Market is valid on Both Innings.</p>
                                <p>6.4 In Case of Running Match Getting Cancelled/No Result/Abandoned, Market Result will Be Given As Per Score.
                                    <div>Note:-After Bet Minimum 1 Ball to Bowl Other wise that Particular Bet Will Be Voided.</div>
                                </p>
                            </div>
                            <div>
                                <p><strong>7. Player/Batsman Boundaries Market  (One Day/T20/T10 Match)</strong></p>
                                <p>7.1 In case Player is injured he/she has made 2 Boundaries the market will be settled at 2 Boundaries.</p>
                                <p>7.2 In Advance Market If Same Player Will Came in The Opening Then Market Will be Valid Otherwise It will be Voided.
                                </p>
                                <p>7.3 Advance Market is valid on Both Innings.</p>
                                <p>7.4 In Case of Running Match Getting Cancelled/No Result/Abandoned, Market Result will Be Given As Per Score.
                                    <div>
                                    Note:-After Bet Minimum 1 Ball to Bowl Other wise that Particular Bet Will Be Voided.</div>
                                </p>
                            </div>
                            <div>
                                <p><strong>8. Only Over Boundary Market (One Day/T20/T10 Match)</strong></p>
                                <p>
                                    <div>For Example</div>
                                    <ul>
                                        <ol>. 55.1 Over has been played and innning ends then 56th only Over Runs Will Be settled </ol>
                                        <ol>. 55 Over has been played and innning ends then 56th only Over Runs Will Be Voided. </ol>
                                        <ol>. After Bet Minimum 1 Ball to Bowl Other wise that Particular Bet Will Be Voided.</ol>
                                    </ul>
                                </p>
                                <p>
                                8.2 Runs Comes In Boundary Format (4's and 6's) Will be Counted in This Market, 
                                <ul>
                                    <ol>. 
                                        Leg Bye Boundaries,Bye Boundaries,No Ball Boundaries,Wide Boundaries,Over Throw Boundaries all Are Counted in this Market.
                                    </ol>
                                        <ol>. For Boundary Count Ball Must Be Corssed Boundary line For Validation.</ol>
                                    </ul>
                                </p>
                            </div>
                            <div>
                                <p><strong>9. Only Over Dot Ball Market (One Day/T20/T10 Match)</strong></p>
                                <p>9.1 Due to Innings Declaration/All Out/Weather Situation Running Only Over Boundary Market Will Be settled</p>
                                <p>
                                    <div>For Example</div>
                                    <ul>
                                        <ol>. 55.1 Over has been played and innning ends then 56th only Over Runs Will Be settled. </ol>
                                        <ol>. 55 Over has been played and innning ends then 56th only Over Runs Will Be Voided. </ol>
                                        <ol>. After Bet Minimum 1 Ball to Bowl Other wise that Particular Bet Will Be Voided.</ol>
                                    </ul>
                                </p>
                                <p>
                                9.2 Only No Run will Be Counted a Dot Ball in this Market
                                    <ul>
                                        <ol>. Dot Ball :- Counted as A Dot Ball</ol>
                                        <ol>. Wide Ball :- Not Counted as a Dot Ball</ol>
                                        <ol>. No Ball :- Not Counted as a Dot Ball</ol>
                                        <ol>. Leg Bye Runs :- Not Counted as a Dot Ball</ol>
                                        <ol>. Bye Runs :- Not Counted as a Dot Ball</ol>
                                        <ol>. Wicket :- Any Format of Wicket Will be Not Counted as a Dot Ball </ol>
                                    </ul>
                                </p>
                            </div>
                            <div>
                                <p><strong>10. Wicket Lost to Balls/ How Many Balls Face By Player Market (One Day/T20/T10 Match)</strong></p>
                                <p>10.1 In case Player is injured he/she Played 22 Balls the market will be settled at 22 Balls.</p>
                                <p>10.2 In Advance Market If Same Player Will Came in The Opening Then Market Will be Valid Otherwise It will be Voided.</p>
                                <p>10.3 Advance Market is valid on Both Innings.</p>
                                <p>10.4 In Case of Running Match Getting Cancelled/No Result/Abandoned, Market Result will Be Given As Per Score.
                                    <div>Note:-After Bet Minimum 1 Ball to Bowl Other wise that Particular Bet Will Be Voided.</div>
                                </p>
                            </div>
                            <div>
                                <p><strong>11. Ball By Ball Market (One Day/T20/T10 Match)</strong></p>
                                <p>11.1 After Bet Minimum 1 Ball to Bowl Other wise that Particular Bet Will Be Voided.</p>
                                <p>10.2 In Advance Market If Same Player Will Came in The Opening Then Market Will be Valid Otherwise It will be Voided.</p>
                                <p>10.3 Advance Market is valid on Both Innings.</p>
                                <p>10.4 In Case of Running Match Getting Cancelled/No Result/Abandoned, Market Result will Be Given As Per Score.
                                    <div>Note:-After Bet Minimum 1 Ball to Bowl Other wise that Particular Bet Will Be Voided.</div>
                                </p>
                            </div>
                            <div>
                                <p><strong>13. Bowler Session Market (One Day/T20/T10 Match)</strong></p>
                                <p>13.1 In Case of Running Match Getting Cancelled/No Result/Abandoned but the Session is complete it will be settle.</p>
                                <p>
                                    <i>For Example</i>
                                    <ul>
                                        <ol>. 
                                            Player X Bowling his 4th over and Player X's 5 Over Session Runs is Running and Match Cancelled/No Result/Abonded will Affected match then Player X's 5 Over Session Runs Will Be Voided.
                                        </ol>
                                        <ol>. After Bet Minimum 1 Ball to Bowl Other wise that Particular Bet Will Be Voided.</ol>
                                    </ul>
                                </p>
                            </div>
                            <div>
                                <p><strong>12. Advance Special Markets (One Day/T20/T10 Match)</strong></p>
                                <p>12.1 TOTAL MATCH FOURS,SIXES,WIDES, WICKETS, EXTRA RUNS, TOTAL RUNS, HIGEST OVER, PARTNERSHIP  and TOP BATSMAN RUNS is valid only if Total Match Will be Played or the match has been won by any team, Otherwise All bets will Be Voided.</p>
                                <p>12.2 In Case of Running Match Getting Cancelled/No Result/Abandoned/Over Reduce, Markets Will Be Voided.</p>
                            </div>
                            <div>
                                <h5><strong>Rules Of Line Market</strong></h5>
                                <p>1.How many runs will be scored in the stated number of overs? Line market: This market operate with a bet delay of 1 second; All bets are struck at 2.0 The 'price' at which your bet is placed defines the number of runs bet upon. *YES Line* (buy) bets win when more runs are scored in the specified number of overs than the 'price' at which the bet is matched. *NO Line* (Sell) bets win when fewer runs are scored in the specified number of overs than the 'price' at which the bet is matched. This market will be settled if 5 overs have been completed in the innings, the batting team is bowled out or has reached the target to win the match.</p>
                                <p>2. How many runs will be scored in the stated number of overs? Line market:This market operate with a bet delay of 1 second; All bets are struck at 2.0 . The 'price' at which your bet is placed defines the number of runs bet upon. *YES Line* (Buy) bets win when more runs are scored in the specified number of overs than the 'price' at which the bet is matched. *NO Line* (Sell) bets win when fewer runs are scored in the specified number of overs than the 'price' at which the bet is matched. Special rules for Overs Runs Line markets: Bets will be void - regardless of whether the outcome of the bet is already unconditionally determined - when any of the following are the case: -if the scheduled number of overs for the innings is reduced by rain interruption and, at settlement time, the actual number of overs bowled is less than the number of overs stipulated at the head of these rules. - if the scheduled number of overs for the innings is reduced for any other reason after the innings has begun and at settlement time, that new number of scheduled overs is less than the number of overs stipulated at the head of these rules. Please note if the batting side reach their target within the 20 overs or have been bowled out and the innings hasn’t been reduced in overs to less than the stipulated number at the head of the rules, the market will be settled.</p>
                            </div>
                            <div>
                                <h5>➣ Sportsbook Market Rules (Test/One Day/T20/T10 Match)</h5>
                                <p><strong>1. Odd Even Markets</strong></p>
                                <p>1.1  In Case of Running Match Getting Completed/Cancelled/No Result/Abandoned but the Market is complete it will be settle.</p>
                                <p><i>For Example</i>
                                :-Current over is 20.4 and 25 Over Odd/Even Market is Running and Score is 180/4 (20.4 Over)and Match Completed/Cancelled/No Result/All Out/Abonded will Affected match then 25 Over Odd/Even Market Will Be Voided.
                                1.2 After Bet Minimum 1 Ball to Bowl Other wise that Particular Bet Will Be Voided.
                                </p>
                                <p><strong>2. Last Digit Market (Test/One Day/T20/T10 Match)</strong></p>
                                <p>1. In Case of Running Match Getting Cancelled/No Result/Abandoned but the Market is complete it will be settle.</p>
                                <p><i>For Example</i>Current over is 20.4 and 25 Over Last Digit Market is Running and Score is 180/4 (20.4 Over)and Match Completed/Cancelled/No Result/All Out/Abonded will Affected match then 25 Over Lasr Digit Market Will Be Voided.
                                </p>
                            </div>
                            <div>
                                <p><strong>3. Advance Special Markets</strong></p>
                                <p>3.1 In Case of Running Match Getting Cancelled/No Result/Abandoned but the Market is complete it will be settle</p>
                            </div>
                            <div>
                                <p><strong>4. Meter Market </strong></p>
                                <p>4.1.In Case of Running Match Getting Cancelled/No Result/Abandoned Resut Will Be Given at Mid Point of Current Value.</p>
                                <p>
                                    <ul>
                                        <ol>. If Meter Market's Current value is 75/77 or 75/76 then result will be 76 respectively.</ol>
                                    </ul>
                                </p>
                            </div>
                            <div>
                                <p><strong>5. Khado Market </strong></p>
                                <p>1. Advance Khado Market is Valid Only 1st Innings</p>
                                <p>2. In Case of Running Match Getting Cancelled/No Result/Abandoned but the Market is complete it will be settle.</p>
                                <p>3. You Have to Chose Your Own Value in This Market.</p>
                            </div>
                            <div>
                                <p><strong>ICC CRICKET WORLD CUP - 2023 RULES </strong></p>
                                <p>1. 48 Matches will be counted in fancy market.(Super Over Will be Not Counted)</p>
                                <p>2.If Tournament of 48 matches gets reduced or Postponded due to any reason except Match abandoned due to rain/bad light, all the special fancies will be voided.
                                </p>
                                <p>3.If Tournament will cancelled or Postponded due to any reason, all settled markets will be valid.
                                </p>
                                <p>4.Total Match 1st over runs : Average 4 runs will be given in case match abandoned or over reduced (Only First Innings is Valid).
                                </p>
                                <p>5.Total Match 1st 10 over run:- Average 51 runs will be given in case match abandoned or over reduced (Only First Innings is Valid).
                                </p>
                                <p>6.Total fours: Average 45 fours will be given in case match abandoned or over reduced.
                                </p>
                                <p>7.Total sixes: Average 11 sixes will be given in case match abandoned or over reduced.
                                </p>
                                <p>8.Total Wickets - Average 15 Wickets will be given in case match abandoned or over reduced.
                                </p>
                                <p>9.Total Wides - Average 16 Wides will be given in case match abandoned or over reduced.
                                </p>
                                <p>10.Total No balls:- Average 2 No ball will be given in case match abandoned or over reduced.
                                </p>
                                <p>11.Total Extras - Average 26 extras will be given in case match abandoned or over reduced.
                                </p>
                                <p>12.Total Caught outs: Average 9 caught out will be given in case match abandoned or over reduced.
                                </p>
                                <p>13.Total Bowled:- Average 3 Bowled out will be given in case match abandoned or over reduced.
                                </p>
                                <p>14.Total LBW:- Average 2 LBW will be given in case match abandoned or over reduced.
                                </p>
                                <p>15.Total Run out:- Average 1 Run out will be given in case match abandoned or over reduced.
                                </p>
                                <p>16.Total Fifties - Average 3 fifties will be given in case match abandoned or over reduced.
                                </p>
                                <p>17.Total Hunderds - Average 1 Hundred will be given in case match abandoned or over reduced. 
                                </p>
                                <p>18.Total Ducks - Average 1 Duck out will be given in case match abandoned or over reduced.
                                </p>
                                <p>19.Total Maidens - Average 3 Maidens will be given in case match abandoned or over reduced.
                                </p>
                                <p>20.Total 50+ Partnerships - Average 3 Fifty plus Partnerships will be given in case match abandoned or over reduced. 50 and 50 Above Partnerships All Counted in this.
                                </p>
                                <p>21.Highest 1st over run in individual match: Only First Innings is Valid.
                                </p>
                                <p>22.Highest 1st 10 over run in individual match: Only First Innings is Valid.
                                </p>
                                <p>23.Highest Fours in individual match: Both innings are valid.
                                </p>
                                <p>24.Highest Sixes in individual match: Both innings are valid.
                                </p>
                                <p>25.Highest Wicket in individual match: Both innings are valid.
                                </p>
                                <p>26.Highest Extras in individual match: Both innings are valid.
                                </p>
                                <p>27.Lowest Runs Given By Any Bowler In Individual Match: Bowler must bowl 10 overs.</p>
                            </div>
                        </div>
                    </div>
                    <div className="privacy-start-ok">
                        <button onClick={infoModalClose}>OK</button>
                    </div>
                </div>
            </div >
        </>
    )
}

export default InfoModal
