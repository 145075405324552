import React, { useState, useEffect } from 'react';


const BlinkingComponent = ({ price, size, color, type }) => {
    const [blink, setBlink] = useState(false);
    const [prevPrice, setPrevPrice] = useState(price);
    const [prevSize, setPrevSize] = useState(size);

    useEffect(() => {
        if (price !== prevPrice || size !== prevSize) {
            setBlink(true);
            const timeout = setTimeout(() => {
                setBlink(false);
                setPrevPrice(price);
                setPrevSize(size);
                setBlink(false);
            }, 500); // Blinking duration
            return () => clearTimeout(timeout);
        }
    }, [price, size, prevPrice, prevSize, color]);


    const numFormatter = (number) => {
        const parsedNumber = parseFloat(number);
        if (isNaN(parsedNumber)) {
            return number;
        }

        // Hundreds
        if (parsedNumber <= 999) {
            return parsedNumber.toFixed(2);
        }
        // Thousands
        else if (parsedNumber >= 1000 && parsedNumber <= 999999) {
            return (parsedNumber / 1000).toFixed(2) + 'K';
        }
        // Millions
        else if (parsedNumber >= 1000000 && parsedNumber <= 999999999) {
            return (parsedNumber / 1000000).toFixed(2) + 'M';
        }
        // Billions
        else if (parsedNumber >= 1000000000 && parsedNumber <= 999999999999) {
            return (parsedNumber / 1000000000).toFixed(2) + 'B';
        }
        // Trillions
        else if (parsedNumber >= 1000000000000) {
            return (parsedNumber / 1000000000000).toFixed(2) + 'T';
        } else {
            return parsedNumber.toFixed(2);
        }
    };
    return (

        <div id='all-match-tab'
            // blink ? `  blinkColor ` : ` ${color} `
            className={blink ? `blinkColor` : `${color}`}
        >
            <div className='text-center'>
                <p className={`mb-0 ${blink ? "text-[14px] " : "text-[14px] "}`}
                >
                    <span className="font-bold d-block">{price ? price : "0"}</span>
                    {
                        type === "fancy" ?
                            <span className="text-xs fancy-blinktext" style={{ fontSize: "9px" }}>
                                {size && size !== "0" && size !== "" && size !== "--" && size !== null
                                    ? (size)
                                    : "0"}
                            </span>
                            :
                            <span className="text-xs fancy-blinktext" style={{ fontSize: "9px" }}>
                                {size && size !== "0" && size !== "" && size !== "--" && size !== null
                                    ? numFormatter(size)
                                    : "0"}
                            </span>}

                </p>
            </div>
        </div>
    );
};

export default BlinkingComponent;