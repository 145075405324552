import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {casinoListByCategory} from "../../redux/_reducers/_reducers";
import { useDispatch, useSelector } from "react-redux";

function LotteryImages({lotteryData}) {
    const {casinoListByCategoryData} = useSelector((state) => state.authentication);
    const [searchParams] = useSearchParams();
    // Extract query parameters
    const gameName = searchParams.get('game_name');
    const id = searchParams.get('id');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isLoggedIn = localStorage.getItem("user") ? true : false;

    const handleImageClick = (item) => {
        if (isLoggedIn) {
        const iframeURL = `/iframe?game_name=${item?.provider_name}&id=${item?.game_id}`;
        navigate(iframeURL);
        } else {
        console.error("User not logged in, redirecting to login page...");
        navigate("/login");
        }
    };
    return (
        <div
            className="tab-content nav-patti-control py-3"
            id="nav-tabContent"
            
        >
            <div
                className="tab-pane fade show active"
                id="nav-home2"
                role="tabpanel"
                aria-labelledby="nav-home-tab"
            >
                <div className="cards-sec row">
                    {lotteryData && lotteryData.length > 0 ? (
                        lotteryData.map((item) => (
                        <div key={item.id} className={`col-lg-2 col-sm-3 patti-images`}>
                            <div onClick={() => handleImageClick(item)}>
                                <div className="patti-images2">
                                    <div className="cards-sec-inner1"></div>
                                    <div className="cards-sec-inner2">
                                    <img src={item?.Image || 'images/lotteryimg.png'} alt={item.name} />
                                    </div>
                                    <p className="text-center mt-2">{item?.game_name}</p>
                                </div>
                            </div>
                        </div>
                        ))
                    ) : (
                        <p>No lottery data available</p>
                    )}
                    {/* <div className={`col-lg-3 col-sm-4 patti-images `}>
                        <Link to='/app/next-lottery'>
                        <div className="patti-images2">
                            <div className="cards-sec-inner1"></div>
                            <div className="cards-sec-inner2">
                                <img src="images/lotteryimg.png" alt="" />
                            </div>
                            <p className="text-center mt-2">Time Bazar</p>
                        </div>
                        </Link>
                    </div>
                    <div className={`col-lg-3 col-sm-4 patti-images `}>
                        <div className="patti-images2">
                            <div className="cards-sec-inner1"></div>
                            <div className="cards-sec-inner2">
                                <img src="images/lotteryimg.png" alt="" />
                            </div>
                            <p className="text-center mt-2">MILAN DAY BAZAR</p>
                        </div>
                    </div>
                    <div className={`col-lg-3 col-sm-4 patti-images `}>
                        <div className="patti-images2">
                            <div className="cards-sec-inner1"></div>
                            <div className="cards-sec-inner2">
                                <img src="images/lotteryimg.png" alt="" />
                            </div>
                            <p className="text-center mt-2">RAJDHANI DAY BAZAAR</p>
                        </div>
                    </div>
                    <div className={`col-lg-3 col-sm-4 patti-images `}>
                        <div className="patti-images2">
                            <div className="cards-sec-inner1"></div>
                            <div className="cards-sec-inner2">
                                <img src="images/lotteryimg.png" alt="" />
                            </div>
                            <p className="text-center mt-2">KALYAN BAZAAR</p>
                        </div>
                    </div>
                    <div className={`col-lg-3 col-sm-4 patti-images `}>
                        <div className="patti-images2">
                            <div className="cards-sec-inner1"></div>
                            <div className="cards-sec-inner2">
                                <img src="images/lotteryimg.png" alt="" />
                            </div>
                            <p className="text-center mt-2">MILAN NIGHT BAZAAR</p>
                        </div>
                    </div>
                    <div className={`col-lg-3 col-sm-4 patti-images `}>
                        <div className="patti-images2">
                            <div className="cards-sec-inner1"></div>
                            <div className="cards-sec-inner2">
                                <img src="images/lotteryimg.png" alt="" />
                            </div>
                            <p className="text-center mt-2">KALYAN NIGHT BAZAAR</p>
                        </div>
                    </div>
                    <div className={`col-lg-3 col-sm-4 patti-images `}>
                        <div className="patti-images2">
                            <div className="cards-sec-inner1"></div>
                            <div className="cards-sec-inner2">
                                <img src="images/lotteryimg.png" alt="" />
                            </div>
                            <p className="text-center mt-2">RAJDHANI NIGHT BAZAAR</p>
                        </div>
                    </div>
                    <div className={`col-lg-3 col-sm-4 patti-images `}>
                        <div className="patti-images2">
                            <div className="cards-sec-inner1"></div>
                            <div className="cards-sec-inner2">
                                <img src="images/lotteryimg.png" alt="" />
                            </div>
                            <p className="text-center mt-2">MAIN BAZAAR</p>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    );
}

export default LotteryImages;
