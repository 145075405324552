import { Link, useNavigate, useParams } from "react-router-dom"
import { useLocation } from "react-router-dom"
import { useEffect, useState } from "react";
import UserNotLoginPopup from "../UserNotLoginPopup/UserNotLoginPopup";
import { matchSeriesList } from "../../redux/_reducers/_reducers";
import { useDispatch, useSelector } from "react-redux";
import ComingSoon from "../ComingSoon/ComingSoon";

function SubHeader() {
    const userLgin = localStorage.getItem('user')
    const navigate = useNavigate();
    const [confirmPopup, setConfirmPopup] = useState(false)
    const [commingSoonOpen, setCommingSoonOpen] = useState(false)
    const isLoggedIn = localStorage.getItem("user") ? true : false;

    const { providerName, gameId } = useParams();
    const handleOpen = () => {
        setCommingSoonOpen(true)
    }

    const handleClose = () => {
        setCommingSoonOpen(false)
    }

    const { sportsId } = useParams();

    const handleOnClick = (path) => {
        if (!userLgin) {
            alert("Login First !")
            navigate("/home")
        }
        // navigate(`/app/${path}`)
        navigate(`allsportshighlight/${sportsId}`)
    };

    const { matchSeriesListData } = useSelector((state) => state.authentication);

    const dispatch = useDispatch();
    useEffect(() => {
        if (!matchSeriesListData) {
            const payload = { limit: 100, pageno: 1, series_id: 0, sport_id: sportsId, type: 'h' };
            dispatch(matchSeriesList(payload));
        }
    }, [dispatch, matchSeriesListData]);


    const handleImageClick = (event, providerName, gameId) => {
        event.preventDefault();

        // Check if the user is logged in
        const isLoggedIn = localStorage.getItem("user") ? true : false;
        if (isLoggedIn) {
            const iframeURL = `/iframe/${providerName}/${gameId}`;
            navigate(iframeURL);
        } else {
            // Redirect to the login page
            navigate("/login");
        }
    };


    return (
        <>
            {
                commingSoonOpen && (
                    <ComingSoon handleClose={handleClose} />
                )
            }
            {confirmPopup && <UserNotLoginPopup confirmPopup={confirmPopup} setConfirmPopup={setConfirmPopup} />}
            <div className="bottom-header d-none d-lg-flex">
                <div className="container-fluid ">
                    <div className="header-nav d-flex align-items-center justify-content-between">
                        <ul className="d-lg-flex d-none flex-wrap ">
                            <li><Link to="/">Home</Link></li>
                            <li><Link to="/inplay">In-Play</Link></li>
                            {/* <li onClick={() => handleOnClick("multi-market")} >Multi Markets</li> */}
                            <li><Link to={`allsportshighlight/4`}>Cricket</Link></li>
                            <li><Link to={`allsportshighlight/2`}>Tennis</Link></li>
                            <li><Link to={`allsportshighlight/1`}>Soccer</Link></li>
                            {/* <li><Link to={`allsportshighlight/9999`}>Baccarat</Link></li>
                            <li><Link to={`allsportshighlight/9999`}>32 Cards</Link></li>
                            <li><Link to={`allsportshighlight/9999`}>Teen Patti</Link></li>
                            <li><Link to={`allsportshighlight/9999`}>Poker</Link></li>
                            <li><Link to={`allsportshighlight/9999`}>Lucky 7</Link></li> */}

                            <li>
                                <Link onClick={(e) => handleImageClick(e, "AVIATOR", "201206")}>
                                    <img src="/images/aviator.png" alt="" style={{ height: "16px", width: "30px" }} />
                                    Aviator
                                </Link>
                            </li>
                            <li>
                                <Link onClick={(e) => handleImageClick(e, "EZUGI", "500002")}>
                                    Baccarat
                                </Link>
                            </li>
                            <li>
                                <Link onClick={(e) => handleImageClick(e, "DC", "200329")}>
                                    Dragon Tiger
                                </Link>
                            </li>
                            <li>
                                <Link onClick={(e) => handleImageClick(e, "EZUGI", "100046")}>
                                    Teen Patti
                                </Link>
                            </li>
                            <li>
                                <Link onClick={(e) => handleImageClick(e, "DC", "200208")}>
                                    Poker
                                </Link>
                            </li>
                            <li>
                                <Link onClick={(e) => handleImageClick(e, "RG", "900021")}>
                                    Lucky 7
                                </Link>
                            </li>

                            {/* <li><Link onClick={handleImageClick} to={`/iframe/Baccarat/500002`}>Baccarat</Link></li>
                            <li><Link onClick={handleImageClick} to={`/iframe/TeenPatti/600011`}>32 Cards</Link></li>
                            <li><Link onClick={handleImageClick} to={`/iframe/TeenPatti/600011`}>Teen Patti</Link></li>
                            <li><Link onClick={handleImageClick} to={`/iframe/Poker/500003`}>Poker</Link></li>
                            <li><Link onClick={handleImageClick}  to={`/iframe/Lucky 7/100066`}>Lucky 7</Link></li>
                             */}
                            {/* <li><Link to={`allsportshighlight/1`}>Baccarat</Link></li> */}
                            <li><Link to={`/lottery`}>Lottery</Link></li>
                            <li><Link to="/live-casino">Live Casino</Link></li>
                            {/* <li><Link onClick={()=>handleOpen()} to="">Horse Racing</Link></li>
                            <li><Link onClick={()=>handleOpen()} to=""> Greyhound Racing</Link></li> */}
                            {/* <li><Link to={`/allsportshighlight/4`}>Horse Racing</Link></li>
                            <li><Link to={`/allsportshighlight/4`}>greyhound Racing</Link></li>
                            <li><Link to={`/allsportshighlight/4`}>basketball</Link></li>
                            <li><Link to={`/allsportshighlight/4`}>Lottery</Link></li>
                            <li><Link to={`/allsportshighlight/4`}>Live Casino</Link></li> */}
                            {/* <li onClick={userLgin ? () => handleOnClick('virtual-sports') : ()=>setConfirmPopup(true)}><Link>Virtual Sports</Link></li>  */}
                            {/* <li><Link to="tips-and-preview">Tips & Previews</Link></li> */}
                        </ul>
                        {userLgin && <Link to="/app/settings">
                            <span>
                                Settings
                            </span>
                            <i className="fa fa-cog ms-1"></i>
                        </Link>}
                    </div>
                </div>
            </div>
        </>
    )
}

export default SubHeader
