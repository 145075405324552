import { useDispatch, useSelector } from "react-redux";
import { Helmet } from 'react-helmet'; // Import react-helmet
import "./App.css";
import { useEffect, useState } from "react";
import { getSport } from "./redux/_reducers/_reducers";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./pages/PublicPages/Login/Login";
import ParentRoute from "./routes/ParentRoute";
import { ToastContainer } from "react-toastify";
import { CONST } from "./middelware/Const";

function App() {
  const { defaultSettingData } = useSelector(
    (state) => state.authentication
  );
  const title = defaultSettingData?.find((item) => item.key === "site.TITLE");
  const FAVICON = defaultSettingData?.find((item) => item.key === "site.FAVICON");
  return (
    <div className="App main-section">
      <ToastContainer />
      <BrowserRouter>
        {/* Global Helmet for default title and favicon */}
        <Helmet>
          <title>{title?.value}</title>
          <link rel="icon" href={`${CONST.IMAGE_URL}${FAVICON?.value}`} />
        </Helmet>

        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/*" element={<ParentRoute />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
