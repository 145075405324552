import React from "react";
import { FaHome, FaRegClock } from "react-icons/fa";
import { GiTrophyCup } from "react-icons/gi";
import { MdAccountCircle, MdPushPin } from "react-icons/md";
import { NavLink, useNavigate } from "react-router-dom";
import casino from '../../assets/image/casino.gif'

function MobileMenu ()  {
    const navigate = useNavigate();
    const handleNavigation = (e) => {
        e.preventDefault(); 
    const loggedIn = localStorage.getItem("user");
    if (loggedIn) {
        navigate("/accountpage");
    } else {
        navigate("/login");
    }
    };
    return (
        <div>
            <div className="mobile-menu d-lg-none d-block position-fixed fixed-bottom">
                <ul className="d-flex align-items-center flex-wrap justify-content-center">
                    <li className="text-center">
                        <NavLink to="/" activeClassName="active">
                            <FaHome size={30}/>
                            <div className="footerLink">Home</div>
                        </NavLink>
                    </li>
                    <li className="text-center">
                        <NavLink to="/inplay" activeClassName="active">
                            <FaRegClock size={30}/>
                            <div className="footerLink">In-Play</div>
                        </NavLink>
                    </li>
                    <li className="text-center mobile-menu-before">
                        <NavLink to="/sportspage" activeClassName="active">
                            <GiTrophyCup size={30}/>
                            <div className="footerLink">Sports</div>
                        </NavLink>
                    </li>
                    <li className="text-center">
                        <NavLink to="/live-casino" activeClassName="active">
                            <img className="casinoGif" src={casino} alt="" />
                            {/* <MdPushPin size={30}/> */}
                            <div className="footerLink">Live Casino</div>
                        </NavLink>
                    </li>
                    <li className="text-center">
                        <NavLink onClick={handleNavigation} to="/" activeClassName="active">
                            <MdAccountCircle size={30}/>
                            <div className="footerLink">Account</div>
                        </NavLink>  
                    </li>
                </ul>
            </div>
            {/* <div className="mobile-menu d-lg-none d-block position-fixed fixed-bottom">
                <ul className="d-flex align-items-center flex-wrap justify-content-center">
                    <li className="text-center">
                        <FaHome size={40}/>
                        <p>Home</p>
                    </li>
                    <li className="text-center">
                        <FaRegClock size={40}/>
                        <p>In-Play</p>
                    </li>
                    <li className="text-center mobile-menu-before">
                        <GiTrophyCup size={40}/>
                        <p>Sports</p>
                    </li>
                    <li className="text-center">
                        <MdPushPin size={40}/>
                        <p>Multi Markets</p>
                    </li>
                    <li className="text-center"><MdAccountCircle size={40}/><p>Account</p></li>
                </ul>
            </div> */}
        </div>
    )
}

export default MobileMenu