import React, { useEffect, useState, useRef } from "react";
import SubHeader from "../SubHeader/SubHeader";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { defaultSetting, login, logout, walletBalance } from "../../redux/_reducers/_reducers";
import { useBalance } from "../../ContextApi";
import chatIcon from '../../assets/image/chatIcon.png'
import wp from '../../assets/image/whatsapplogo.png'
import telegram from '../../assets/image/telegram.png'
import { IoClose } from "react-icons/io5";
import UserNotLoginPopupSign from "../UserNotLoginPopupSign/UserNotLoginPopupSign";
import { CONST } from "../../middelware/Const";


function Header() {
  const [fieldsLogin, setFieldsLogin] = useState({
    user_name: "",
    password: "",
  });
  const [loginData, setUserLoggedIn] = useState()
  const [walletBalanceDataa, setWalletBalanceData] = useState()
  const [defaultAllData, setDefaultAllData] = useState()
  const [errorsLogin, setErrorsLogin] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [usernameErrorMessage, setusernameErrorMessage] = useState("");
  const [searchInput, setSearchToggle] = useState(false);
  const [accountDropdownOpen, setAccountDropdownOpen] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [IsSignup, SetIsSignup] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const { balance } = useBalance();
  const userLoginData = localStorage.getItem("user")
  const [isVisible, setIsVisible] = useState(false);
  const [sigup, setSigup] = useState(false);
  const [confirmPopup, setConfirmPopup] = useState(false)
  const [refreshKey, setRefreshKey] = useState(0);
  const accountDropdownRef = useRef(null);
  const toggleImages = () => {
    setIsVisible(!isVisible);
  };

  const SignupClick = () => {
    setConfirmPopup(true)
    SetIsSignup(true)
    // SetIsSignup((prev) => !prev);
  }

  const handleRefresh = () => {
    setRefreshKey((prevKey) => prevKey + 1);
    // setWalletBalanceDataa({
    //     balance: Math.random() * 100,
    //     liability: Math.random() * 50,
    // });
  };

  const { balanceLoading, defaultSettingData, walletBalanceData } = useSelector(
    (state) => state.authentication
  );


  useEffect(() => {
    setDefaultAllData()
  }, [defaultAllData])

  useEffect(() => {
    dispatch(walletBalance(navigate));
  }, [dispatch]);

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(walletBalance(navigate));
    }, 60000);

    return () => clearInterval(interval);
  }, [dispatch]);

  useEffect(() => {
    if (walletBalanceData) {
      setWalletBalanceData(walletBalanceData);
    }
  }, [walletBalanceData]);


  useEffect(() => {
    dispatch(defaultSetting());
  }, [dispatch]);

  useEffect(() => {
    if (defaultSettingData) {
      setDefaultAllData(defaultSettingData);
    }
  }, [defaultSettingData]);

  const whatsappData = defaultSettingData?.find((item) => item.key === "site.whatsapp");
  const telegramData = defaultSettingData?.find((item) => item.key === "site.telegram");
  const sitelogo = defaultSettingData?.find((item) => item.key === "site.LOGO");
  const signUpStatus = defaultSettingData?.find((item) => item.key === "site.sign_up_status");

  const loginSubmit = async (e) => {
    e.preventDefault();
    try {

      if (fieldsLogin.user_name.length === 0) {
        setusernameErrorMessage("UserName can't be empty");
        return;
      }
      if (
        fieldsLogin.password.length === 0
      ) {
        setErrorMessage("Password can't be empty");
        return;
      }
      const data = {
        ...fieldsLogin,
        // isClient: true,
        // host: window.location.host,
      };

      dispatch(login(data)).then(((response) => {
        if (response && response?.payload?.userinfo) {
          setFieldsLogin({ user_name: "", password: "" });
          setErrorMessage("");
          navigate("/");
          setAccountDropdownOpen(false);

        }

      }))


    } catch (error) {
      console.error("Login error:", error);
    }
  };

  const logoutUser = () => {
    dispatch(logout());
    localStorage.removeItem("user");
    setAccountDropdownOpen(false);
    navigate("/")
  };

  const exposureNavigate = () => {
    navigate("/app/bet-history/exposure")
  };

  const inputChange = (e) => {
    const { name, value } = e.target;
    setFieldsLogin((prevFields) => ({
      ...prevFields,
      [name]: value,
    }));
    setErrorsLogin((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
    setErrorMessage("");
    setusernameErrorMessage("")
  };


  const handleOutsideClick = (e) => {
    // console.log("Dropdown Ref:", accountDropdownRef.current);
    if (
      accountDropdownRef.current &&
      !accountDropdownRef.current.contains(e.target)
    ) {
      setAccountDropdownOpen(false);
    }
  };

  useEffect(() => {
    if (accountDropdownOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [accountDropdownOpen]);

  const refreshBalance = async () => {
    setIsRefreshing(true);
    try {
      await dispatch(walletBalance());
    } catch (error) {
      console.error('Error refreshing balance:', error);
    } finally {
      setIsRefreshing(false);
    }
  };

  return (
    <>
      {confirmPopup && <UserNotLoginPopupSign confirmPopup={confirmPopup} setConfirmPopup={setConfirmPopup} />}
      <header className="dream-header">
        <div className="top-header">
          <div className="container-fluid">
            <div className="header-work row align-items-center">
              <Link to='/'
                className={`header-left ${(loginData || userLoginData) ? "d-none d-lg-block" : "d-block"
                  } col-lg-3 col-md-6 col-sm-6 `}
              >
                <img src={`${CONST.IMAGE_URL}${sitelogo?.value}`} alt="" />
              </Link>
              {(loginData || userLoginData) && (
                <Link to='/' className="header-left d-block d-lg-none">
                  <img src={`${CONST.IMAGE_URL}${sitelogo?.value}`} alt="" />
                </Link>
              )}
              <div className="d-flex flex-column align-items-end header-right col-lg-9 col-md-6 col-sm-6">
                <form
                  className="d-flex flex-wrap justify-content-end gap-2 align-items-center"
                  onSubmit={loginSubmit}
                >
                  {/* {searchInput && (
                    <input
                      style={{ width: "300px" }}
                      type="search"refreshBalance
                    />
                  )}
                  <span>
                    <FaSearchPlus
                      className="d-none d-lg-block"
                      onClick={() => {
                        setSearchToggle(!searchInput);
                      }}
                      style={{ color: "white" }}
                      size={20}
                    />
                  </span> */}
                  {!(loginData || userLoginData) && (
                    <>
                      <div className=" d-none d-lg-flex login-input">
                        <div className="position-relative">
                          <input
                            type="text"
                            placeholder="User Name"
                            name="user_name"
                            value={fieldsLogin.user_name}
                            onChange={inputChange}
                          />
                          <p
                            className="position-absolute"
                            style={{
                              color: "white",
                              height: "10px",
                              fontSize: "13px",
                              top: "30px",
                            }}
                          >
                            {usernameErrorMessage ? <p className="text-danger ">{usernameErrorMessage}</p> : ""}
                          </p>
                        </div>
                        <div className="position-relative">


                          <input
                            type="password"
                            placeholder="Password"
                            name="password"
                            value={fieldsLogin.password}
                            onChange={inputChange}
                          />
                          <p
                            className="position-absolute"
                            style={{
                              color: "white",
                              height: "10px",
                              fontSize: "13px",
                              top: "30px",
                            }}
                          >
                            {errorMessage ? <p className="text-danger ">{errorMessage}</p> : ""}
                          </p>
                        </div>

                        <div className="d-flex flex-wrap logindatawork">
                          <div className="log-in">
                            <button className="login-button" type="submit">
                              Login{" "}
                              <i
                                style={{ color: "black" }}
                                className="fa fa-sign-out"
                                aria-hidden="true"
                              ></i>
                            </button>
                          </div>
                          {signUpStatus?.value === "1" ?
                            <div className="log-in">
                              <button className="login-button " type="submit" onClick={() => {
                                setConfirmPopup(true);
                                setSigup('/signUp')
                                // SetIsSignup(true); 
                              }}>Signup</button>
                            </div> : null}
                        </div>
                      </div>

                      <div className="d-flex flex-wrap login-mobile">
                        <div className="log-in d-md-flex d-lg-none">
                          <Link to='/login'>
                            <button className="login-button">
                              Login
                              <i style={{ color: "black" }} className="fa fa-sign-out ps-2" aria-hidden="true"></i>
                            </button>
                          </Link>

                        </div>
                        {signUpStatus?.value === "1" ?
                          <div className="log-in d-md-flex d-lg-none">
                            <button className="login-button" onClick={SignupClick}>Signup</button>
                          </div> : null
                        }
                      </div>
                    </>
                  )}
                  {(loginData || userLoginData) && (
                    <div className="naxtpage-login">
                      <ul className="d-flex ">
                        {isRefreshing ? null : (
                          <li className="main-pit">
                            <p className="text-nowrap">
                              {" "}
                              <strong className="text-nowrap">
                                Main PTI {walletBalanceDataa?.balance}
                              </strong>{" "}
                              <br />
                              <strong style={{ cursor: "pointer" }} onClick={() => exposureNavigate()}>Exposure (<span>{walletBalanceDataa?.liability}</span>) </strong>
                            </p>
                          </li>)

                        }
                        <li className="my-accoutn d-flex align-items-center  justify-content-center ">
                          <div onClick={() => refreshBalance()} className="reload d-flex align-items-center justify-content-center">
                            <img onClick={handleRefresh} src="/images/refresh.png" alt="" />
                            {signUpStatus?.value === "1" ?
                              <Link to='/app/payment' className="reloadnone">
                                <i class="fa fa-university d-md-none d-block " aria-hidden="true"></i>
                              </Link> : null}
                          </div>
                          <div className="my-accoutn-btn  position-relative d-lg-flex d-none align-items-center gap-1 justify-content-center">
                            <div onClick={() =>
                              setAccountDropdownOpen(!accountDropdownOpen)
                            } className=" position-relative d-lg-flex d-none align-items-center gap-1 justify-content-center">
                              <i className="fa fa-user" aria-hidden="true"></i>
                              <p className="account-name">My Account</p>
                              <i className="fa fa-sort-desc" aria-hidden="true"></i>
                            </div>
                            {accountDropdownOpen && (<div ref={accountDropdownRef} className="drop-my-account  ">
                              <ul>
                                <li>
                                  <p>Exchange</p>
                                </li>
                                <li>
                                  <Link to='/app/changePassword' onClick={() =>
                                    setAccountDropdownOpen(!accountDropdownOpen)
                                  }>Change Password</Link>
                                </li>
                                <li>
                                  <Link to='/app/account-statement' onClick={() =>
                                    setAccountDropdownOpen(!accountDropdownOpen)
                                  }>Account Statement</Link>
                                </li>
                                <li>
                                  <Link to='/app/bet-history/others' onClick={() =>
                                    setAccountDropdownOpen(!accountDropdownOpen)
                                  }>Bets History</Link>
                                </li>
                                <li>
                                  <Link to='/app/Profit-loss' onClick={() =>
                                    setAccountDropdownOpen(!accountDropdownOpen)
                                  }>Profit & Loss</Link>
                                </li>
                                <li>
                                  <Link to='/app/depositWithdraw' onClick={() =>
                                    setAccountDropdownOpen(!accountDropdownOpen)
                                  }>D/w History</Link>
                                </li>
                                {signUpStatus?.value === "1" ?
                                  <>
                                    <li>
                                      <Link to='/app/deposite' onClick={() =>
                                        setAccountDropdownOpen(!accountDropdownOpen)
                                      }>Deposit</Link>
                                    </li>
                                    <li>
                                      <Link to='/app/withdraw' onClick={() =>
                                        setAccountDropdownOpen(!accountDropdownOpen)
                                      }>Withdraw</Link>
                                    </li>
                                  </> : null}
                                <li>
                                  <button onClick={() => logoutUser()} className="d-flex">
                                    LOGOUT{" "}
                                    <i
                                      className="fa fa-sign-out text-light"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                </li>
                              </ul>
                            </div>)}
                          </div>
                        </li>
                      </ul>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
        <SubHeader />
        {/* <Signup SignupOpen={IsSignup} CloseSignup={SignupClick} /> */}
      </header>
      <div>
        <div className="chat-container">
          <div className="chatBox" onClick={toggleImages}>
            {isVisible ? (
              <IoClose size={25} color="white" />
            ) : (
              <img src={chatIcon} alt="Chat Icon" />
            )}
          </div>
          <div style={!isVisible ? { pointerEvents: "none" } : {}} className={`images-container ${isVisible ? "show" : "hide"}`}>
            <Link to={whatsappData?.value}>
              <img src={wp} alt="Image 1" className="image slide" />
            </Link>
            <Link to={telegramData?.value}>
              <img src={telegram} alt="Image 2" className="image slide" />
            </Link>
          </div>
        </div>
      </div>
      {/* <UserNotLoginPopup /> */}
    </>
  );
}

export default Header;
