import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {casinoListByCategory } from "../../redux/_reducers/_reducers";

const tabData = [
    { id:5000, label: 'baccarat' },
    { id: 5001, label: 'teen-patti' },
    { id: 5002, label: 'lucky7' },
    { id: 5003, label: 'dragon-tiger' },
    { id: 5004, label: 'baccarat' },
    { id: 5005, label: 'andar-bahar' },
    { id: 5006, label: 'poker' },
    { id: 5007, label: 'Bollywood' },
    { id: 5008, label: 'Region' },
    { id: 5009, label: 'holdem' },
];

function CasinoListNav({activeCasinoTab, setActiveCasinoTab}) {
const navigate = useNavigate();
const dispatch = useDispatch();
const [apiData, setApiData] = useState([]);
const [isLoading, setIsLoading] = useState(false);
const isLoggedIn = localStorage.getItem("user") ? true : false;
const { providerName, gameId } = useParams();

useEffect(() => {
    const firstTab = tabData[0];
    const payload = {
        category: firstTab.label.toLowerCase().replace(/\s+/g, '-'),
        limit: 1000,
    };
    setIsLoading(true);
    dispatch(casinoListByCategory(payload))
        .unwrap()
        .then((response) => {
            if (response) {
                setApiData(response); 
            } else {
                console.warn('No data found in response:', response);
                setApiData([]); 
            }
        })
        .catch((error) => {
            console.error('API call failed:', error);
            setApiData([]);
            setIsLoading(false);
        });
}, [dispatch]);


const handleClick = (id) => {
    setActiveCasinoTab(id);

    const selectedTab = tabData.find((tab) => tab.id === id);
    if (selectedTab) {
        const payload = {
            category: selectedTab.label.toLowerCase().replace(/\s+/g, '-'),
            limit: 1000,
        };
        dispatch(casinoListByCategory(payload))
            .unwrap() 
            .then((response) => {
                if (response && response) {
                    setApiData(response);
                } else {
                    console.warn('No data found in response:', response);
                    setApiData([]);
                }
            })
            .catch((error) => {
                console.error('API call failed:', error); 
                setApiData([]);
            });
        }
    };
        const handleImageClick = (item) => {
            if (isLoggedIn) {
            const iframeURL = `/iframe/${item?.provider_name}/${item?.game_id}`;
            navigate(iframeURL);
            } else {
            navigate("/login");
            }
        };
    
    // const handleImageClick = (item) => {
    //     const iframeURL = `/iframe?game_name=${(item?.provider_name)}&id=${(item?.game_id)}`;
        
    //     navigate(iframeURL);
    // };

    return (
        <>
            <nav className="nav-patti-work">
                <div className="nav nav-tabs" role="tablist">
                    {tabData.map(tab => (
                        <button
                            key={tab.id}
                            className={`nav-link-bottom ${activeCasinoTab === tab.id ? 'active' : ''}`}
                            id={`tab-${tab.id}`}
                            type="button"
                            role="tab"
                            aria-selected={activeCasinoTab === tab.id}
                            onClick={() => handleClick(tab.id)}
                        >
                            {tab.label}
                        </button>
                    ))}
                </div>
            </nav>
            <div className="tab-content row">
                { apiData.length > 0 ? (
                    apiData.map((item, index) => (
                        <div
                            key={index}
                            className={`col-md-2 col-sm-3 patti-images`}
                        >
                            <div className="patti-images2">
                                <div className="cards-sec-inner1"></div>
                                <div className="cards-sec-inner2">
                                    <img
                                        onClick={() => handleImageClick(item)}
                                        className=""
                                        src={item.Image}
                                        alt={item.game_name}
                                    />
                                </div>
                                <p className="text-center mt-2">{item.game_name}</p>
                            </div>
                        </div>
                    ))
                ) : (
                    <p className="text-center">No data available. Click a tab to load content.</p>
                )}
            </div>
        </>
    );
}

export default CasinoListNav;
