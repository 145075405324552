import styles from './styles.module.css';
function Multimarket() {
    return (
        <div className={styles.multimarketMianWrap}>
      <div className={styles.multimarketNoData}>
        <h3>There are currently no followed multi markets.</h3>
        <p>Please add some markets from events.</p>
      </div>
    </div>
    )
}

export default Multimarket
