import { IoCloseSharp } from "react-icons/io5"
import "./FancyBookModal.css"
function FancyBookModal({ handleCloseFancyBookModal, fancyBookdata }) {
    return (
        <>
            <div className="privacy position-fixed d-block" style={{ zIndex: '99' }}>
                <div className="privacy-start">
                    <div className="privacy-startcopy">
                        <div style={{ background: "black", color: "white", padding: '10px 16px' }} className="d-flex flex-wrap justify-content-between align-items-center">
                            <span>Book</span>
                            <span onClick={() => handleCloseFancyBookModal()}><IoCloseSharp /></span>
                        </div>
                        <div className="table-responsive p-2">
                            <table className="table mb-0 w-100 table-bordered" style={{ border: '1px solid black' }}>
                                <thead>
                                    <tr>
                                        <th className="text-center" style={{ width: '50%', backgroundColor: '#e0e6e6' }}><div>Run</div></th>
                                        <th className="text-center" style={{ width: '50%', backgroundColor: '#e0e6e6' }}><div>Amount</div></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {fancyBookdata && fancyBookdata.length > 0 ? fancyBookdata.map((element, index) => (
                                        <tr>
                                            <td className="text-center"><div>{element.key}</div></td>
                                            <td className={`${element.value < 0 ? "textRed" : "textGreen"} text-center`}><div>{element.value}</div></td>
                                        </tr>)) :
                                        <tr>
                                            <td colspan="2" className="text-center"><p>No data!</p></td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>


                    </div>

                </div>
            </div >
        </>
    )
}

export default FancyBookModal
