import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Slider from "react-slick";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import BlinkingComponent from "../../../components/Blinking/blinking";
import BigSuspend from "../../../components/bigsuspend/bigsuspend";
import Suspend from "../../../components/Suspend/suspend";
import CALC from "../../../components/CALC/calc";
import { useParams } from 'react-router-dom';
import { httpPost, httpPostBet } from "../../../middelware/Http";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { defaultSetting, walletBalance } from "../../../redux/_reducers/_reducers";
import Loader from "../../../components/Loader/Loader";
import FancyBookModal from "../../../components/FancyBookModal/FancyBookModal";
import moment from "moment";
import RightSidebarDetailsPage from "../../../components/RightSidebarDetailsPage/RightSidebarDetailsPage";
import { IoTvOutline } from "react-icons/io5";
import InfoModal from "../../../components/InfoModal/InfoModal";
import { FaChartLine } from "react-icons/fa6";

function ViewDetailsPage() {
   const { sportId, matchId } = useParams();
   const [IsFancy, setIsFancy] = useState("tab1");

   const [profitLoss, setProfitLoss] = useState(0);
   const [selectedLineIndex, setSelectedLineIndex] = useState("");
   const [isVisible, setIsVisible] = useState(null);
   const [bookerMakerMarket, setBookerMakerMarket] = useState([]);
   const [matchDetails, setMatchDetails] = useState({});
   const [otherMarketList, setOtherMarketList] = useState([]);
   const [userSportSettings, setUserSportSettings] = useState([]);
   const [bm, setBm] = useState([]);
   const [sessionData, setSessionData] = useState([]);
   const [sessionBetList, setSessionBetList] = useState([]);
   const [matchBetList, setMatchBetList] = useState([]);
   const [betModal, setBetModal] = useState(false)
   const [betSlipData, setBetSlipData] = useState([])
   const [count, setCount] = useState(0);
   const [lodingBet, setLoadingBet] = useState(false);
   const [fancyBookModal, setFancyBookModal] = useState(false)
   const [fancyBookdata, setFancyBookData] = useState(false)
   const [showIframe, setShowIframe] = useState(false);
   const [showScore, setShowScore] = useState(false);
   const [handleOdds, setHandleOdds] = useState('');
   const [balance, setBalance] = useState(0);
   const [infoModal, setInfoModal] = useState(false);
   const { defaultSettingData, walletBalanceData } = useSelector(
      (state) => state.authentication
   );
   const handleIconClick = () => {
      setShowIframe(!showIframe);
   };
   const handleScoreClick = () => {
      setShowScore(!showScore);
   };
   const [value, setValue] = useState(null);

   useEffect(() => {
      if (walletBalanceData?.balance !== undefined && walletBalanceData?.balance !== null) {
         setBalance(walletBalanceData?.balance);  // Set walletBalanceData into the state
      }
   }, [walletBalanceData]);

   // const handleScoreClick = () => {
   //     setShowScore(!showScore);
   //     setShowIframe(false) // Toggle iframe visibility
   // };

   const infoModalOpen = () => {
      setInfoModal(true);
   };

   const infoModalClose = () => {
      setInfoModal(false);
   };

   const toggleVisibility = () => {
      setIsVisible(!isVisible);
   };

   useEffect(() => {
      let isMounted = true; // Track if component is mounted
      dispatch(defaultSetting());
      const fetchData = async () => {
         if (!isMounted) return; // Don't run if unmounted
         try {
            const responseEventDetails = await getEventDetails(matchId);
            const responseSessionDetails = await getEventSession(matchId);
         } catch (error) {
            console.error("Error fetching data:", error);
         }
      };

      const intervalId = setInterval(() => {
         fetchData();
      }, 1000);

      const getBetListData = async () => {
         try {
            await getBetList();
         } catch (error) {
            console.error("Error fetching bet list:", error);
         }
      };

      getBetListData();

      return () => {
         isMounted = false; // Mark the component as unmounted
         clearInterval(intervalId); // Clear interval on unmount
      };
   }, [matchId]);

   const getEventDetails = async (matchId) => {
      try {
         let reqData = {
            "match_id": matchId,
            "sport_id": sportId
         };
         const getMatchDetails = await httpPost(`event-detals`, reqData);
         if (!getMatchDetails.error) {
            const matchDetailsData = getMatchDetails.data;
            setBookerMakerMarket(matchDetailsData?.BookerMakerMarket || []);
            setMatchDetails(matchDetailsData?.MatchDetails || {});
            setOtherMarketList(matchDetailsData?.OtherMarketList || []);
            setUserSportSettings(matchDetailsData?.UserSportSettings || []);
            setBm(matchDetailsData?.bm || []);


            const match_stack = matchDetailsData?.UserSportSettings?.[0]?.match_stack;

            if (match_stack) {
               // Get the current data from localStorage
               const storedMatchStack = localStorage.getItem('match_stack');

               // If the data is not already in localStorage, save it
               if (!storedMatchStack) {
                  localStorage.setItem('match_stack', match_stack);
               }
            }


         } else {
            console.error("Error in fetching match details");
         }
      } catch (error) {
         console.error("Error in getEventDetails:", error);
      }
   };


   const tvData = defaultSettingData?.find((item) => item.key === "site.tv_link");

   useEffect(() => {
      if (tvData && tvData.value !== undefined) {
         setValue(tvData.value);
      }
   }, [tvData]);

   const getEventSession = async (matchId) => {
      try {
         let reqData = {
            "match_id": matchId
         };
         const getSessionDetails = await httpPost(`event-session`, reqData);

         if (!getSessionDetails.error) {
            const sessionDetailsData = getSessionDetails.data;
            setSessionData(sessionDetailsData || []);

         } else {
            console.error("Error in fetching session details");
         }
      } catch (error) {
         console.error("Error in getEventSession:", error);
      }
   };
   const getBetList = async () => {
      try {
         let reqData = {
            "limit": 10,
            "match_id": matchId,
            "market_id": "0",
            "fancy_id": 0,
            "pageno": 1
         }
         const getBetListData = await httpPost(`list-bt-ssn-mk`, reqData);

         if (!getBetListData.error) {
            const betData = getBetListData.data;
            setMatchBetList(betData?.MatchAndBetfair || []);
            setSessionBetList(betData?.MatchFancy || []);

         } else {
            console.error("Error in fetching session details");
         }
      } catch (error) {
         console.error("Error in getEventSession:", error);
      }
   };


   const handleOpenData = (data) => {
      if (data?.odds > 0) {
         if (balance > 0) {
            setSelectedLineIndex(data?.selection_id);
            setBetModal(true);
            setBetSlipData(data);
            setCount(0);
            setHandleOdds(data?.odds);

         } else {
            toast.error("Insufficient balance to place a bet.");
         }
      } else {
         toast.error("Odds is 0 , Bet is Not Placed");
      }
   }

   const handleCloseModal = () => {
      setBetModal(false)
      setCount(0)
      setProfitLoss([])
   }
   const handleFancyBookModal = (data) => {
      setFancyBookData(data)
      setFancyBookModal(true)
   }

   const handleCloseFancyBookModal = () => {
      setFancyBookModal(false)
   }
   const dispatch = useDispatch();
   const handlePlaceBet = async () => {
      loaderFunction()
      setLoadingBet(true);
      setCount(0)
      try {
         const betObject = {
            "match_id": matchId,
            "market_id": betSlipData.market_id,
            "selection_id": betSlipData.selection_id,
            "odds": handleOdds + "",
            "stack": Number(count),
            "is_back": betSlipData.is_back
         }
         const sessionObject = {
            "match_id": matchId,
            "fancy_id": betSlipData.selection_id,
            "run": handleOdds + "",
            "size": betSlipData.size + "",
            "stack": Number(count),
            "is_back": betSlipData.is_back,
            "fancyStatus": "A",
            "sport_id": sportId,
         }

         setBetModal(false);

         let saveBetOdds;
         if (betSlipData.type === "fancy") {
            saveBetOdds = await httpPostBet("save-ssn-bet", sessionObject);
         } else {
            saveBetOdds = await httpPostBet("save-bet", betObject);
         }

         if (saveBetOdds.error === false) {
            toast.success(saveBetOdds.message);
            await getBetList();
            dispatch(walletBalance());
         } else {
            toast.error(`Bet failed: ${saveBetOdds.message || 'not found'}`);
         }

      } catch (error) {
         console.error('Error placing bet:', error);
      } finally {
         setLoadingBet(false);
         setProfitLoss([])
      }
   };

   const loaderFunction = () => {
      setLoadingBet(true);
   }

   let scoreLink = defaultSettingData && defaultSettingData[9]?.value
   let scoreLinkDynamic = ''

   if (scoreLink) {
      scoreLinkDynamic = `${scoreLink}${matchId}`;
   }

   const [detailodds, setDetailodds] = useState([
      { id: 1, name: "Australia" },
   ]);
   const handleTabClick = (tab) => {
      setIsFancy(tab);
   };

   return (
      <>
         <div className="row mx-auto">
            <div className="col-lg-9 col-12 px-0">
               <div className="score-tabcontent-work px-0">
                  {lodingBet && (
                     <Loader />
                  )}
                  {fancyBookModal && (
                     <FancyBookModal handleCloseFancyBookModal={handleCloseFancyBookModal} fancyBookdata={fancyBookdata} />
                  )}
                  {
                     infoModal && (
                        <InfoModal infoModalClose={infoModalClose} />
                     )
                  }
                  <div className="row">
                     <div className="col-lg-12">
                        <div className="d-none d-lg-block" style={{ background: "black", color: "white", padding: "2px", paddingInline: "5px", marginBottom: "2px" }} onClick={() => handleScoreClick()}>Score Streaming</div>
                        <div className="d-flex flex-wrap d-lg-none d-flex justify-content-lg-center justify-content-between align-items-center" style={{ background: "black", color: "white", borderTop: '1px solid white', paddingInline: "4px" }}>
                           <div>    <span onClick={() => handleScoreClick()}><FaChartLine size={20} /></span></div>
                           <div>{matchDetails?.SportName}</div>
                           <div className="p-1">

                              <span onClick={() => handleIconClick()}><IoTvOutline size={20} /></span>  </div>
                        </div>

                        {matchDetails?.sportGraphic === "Y" ?
                           <div>
                              {showScore && (
                                 <>
                                    {balance > 0 && scoreLinkDynamic ? (
                                       <iframe
                                          src={scoreLinkDynamic}
                                          width="100%"
                                          height="280"
                                          frameBorder="0"
                                          scrolling="no"
                                          marginWidth="0"
                                          allowFullScreen
                                          webkitAllowFullScreen
                                          mozAllowFullScreen
                                          style={{ overflow: 'hidden' }}
                                       ></iframe>
                                    )
                                       : null}
                                 </>
                              )}

                              {showIframe && (
                                 <>
                                    <div className="d-lg-none d-flex">
                                       <iframe
                                          // src={tvData?.value ? matchDetails?.MainTV : null} 
                                          src={tvData?.value ? `${tvData?.value}${matchId}` : matchDetails?.MainTV}
                                          // src={matchDetails?.MainTV}
                                          style={{ width: '100%', height: '280px' }}
                                          title="MainTV Content"
                                       />
                                    </div>
                                 </>
                              )}
                           </div> : null}
                        {/* <Slider {...settings} className="tab-content-slider">
                  <div className="item">
                     <img src="/images/slide-1.jpg" alt="" />
                  </div>
                  <div className="item">
                     <img src="/images/slide-2.jpg" alt="" />
                  </div>
                  <div className="item">
                     <img src="/images/slide-3.jpg" alt="" />
                  </div>
                  <div className="item">
                     <img src="/images/slide-4.jpg" alt="" />
                  </div>
                  <div className="item">
                     <img src="/images/slide-5.jpg" alt="" />
                  </div>
                  <div className="item">
                     <img src="/images/slide-6.jpg" alt="" />
                  </div>
               </Slider> */}


                        {matchDetails.runner_json && (
                           <div className="detail-odds">
                              <div className="match-odds d-flex flex-wrap justify-content-between align-items-center">
                                 <div className="match-oddsleft">
                                    <ul className="matchodds-heading">
                                       <li className="d-flex flex-wrap align-items-center">
                                          <p className="mb-0">{matchDetails?.marketName}</p>
                                          <span onClick={() => infoModalOpen()} className="ps-2"><AiOutlineExclamationCircle size={15} color="currentColor" /></span>
                                       </li>
                                    </ul>
                                 </div>

                                 <div className="d-md-none d-flex flex-wrap detail-oddsgroup">
                                    <div className="text-center min-maxdetail">
                                       <div className="d-flex min-maxdetail2 align-items-center flex-wrap justify-content-center py-1">
                                          <p className="mb-0">min/max</p>
                                          <span>{matchDetails?.marketMinStack || 0}-{matchDetails?.marketMaxStack || 0}</span>
                                       </div>
                                    </div>
                                 </div>
                                 {/* <div className="match-oddsright px-2">
                           <p className="mb-0">Matched<span>€ 28.1M</span></p>
                        </div> */}
                              </div>

                              <div className="all-bits-detail ">
                                 <div className="detail-oddsbit d-flex flex-wrap align-items-center justify-content-between">
                                    <div className="detail-oddsleft"><p className="mb-0"></p></div>

                                    <div className="detail-oddsright d-flex flex-wrap">
                                       <div className="d-flex flex-warp detail-oddsgroup">
                                          <div className="detail-odds-single text-center bg-transprent"></div>
                                          <div className="detail-odds-single text-center bg-transprent"></div>
                                       </div>

                                       <div className="d-flex flex-warp detail-oddsgroup">
                                          <div className="detail-odds-single text-center">
                                             <p className="mb-0 py-2">Back</p>
                                          </div>
                                          <div className="detail-odds-single text-center">
                                             <p className="mb-0  py-2">Lay</p>
                                          </div>
                                       </div>

                                       <div className="d-flex flex-warp detail-oddsgroup">
                                          <div className="text-center min-maxdetail">
                                             <div className="d-flex min-maxdetail2 align-items-center flex-wrap justify-content-center py-1">
                                                <p className="mb-0">min/max</p>
                                                <span>{matchDetails?.marketMinStack || 0}-{matchDetails?.marketMaxStack || 0}</span>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>

                                 <div className="position-relative">
                                    {matchDetails && matchDetails.runner_json ? matchDetails.runner_json.map((element, index) => (
                                       <div className="detail-oddsbit d-flex flex-wrap align-items-center justify-content-between ">
                                          <div className="detail-oddsleft"><p className="mb-0 fontSize15">{element?.selectionName}</p>
                                             {(parseFloat(element?.WinAndLoss) !== 0) && (<span className={`${parseFloat(element?.WinAndLoss) < 0 ? "red-text" : "green-text"}`}>{parseFloat(element?.WinAndLoss).toFixed(2).replace(/\.?0+$/, "")}</span>)}

                                             {(element?.WinAndLoss != null && profitLoss[element?.selectionId] != null && profitLoss[element?.selectionId] !== 0 &&
                                                parseFloat(element?.WinAndLoss + Number(profitLoss[element?.selectionId])) !== 0) && (
                                                   <span className={`${parseFloat(element?.WinAndLoss + Number(profitLoss[element?.selectionId])) < 0 ? "red-text" : "green-text"}`}  >
                                                      {" "}({parseFloat(element?.WinAndLoss + Number(profitLoss[element?.selectionId])).toFixed(2).replace(/\.?0+$/, "")})
                                                   </span>
                                                )}
                                          </div>


                                          <div className="detail-oddsright d-flex flex-wrap position-relative">
                                             <div className="d-flex flex-warp detail-oddsgroup">
                                                {element && element.ex && element.ex.availableToBack ? element.ex.availableToBack.map((elementInner, index) => {
                                                   if (index === 0) return null;
                                                   return (
                                                      <div className="detail-odds-single text-center " key={index}>
                                                         <BlinkingComponent price={elementInner.price} size={elementInner.size} color="" className="" />
                                                      </div>
                                                   )
                                                }) : null
                                                }
                                             </div>

                                             <div className="d-flex flex-wrap detail-oddsgroup">
                                                {element && element.ex && element.ex.availableToBack && element.ex.availableToBack[0] ? (
                                                   <div className="detail-odds-single text-center" style={{ borderRadius: '0' }}
                                                      onClick={() => handleOpenData({
                                                         "market_id": matchDetails?.market_id,
                                                         "selection_id": element?.selectionId,
                                                         "odds": element.ex.availableToBack[0]?.price,
                                                         "is_back": "1",
                                                         "name": element?.selectionName,
                                                         "type": matchDetails?.marketName,
                                                         "matchDetails": matchDetails,
                                                      })}                                 >
                                                      <BlinkingComponent
                                                         price={element.ex.availableToBack[0].price}
                                                         size={element.ex.availableToBack[0].size}
                                                         color=""
                                                         className=""
                                                      />
                                                   </div>
                                                ) : null}
                                                {element && element.ex && element.ex.availableToLay && element.ex.availableToLay[0] ? (
                                                   <div className="detail-odds-single text-center" style={{ borderRadius: '0' }}
                                                      onClick={() => handleOpenData({
                                                         "market_id": matchDetails?.market_id,
                                                         "selection_id": element?.selectionId,
                                                         "odds": element.ex.availableToLay[0]?.price,
                                                         "is_back": "0",
                                                         "name": element?.selectionName,
                                                         "type": matchDetails?.marketName,
                                                         "matchDetails": matchDetails,
                                                      })}>
                                                      <BlinkingComponent
                                                         price={element.ex.availableToLay[0].price}
                                                         size={element.ex.availableToLay[0].size}
                                                         color=""
                                                         className=""
                                                      />
                                                   </div>
                                                ) : null}
                                             </div>

                                             <div className="d-flex flex-warp detail-oddsgroup">
                                                {element && element.ex && element.ex.availableToLay ? element.ex.availableToLay.slice(1, 3).map((elementInner, index) => (
                                                   <>
                                                      <div className="detail-odds-single text-center" key={index}>
                                                         <BlinkingComponent price={elementInner.price} size={elementInner.size} color="" className="" />
                                                      </div>
                                                   </>

                                                )) : null}
                                             </div>
                                             {element.status === "ACTIVE" ? null :
                                                <BigSuspend />
                                             }
                                          </div>
                                          {selectedLineIndex === element.selectionId && (
                                             <>
                                                {betModal ? <CALC matchDetails={matchDetails} handleCloseModal={handleCloseModal} betModal={betModal} betSlipData={betSlipData} handlePlaceBet={handlePlaceBet} setCount={setCount} lodingBet={lodingBet} count={count} handleOdds={handleOdds} setHandleOdds={setHandleOdds} setProfitLoss={setProfitLoss} profitLoss={profitLoss} /> : null}
                                             </>
                                          )
                                          }
                                       </div>
                                    )) : null}

                                    {matchDetails.adminMessage != '' || (matchDetails.InplayStatus == 'CLOSE' || matchDetails.InplayStatus == 'SUSPENDED') || matchDetails.IsBetAllow == 'N' ?
                                       <BigSuspend GameStatus={matchDetails.adminMessage != '' ? matchDetails.adminMessage :
                                          (matchDetails.InplayStatus == 'CLOSE' || matchDetails.InplayStatus == 'SUSPENDED') ? 'SUSPENDED' :
                                             (matchDetails.IsBetAllow == 'N') ? 'Bet locked' : "Bet SUSPENDED"} /> : null
                                    }
                                 </div>
                              </div>
                           </div>
                        )}

                        {bookerMakerMarket.runner_json && (
                           <div className="detail2-odds">
                              <div className="match-odds2 d-flex flex-wrap justify-content-between align-items-center">
                                 <div className="match-oddsleft2">
                                    <ul className="matchodds-heading">
                                       <li className="d-flex flex-wrap align-items-center">
                                          <p className="mb-0">{bookerMakerMarket?.marketName}</p>
                                          <span onClick={() => handleIconClick()} className="ps-2"><AiOutlineExclamationCircle size={15} color="currentColor" /></span>
                                       </li>
                                    </ul>
                                 </div>
                                 <div className="d-md-none d-flex flex-wrap detail-oddsgroup">
                                    <div className="text-center min-maxdetail">
                                       <div className="d-flex min-maxdetail2 align-items-center flex-wrap justify-content-center py-1">
                                          <p className="mb-0">min/max</p>
                                          <span>{bookerMakerMarket?.marketMinStack || 0}-{bookerMakerMarket?.marketMaxStack || 0}</span>
                                       </div>
                                    </div>
                                 </div>

                                 {/* <div className="match-oddsright px-2">
                           <p className="mb-0">Matched<span>€ 28.1M</span></p>
                        </div> */}
                              </div>

                              <div className="all-bits-detail2">
                                 <div className="detail-oddsbit2 d-flex flex-wrap align-items-center justify-content-between">
                                    <div className="detail-oddsleft2"><p className="mb-0"></p></div>

                                    <div className="detail-oddsright2 d-flex flex-wrap" style={{ backgroundColor: '#faf8d8', boxShadow: 'none' }}>
                                       <div className="d-flex flex-warp detail-oddsgroup2">
                                          <div className="detail-odds-single text-center bg-transprent"></div>
                                          <div className="detail-odds-single text-center bg-transprent"></div>
                                       </div>

                                       <div className="d-flex flex-warp detail-oddsgroup2">
                                          <div className="detail-odds-single text-center">
                                             <p className="mb-0 py-2">Back</p>
                                          </div>
                                          <div className="detail-odds-single text-center">
                                             <p className="mb-0  py-2">Lay</p>
                                          </div>
                                       </div>

                                       <div className="d-flex flex-warp detail-oddsgroup2">
                                          <div className="text-center min-maxdetail">
                                             <div className="d-flex min-maxdetail2 align-items-center flex-wrap justify-content-center py-1">
                                                <p className="mb-0">min/max</p>
                                                <span>{bookerMakerMarket?.marketMinStack || 0}-{bookerMakerMarket?.marketMaxStack || 0}</span>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="position-relative">
                                    {bookerMakerMarket && bookerMakerMarket.runner_json ? bookerMakerMarket.runner_json.map((element, index) => (
                                       <div className="detail-oddsbit2 d-flex flex-wrap align-items-center justify-content-between">
                                          <div className="detail-oddsleft2"><p className="mb-0 fontSize15">{element?.RunnerName}</p>
                                             {(parseFloat(element?.WinAndLoss) !== 0) && (<span className={`${parseFloat(element?.WinAndLoss) < 0 ? "red-text" : "green-text"}`}>{parseFloat(element?.WinAndLoss).toFixed(2).replace(/\.?0+$/, "")}</span>)}
                                             {(element?.WinAndLoss != null && profitLoss[element?.selectionId] != null && profitLoss[element?.selectionId] !== 0 &&
                                                parseFloat(element?.WinAndLoss + Number(profitLoss[element?.selectionId])) !== 0) && (
                                                   <span className={`${parseFloat(element?.WinAndLoss + Number(profitLoss[element?.selectionId])) < 0 ? "red-text" : "green-text"}`}  >
                                                      {" "}({parseFloat(element?.WinAndLoss + Number(profitLoss[element?.selectionId])).toFixed(2).replace(/\.?0+$/, "")})
                                                   </span>
                                                )}
                                          </div>


                                          <div className="detail-oddsright2 d-flex flex-wrap position-relative">
                                             <div className="d-flex flex-warp detail-oddsgroup2">
                                                {element && element.ex && element.ex.availableToBack ? element.ex.availableToBack.map((elementInner, index) => {
                                                   if (index === 0) return null;
                                                   return (
                                                      <div className="detail-odds-single2 text-center" key={index}>
                                                         <BlinkingComponent price={elementInner.price} size={elementInner.size} color="" className="" />
                                                      </div>
                                                   )
                                                }) : null
                                                }
                                             </div>

                                             <div className="d-flex flex-wrap detail-oddsgroup2">
                                                {element && element.ex && element.ex.availableToBack && element.ex.availableToBack[0] ? (
                                                   <div className="detail-odds-single2 text-center" style={{ borderRadius: '0' }}
                                                      onClick={() => handleOpenData({
                                                         "market_id": bookerMakerMarket?.market_id,
                                                         "selection_id": element?.selectionId,
                                                         "odds": element.ex.availableToBack[0]?.price,
                                                         "is_back": "1",
                                                         "name": element?.selectionName,
                                                         "type": bookerMakerMarket?.marketName,
                                                         "matchDetails": bookerMakerMarket,
                                                      })}                                 >
                                                      <BlinkingComponent
                                                         price={element.ex.availableToBack[0].price}
                                                         size={element.ex.availableToBack[0]?.size}
                                                         color=""
                                                         className=""
                                                      />
                                                   </div>
                                                ) : null}
                                                {element && element.ex && element.ex.availableToLay && element.ex.availableToLay[0] ? (
                                                   <div className="detail-odds-single2 text-center" style={{ borderRadius: '0' }}
                                                      onClick={() => handleOpenData({
                                                         "market_id": bookerMakerMarket?.market_id,
                                                         "selection_id": element?.selectionId,
                                                         "odds": element.ex.availableToLay[0]?.price,
                                                         "is_back": "0",
                                                         "name": element?.selectionName,
                                                         "type": bookerMakerMarket?.marketName,
                                                         "matchDetails": bookerMakerMarket,
                                                      })}>
                                                      <BlinkingComponent
                                                         price={element.ex.availableToLay[0].price}
                                                         size={element.ex.availableToLay[0].size}
                                                         color=""
                                                         className=""
                                                      />
                                                   </div>
                                                ) : null}
                                             </div>

                                             <div className="d-flex flex-warp detail-oddsgroup2">
                                                {element && element.ex && element.ex.availableToLay ? element.ex.availableToLay.slice(1, 3).map((elementInner, index) => (
                                                   <>
                                                      <div className="detail-odds-single2 text-center" key={index}>
                                                         <BlinkingComponent price={elementInner.price} size={elementInner.size} color="" className="" />
                                                      </div>
                                                   </>

                                                )) : null}
                                             </div>
                                             {element?.GameStatus && <BigSuspend GameStatus={element.GameStatus} />}
                                          </div>
                                          {selectedLineIndex === element.selectionId && (
                                             <>
                                                {betModal ? <CALC matchDetails={bookerMakerMarket} handleCloseModal={handleCloseModal} betModal={betModal} betSlipData={betSlipData} handlePlaceBet={handlePlaceBet} setCount={setCount} lodingBet={lodingBet} count={count} handleOdds={handleOdds} setHandleOdds={setHandleOdds} setProfitLoss={setProfitLoss} profitLoss={profitLoss} /> : null}
                                             </>
                                          )
                                          }
                                       </div>
                                    )) : null}
                                    {bookerMakerMarket.adminMessage != '' || (bookerMakerMarket.InplayStatus == 'CLOSE' || bookerMakerMarket.InplayStatus == 'SUSPENDED') || bookerMakerMarket.IsBetAllow == 'N' ?
                                       <BigSuspend GameStatus={bookerMakerMarket.adminMessage != '' ? bookerMakerMarket.adminMessage :
                                          (bookerMakerMarket.InplayStatus == 'CLOSE' || bookerMakerMarket.InplayStatus == 'SUSPENDED') ? 'SUSPENDED' :
                                             (bookerMakerMarket.IsBetAllow == 'N') ? 'Bet locked' : "Bet SUSPENDED"} /> : null
                                    }
                                 </div>
                              </div>
                           </div>
                        )}

                        {otherMarketList && otherMarketList.length > 0 ? otherMarketList.map((otherData, index) => (
                           <div className="detail-odds">
                              <div className="match-odds d-flex flex-wrap justify-content-between align-items-center">
                                 <div className="match-oddsleft">
                                    <ul className="matchodds-heading">
                                       <li className="d-flex flex-wrap align-items-center">
                                          <p className="mb-0">{otherData?.marketName}</p>
                                          <span onClick={() => handleIconClick()} className="ps-2"><AiOutlineExclamationCircle size={15} color="currentColor" /></span>
                                       </li>
                                    </ul>
                                 </div>
                                 <div className="d-md-none d-flex flex-wrap detail-oddsgroup">
                                    <div className="text-center min-maxdetail">
                                       <div className="d-flex min-maxdetail2 align-items-center flex-wrap justify-content-center py-1">
                                          <p className="mb-0">min/max</p>
                                          <span>{otherData?.marketMinStack || 0}-{otherData?.marketMaxStack || 0}</span>
                                       </div>
                                    </div>
                                 </div>

                                 {/* <div className="match-oddsright px-2">
                           <p className="mb-0">Matched<span>€ 28.1M</span></p>
                        </div> */}
                              </div>

                              <div className="all-bits-detail">
                                 <div className="detail-oddsbit d-flex flex-wrap align-items-center justify-content-between">
                                    <div className="detail-oddsleft"><p className="mb-0"></p></div>

                                    <div className="detail-oddsright d-flex flex-wrap">
                                       <div className="d-flex flex-warp detail-oddsgroup">
                                          <div className="detail-odds-single text-center bg-transprent"></div>
                                          <div className="detail-odds-single text-center bg-transprent"></div>
                                       </div>

                                       <div className="d-flex flex-warp detail-oddsgroup">
                                          <div className="detail-odds-single text-center">
                                             <p className="mb-0 py-2">Back</p>
                                          </div>
                                          <div className="detail-odds-single text-center">
                                             <p className="mb-0  py-2">Lay</p>
                                          </div>
                                       </div>

                                       <div className="d-flex flex-warp detail-oddsgroup">
                                          <div className="text-center min-maxdetail">
                                             <div className="d-flex min-maxdetail2 align-items-center flex-wrap justify-content-center py-1">
                                                <p className="mb-0">min/max</p>
                                                <span>{otherData?.marketMinStack || 0}-{otherData?.marketMaxStack || 0}</span>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="position-relative">
                                    {otherData && otherData.runner_json ? otherData.runner_json.map((element, index) => (
                                       <div className="detail-oddsbit d-flex flex-wrap align-items-center justify-content-between ">
                                          <div className="detail-oddsleft"><p className="mb-0 fontSize15">{element?.selectionName}</p>
                                             {(parseFloat(element?.WinAndLoss) !== 0) && (<span className={`${parseFloat(element?.WinAndLoss) < 0 ? "red-text" : "green-text"}`}>{parseFloat(element?.WinAndLoss).toFixed(2).replace(/\.?0+$/, "")}</span>)}
                                             {(element?.WinAndLoss != null && profitLoss[element?.selectionId] != null && profitLoss[element?.selectionId] !== 0 &&
                                                parseFloat(element?.WinAndLoss + Number(profitLoss[element?.selectionId])) !== 0) && (
                                                   <span className={`${parseFloat(element?.WinAndLoss + Number(profitLoss[element?.selectionId])) < 0 ? "red-text" : "green-text"}`}  >
                                                      {" "}({parseFloat(element?.WinAndLoss + Number(profitLoss[element?.selectionId])).toFixed(2).replace(/\.?0+$/, "")})
                                                   </span>
                                                )}
                                          </div>


                                          <div className="detail-oddsright d-flex flex-wrap position-relative">
                                             <div className="d-flex flex-warp detail-oddsgroup">
                                                {element && element.ex && element.ex.availableToBack ? element.ex.availableToBack.map((elementInner, index) => {
                                                   if (index === 0) return null;
                                                   return (
                                                      <div className="detail-odds-single text-center" key={index}>
                                                         <BlinkingComponent price={elementInner.price} size={elementInner.size} color="" className="" />
                                                      </div>
                                                   )
                                                }) : null
                                                }
                                             </div>

                                             <div className="d-flex flex-wrap detail-oddsgroup">
                                                {element && element.ex && element.ex.availableToBack && element.ex.availableToBack[0] ? (
                                                   <div className="detail-odds-single text-center" style={{ borderRadius: '0' }}
                                                      onClick={() => handleOpenData({
                                                         "market_id": otherData?.market_id,
                                                         "selection_id": element?.selectionId,
                                                         "odds": element.ex.availableToBack[0]?.price,
                                                         "is_back": "1",
                                                         "name": element?.selectionName,
                                                         "type": otherData?.marketName,
                                                         "matchDetails": otherData,
                                                      })}                                 >
                                                      <BlinkingComponent
                                                         price={element.ex.availableToBack[0].price}
                                                         size={element.ex.availableToBack[0]?.size}
                                                         color=""
                                                         className=""
                                                      />
                                                   </div>
                                                ) : null}
                                                {element && element.ex && element.ex.availableToLay && element.ex.availableToLay[0] ? (
                                                   <div className="detail-odds-single text-center" style={{ borderRadius: '0' }}
                                                      onClick={() => handleOpenData({
                                                         "market_id": otherData?.market_id,
                                                         "selection_id": element?.selectionId,
                                                         "odds": element.ex.availableToLay[0]?.price,
                                                         "is_back": "0",
                                                         "name": element?.selectionName,
                                                         "type": otherData?.marketName,
                                                         "matchDetails": otherData,
                                                      })}>
                                                      <BlinkingComponent
                                                         price={element.ex.availableToLay[0].price}
                                                         size={element.ex.availableToLay[0].size}
                                                         color=""
                                                         className=""
                                                      />
                                                   </div>
                                                ) : null}
                                             </div>

                                             <div className="d-flex flex-warp detail-oddsgroup">
                                                {element && element.ex && element.ex.availableToLay ? element.ex.availableToLay.slice(1, 3).map((elementInner, index) => (
                                                   <>
                                                      <div className="detail-odds-single text-center" key={index}>
                                                         <BlinkingComponent price={elementInner.price} size={elementInner.size} color="" className="" />
                                                      </div>
                                                   </>

                                                )) : null}
                                             </div>
                                             {element?.GameStatus && <BigSuspend GameStatus={element.GameStatus} />}

                                          </div>
                                          {selectedLineIndex === element.selectionId && (
                                             <>
                                                {betModal ? <CALC matchDetails={otherData} handleCloseModal={handleCloseModal} betModal={betModal} betSlipData={betSlipData} handlePlaceBet={handlePlaceBet} setCount={setCount} lodingBet={lodingBet} count={count} handleOdds={handleOdds} setHandleOdds={setHandleOdds} setProfitLoss={setProfitLoss} profitLoss={profitLoss} /> : null}
                                             </>
                                          )
                                          }
                                       </div>
                                    )) : null}
                                    {otherData.adminMessage != '' || (otherData.InplayStatus == 'CLOSE' || otherData.InplayStatus == 'SUSPENDED') || otherData.IsBetAllow == 'N' ?
                                       <BigSuspend GameStatus={otherData.adminMessage != '' ? otherData.adminMessage :
                                          (otherData.InplayStatus == 'CLOSE' || otherData.InplayStatus == 'SUSPENDED') ? 'SUSPENDED' :
                                             (otherData.IsBetAllow == 'N') ? 'Bet locked' : "Bet SUSPENDED"} /> : null
                                    }
                                 </div>
                              </div>
                           </div>

                        )) : null}





                        {sessionData && sessionData.length > 0 && (
                           <div className="fancy-data">
                              <div className="flex flex-col items-center">
                                 <div className="flex space-x-4  fancy-datawork">
                                    <button
                                       className={`d-flex ${IsFancy === "tab1" ? "show" : "hide"}`}
                                       onClick={() => handleTabClick("tab1")}
                                    >
                                       <p className="mb-0">Fancy Bet</p>
                                       <span onClick={() => handleIconClick()}><AiOutlineExclamationCircle /></span>
                                    </button>
                                 </div>
                                 <div className="fancy mb-4">
                                    {IsFancy === "tab1" &&
                                       <div className="all-bits-detail ">
                                          <div className="detail-oddsbit d-flex flex-wrap align-items-center justify-content-between">
                                             <div className="detail-oddsleft"><p className="mb-0"></p></div>

                                             <div className="detail-oddsright d-flex flex-wrap align-items-center">
                                                <div className="d-flex flex-warp detail-oddsgroup">
                                                   <div className="detail-odds-single text-center bg-transprent"></div>
                                                   <div className="detail-odds-single text-center bg-transprent"></div>
                                                </div>

                                                <div className="d-flex flex-warp detail-oddsgroup">
                                                   <div className="detail-odds-single text-center">
                                                      <p className="mb-0 py-2 pink">No</p>
                                                   </div>
                                                   <div className="detail-odds-single text-center">
                                                      <p className="mb-0 py-2 blue">Yes</p>

                                                   </div>
                                                </div>

                                                <div className="d-flex flex-warp detail-oddsgroup">
                                                   <div className="text-center min-maxdetail">
                                                      <div className="d-flex align-items-center flex-wrap justify-content-center py-1">
                                                         <p className="fancy-minamax2 mb-0">min/max</p>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                          {sessionData && sessionData.length > 0 ? sessionData.map((data, index) => (
                                             <div key={index} className="detail-oddsbit d-flex flex-wrap align-items-center justify-content-between">
                                                <div className="detail-oddsleft"><p className="mb-0 fontSize15">{data.RunnerName}</p></div>

                                                <div className="detail-oddsright d-flex flex-wrap  align-items-center position-relative">
                                                   <div className="d-flex flex-warp detail-oddsgroup">
                                                      <div className="detail-odds-single text-center bg-transprent w-100 justify-content-center d-flex">
                                                         {data.scorePostion === null ? null :
                                                            <>
                                                               <button className="book-btn d-md-block d-none" onClick={() => handleFancyBookModal(data.scorePostion)}>Book</button>
                                                               <div className="d-md-none d-block" onClick={() => handleFancyBookModal(data.scorePostion)}> <AiOutlineExclamationCircle size={20} /></div>
                                                            </>}

                                                      </div>
                                                   </div>

                                                   <div className="d-flex flex-warp detail-oddsgroup">
                                                      <div className="detail-odds-single text-center pink" onClick={() => handleOpenData({
                                                         "selection_id": data?.SelectionId,
                                                         "odds": data?.LayPrice1,
                                                         "size": data?.LaySize1,
                                                         "is_back": "0",
                                                         "name": data?.RunnerName,
                                                         "type": "fancy",
                                                      })}>
                                                         <BlinkingComponent price={data.LayPrice1} size={data.LaySize1} color="" type="fancy" className="" />
                                                      </div>
                                                      <div className="detail-odds-single text-center blue" onClick={() => handleOpenData({
                                                         "selection_id": data?.SelectionId,
                                                         "odds": data?.BackPrice1,
                                                         "size": data?.BackSize1,
                                                         "is_back": "1",
                                                         "name": data?.RunnerName,
                                                         "type": "fancy",
                                                      })}>
                                                         <BlinkingComponent price={data.BackPrice1} size={data.BackSize1} color="" type="fancy" className="" />
                                                      </div>

                                                   </div>
                                                   <div className="d-flex flex-warp detail-oddsgroup">
                                                      <div className="text-center min-maxdetail">
                                                         <div className="d-flex align-items-center flex-wrap justify-content-center py-1">
                                                            <p className="fancy-minmax mb-0">{data?.minStack || 0} - {data?.maxStack || 0}</p>
                                                         </div>
                                                      </div>
                                                   </div>
                                                   {data.adminMessage != '' || (data.inplayStatus == 'CLOSE' || data.inplayStatus == 'SUSPENDED') || data.IsBetAllow == 'N' ?
                                                      <Suspend GameStatus={data.adminMessage != '' ? data.adminMessage :
                                                         (data.inplayStatus == 'CLOSE' || data.inplayStatus == 'SUSPENDED') ? 'SUSPENDED' :
                                                            (data.IsBetAllow == 'N') ? 'Bet locked' : "Bet SUSPENDED"} /> : null
                                                   }

                                                </div>
                                                {selectedLineIndex === data.SelectionId && (
                                                   <>
                                                      {betModal ? <CALC matchDetails={sessionData} handleCloseModal={handleCloseModal} betModal={betModal} betSlipData={betSlipData} handlePlaceBet={handlePlaceBet} setCount={setCount} lodingBet={lodingBet} count={count} handleOdds={handleOdds} setHandleOdds={setHandleOdds} setProfitLoss={setProfitLoss} profitLoss={profitLoss} /> : null}
                                                   </>
                                                )
                                                }
                                             </div>
                                          )) : null}
                                       </div>
                                    }
                                 </div>
                              </div>
                           </div>)}
                        {/* <div className="detail-hide d-lg-none d-flex flex-column pb-5">
                           {sessionBetList && sessionBetList.length > 0 ?
                              <div>
                                 <div className="fancy-oddgreen" style={{ backgroundColor: '#e0e6e6' }}><h4>Fancy</h4></div>
                                 <div className="d-flex justify-content-between mt+2">
                                    <div className="text-start ms-1" style={{ width: '55%' }}>Back (Bet For)</div>
                                    <div className="text-center" style={{ width: '15%' }}>Odds</div>
                                    <div className="text-center" style={{ width: '15%' }}>Stake</div>
                                    <div className="text-center" style={{ width: '15%' }}>Profit</div>
                                 </div>
                                 <div>
                                    {sessionBetList && sessionBetList.length > 0 ? sessionBetList.map((element, index) => (
                                       <>
                                          <div
                                             className="d-flex justify-content-start align-items-center ps-1"
                                             style={{
                                                backgroundColor: element.is_back === "1" ? "#beddf4" : "#f3dce2",
                                                color: "#3b5160",
                                                fontSize: "10px",
                                             }}
                                          >
                                             Ref: {moment.unix(element.created_at).format("DD-MM-YYYY HH:MM:SS")}
                                          </div>
                                          <div
                                             className="d-flex justify-content-between teamname-div teamNameFancy"
                                             style={{
                                                backgroundColor: element.is_back === "1" ? "#beddf4" : "#f3dce2",
                                                border: element.is_back === "1" ? "1px solid #7dbbe9" : "1px solid #dfa3b3",
                                             }}
                                          >
                                             <div className={`d-flex gap-2 ${element.is_back === "1" ? "teamname-bet" : "teamname-bet2"
                                                }`}
                                                style={{ width: "55%" }}
                                             >
                                                <div className="d-flex gap-2">
                                                   <span className="d-flex align-items-center justify-content-center"
                                                      style={{
                                                         padding: "2px",
                                                         borderRadius: "5px",
                                                         color: "black",
                                                         fontWeight: "bold",
                                                         backgroundColor: element.is_back === "1" ? "#7dbbe9" : "#dfa3b3",
                                                         border: `1px solid ${element.is_back === "1" ? "#7dbbe9" : "#dfa3b3"}`,
                                                      }}
                                                   >
                                                      {element.is_back === "1" ? "Back" : "Lay"}
                                                   </span>
                                                   <span>
                                                      <div><strong>{element?.fancy_name}</strong></div>
                                                   </span>
                                                </div>
                                             </div>
                                             <div className="d-flex justify-content-center align-items-center" style={{ width: "15%" }}>
                                                <span className="text-center">{element?.run}/{element?.size}</span>
                                             </div>
                                             <div className="d-flex justify-content-center align-items-center" style={{ width: "15%" }}>
                                                <span className="text-center">{element?.stack}</span>
                                             </div>
                                             <div className="d-flex justify-content-center align-items-center" style={{ width: "15%" }}>
                                                <span className="text-center">{element?.profit}</span>
                                             </div>
                                          </div>
                                       </>
                                    )) : null}
                                 </div>
                              </div> : null
                           }
                           {matchBetList && matchBetList.length > 0 ?
                              <div>
                                 <div className="fancy-oddgreen" style={{ backgroundColor: '#e0e6e6' }}><h4>Odds</h4></div>
                                 <div className="d-flex justify-content-between mt-2">
                                    <div className="text-start ms-1" style={{ width: '55%' }}>Back (Bet For)</div>
                                    <div className="text-center" style={{ width: '15%' }}>Odds</div>
                                    <div className="text-center" style={{ width: '15%' }}>Stake</div>
                                    <div className="text-center" style={{ width: '15%' }}>Profit</div>
                                 </div>
                                 <div>
                                    {matchBetList && matchBetList.length > 0 ? matchBetList.map((element, index) => (
                                       <>
                                          <div
                                             className="d-flex ps-1 justify-content-start align-items-center"
                                             style={{
                                                backgroundColor: element.is_back === "1" ? "#beddf4" : "#f3dce2",
                                                color: "#3b5160",
                                                fontSize: "10px",
                                             }}
                                          >
                                             Ref: {moment.unix(element.created_at).format("DD-MM-YYYY HH:MM:SS")}
                                          </div>
                                          <div
                                             className="d-flex justify-content-between teamname-div teamNameFancy"
                                             style={{
                                                backgroundColor: element.is_back === "1" ? "#beddf4" : "#f3dce2",
                                                border: element.is_back === "1" ? "1px solid #7dbbe9" : "1px solid #dfa3b3",
                                             }}
                                          >
                                             <div className={`d-flex gap-2 ${element.is_back === "1" ? "teamname-bet" : "teamname-bet2"
                                                }`}
                                                style={{ width: "55%" }}
                                             >
                                                <div className="d-flex gap-2">
                                                   <span className="d-flex align-items-center justify-content-center"
                                                      style={{
                                                         padding: "2px",
                                                         borderRadius: "5px",
                                                         color: "black",
                                                         fontWeight: "bold",
                                                         backgroundColor: element.is_back === "1" ? "#7dbbe9" : "#dfa3b3",
                                                         border: `1px solid ${element.is_back === "1" ? "#7dbbe9" : "#dfa3b3"}`,
                                                      }}
                                                   >
                                                      {element.is_back === "1" ? "Back" : "Lay"}
                                                   </span>
                                                   <span>
                                                      <div><strong>{element?.selectionName}</strong></div>
                                                      <div>({element?.marketName})</div>
                                                   </span>
                                                </div>
                                             </div>
                                             <div className="d-flex justify-content-center align-items-center" style={{ width: "15%" }}>
                                                <span className="text-center">{element?.odds}</span>
                                             </div>
                                             <div className="d-flex justify-content-center align-items-center" style={{ width: "15%" }}>
                                                <span className="text-center">{element?.stack}</span>
                                             </div>
                                             <div className="d-flex justify-content-center align-items-center" style={{ width: "15%" }}>
                                                <span className="text-center">{element?.p_l}</span>
                                             </div>
                                          </div>
                                       </>
                                    )) : null}
                                 </div>
                              </div> : null
                           }
                        </div> */}
                     </div>
                     {/* <div className="col-lg-3 score-tabbets ">
                     <RightSidebarDetailsPage MainTV={tvData?.value ? `${tvData?.value}${matchId}` : matchDetails?.MainTV} showIframe={showIframe} handleIconClick={handleIconClick} sportGraphic={matchDetails?.sportGraphic} sessionBetList={sessionBetList} matchBetList={matchBetList} />
                  </div> */}
                  </div>
               </div>
            </div>
            <div className="col-lg-3 col-12 px-0 pb-5">
               <RightSidebarDetailsPage MainTV={tvData?.value ? `${tvData?.value}${matchId}` : matchDetails?.MainTV} showIframe={showIframe} handleIconClick={handleIconClick} sportGraphic={matchDetails?.sportGraphic} sessionBetList={sessionBetList} matchBetList={matchBetList} />
            </div>
         </div>
      </>
   )
}

export default ViewDetailsPage
