import { useState } from "react";
import { useDispatch } from "react-redux";
import { GameConstants } from "../../middelware/GameConstants";
import { useNavigate } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";
import { eventGameList, gameEventList } from "../../redux/_reducers/_reducers";

function Sidebar() {
   const [activeTab, setActiveTab] = useState(1);
   const [sidebarlistData, setSidbarListData] = useState([]);
   const [eventListData, setEventListData] = useState([]);
   const [expandedSportId, setExpandedSportId] = useState(null);
   const [expandedItemId, setExpandedItemId] = useState(null);

   const userLoginData = localStorage.getItem("user")

   const navigate = useNavigate();
   const dispatch = useDispatch();

   const handleTab1Click = async (id) => {
      setActiveTab(3);
      setExpandedSportId(id);
      navigate(`/allsportshighlight/${id}`);

      const payloadd = {
         limit: 20,
         sport_id: id,
         pageno: 1,
      };

      try {
         const result = await dispatch(eventGameList(payloadd));
         if (result?.payload) {
            setEventListData(result?.payload);
         }
      } catch (error) {
         console.error('Error fetching second API data:', error);
      }
   };

   const handleBackClick = () => {
      setActiveTab(1);
      setExpandedSportId(null);
      setSidbarListData([])
      setExpandedItemId()
   };

   const handleSidebarClick = (sportId, matchId) => {
      navigate(`/app/view-details/${sportId}/${matchId}`);
   };


   const handleGameList = async (item) => {
      setSidbarListData([])
      setExpandedItemId()
      const payloaddd = {
         limit: 20,
         sport_id: item?.sport_id,
         pageno: 1,
         series_id: item?.series_id,
      };

      try {
         const result = await dispatch(gameEventList(payloaddd));
         if (result?.payload) {
            setSidbarListData(result?.payload);
            setExpandedItemId(item.id);
         }
      } catch (error) {
         console.error('Error fetching second API data:', error);
      }
   }

   return (
      <>
         <div className="score-tab-work d-none d-lg-block">
            <div className="sports-black-ul bg-dark text-light">
               <a onClick={() => handleBackClick()} className="d-flex justify-content-between">
                  <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                  <p className="m-0">Sports</p>
               </a>
            </div>
            {activeTab === 1 && expandedSportId === null && (
               <ul className="list-unstyled">
                  {GameConstants.map((game) => (
                     <li className=" cursor-pointer" key={game.sportId}>
                        {userLoginData ?
                           <a className="cursor-pointer" onClick={() => handleTab1Click(game?.sportId)}>
                              {game.sportName.charAt(0).toUpperCase() + game.sportName.slice(1)}
                              <div className="nav-position">
                                 <i className="fa fa-angle-right" aria-hidden="true"></i>
                              </div>
                           </a> :
                           <a>
                              {game.sportName.charAt(0).toUpperCase() + game.sportName.slice(1)}
                              <div className="nav-position">
                                 <i className="fa fa-angle-right" aria-hidden="true"></i>
                              </div>
                           </a>
                        }
                     </li>
                  ))}
               </ul>
            )}

            {activeTab === 3 && (
               <div className="expanded-event-list">
                  {eventListData.length > 0 ? (
                     eventListData.map((item) => (
                        <ul key={item.id}>
                           <li onClick={() => handleGameList(item)}>{item?.name}</li>
                           {expandedItemId === item.id && (
                              <div className="sidebar-list-data">
                                 {sidebarlistData && sidebarlistData.length > 0 ? (
                                    sidebarlistData.map((match, index) => (
                                       <li
                                          key={match.matchId || index}
                                          className="d-flex gap-2 justify-content-start align-items-center"
                                          onClick={() => handleSidebarClick(match.sport_id, match.match_id)}
                                       >
                                          <span>
                                             <FaArrowRight />
                                          </span>
                                          <span className="cursor-pointer">{match.name}</span>
                                       </li>
                                    ))
                                 ) : (
                                    <div>No Data Available</div>
                                 )}
                              </div>
                           )}
                        </ul>
                     ))
                  ) : <div>No Data Available</div>}
               </div>
            )}

         </div>
      </>
   );
}

export default Sidebar;

