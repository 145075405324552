import { useEffect, useState } from "react";
import Privacy from "../Privacy-policy/privacy";
import { Link } from "react-router-dom";
import { defaultSetting, ourPages } from "../../redux/_reducers/_reducers";
import { useDispatch, useSelector } from "react-redux";
import { LuInstagram } from "react-icons/lu";
import wp from '../../assets/image/whatsapplogo.png'
import telegram from '../../assets/image/telegram.png'

function Footer() {
  const [isPrivacy, setIsPrivacy] = useState(false); // State for controlling visibility of Privacy modal
  const [activeSection, setActiveSection] = useState(1); // Track which section to show in the Privacy modal
  const [ourPageData, setOurPageData] = useState(1);
  const dispatch = useDispatch()
  const { defaultSettingData, ourPagesData } = useSelector(
    (state) => state.authentication
  );

  const whatsappData = defaultSettingData?.find((item) => item.key === "site.whatsapp");
  const telegramData = defaultSettingData?.find((item) => item.key === "site.telegram");
  const privacyClick = (sectionId) => {
    setActiveSection(sectionId); // Set the active section when a footer item is clicked
    setIsPrivacy(true); // Show the Privacy modal
  };

  useEffect(() => {
    dispatch(ourPages())
  }, [])

  useEffect(() => {
    if (ourPagesData) {
      setOurPageData(ourPagesData);
    }
  }, [ourPagesData]);

  const aboutUs = ourPagesData?.find((item) => item.slug === "about-us");
  const privacyPolicy = ourPagesData?.find((item) => item.slug === "privacy-policy");
  const termsCondition = ourPagesData?.find((item) => item.slug == "general-terms-and-conditions");
  const refundPolicy = ourPagesData?.find((item) => item.slug == "refund-policy");
  const kyc = ourPagesData?.find((item) => item.slug == "kyc-and-aml");



  // const privacyPolicy = ourPagesData?.find((item) => item.id == "12");

  return (
    <div>
      <footer className="text-center pb-5">
        <div className="">
          <div className="d-flex flex-wrap justify-content-center align-items-center top-footer gap-2">
            <h4>Support</h4>
            <Link className="d-flex align-items-center gap-1 text-black" to={telegramData?.value}>
              <img src={telegram} className="instaIcon" />
              <span>Telegram </span>
            </Link>
            <Link className="d-flex align-items-center gap-1 text-black" to={whatsappData?.value}>
              <i className="fa fa-whatsapp" aria-hidden="true"></i>
              <span>WhatsApp</span>
            </Link>
          </div>
          <div className="bottom-footer mb-lg-0 mb-4">
            <row className="d-flex flex-wrap justify-content-center mx-0">
              {/* {ourPagesData?.map((item) => (
                <div
                  key={item.id}
                  className="col-sm-2 col-12 borderEnd"
                  onClick={() => privacyClick(item.id)}
                >
                  {item.title}
                </div>
              ))} */}
              <div className="col-sm-2 col-12 borderEnd" onClick={() => privacyClick(privacyPolicy?.slug)}> {privacyPolicy?.slug}</div>
              <div className="col-sm-2 col-12 borderEnd" onClick={() => privacyClick(aboutUs?.slug)}>
                {aboutUs?.slug}
              </div>
              <div className="col-sm-3 col-12 borderEnd" onClick={() => privacyClick(termsCondition?.slug)}>
                {termsCondition?.slug}
              </div>
              <div className="col-sm-3 col-12 borderEnd" onClick={() => privacyClick(refundPolicy?.slug)}>
                {refundPolicy?.slug}
              </div>
              <div className="col-sm-2 col-12" onClick={() => privacyClick(kyc?.slug)}>
                {kyc?.slug}
              </div>
            </row>
          </div>
        </div>
      </footer>

      {/* Conditionally render the Privacy component based on isPrivacy */}
      {isPrivacy && (
        <Privacy
          activeSection={activeSection} // Pass the activeSection to Privacy
          privacyClick={() => setIsPrivacy(false)} // Close the Privacy modal when clicking OK
        />
      )}
    </div>
  );
}

export default Footer;
