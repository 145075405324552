import React from "react";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import NoDataAvailable from "../../components/NodataAvailable/NodataAvailable";
import Slider from "react-slick";
import CasinoListNav from "../../components/CasinoListNav/CasinoListNav";
import Footer from "../../components/Footer/Footer";
import MobileMenu from "../../components/Mobile-Menu/Mobilemenu";
import CasinoImages from "../../components/CasinoImages/CasinoImages";
import HomeBets from "../../components/HomeBets/home-bets";
import SecondBets from "../../components/SecondBets/SecondBets";


function Soccer() {

    const [matchlistData, setMatchlistData] = useState("");
    const [activeNav, setActiveNav] = useState(4);
    const [activeCasinoTab, setActiveCasinoTab] = useState(5000);
    const navigate = useNavigate();
    let user = JSON.parse(localStorage.getItem('clientUser'));
    const { sportmatchlist } = useSelector(
        (state) => state.authentication
    );
    useEffect(() => {
        if (sportmatchlist !== undefined) {
            setMatchlistData(sportmatchlist?.sportlist?.data?.filter((item) => item.sportId === activeNav));
        }
    }, [sportmatchlist, activeNav]);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    const handleClick = (eventId, marketId) => {
        if (!user) {
            return navigate('/')
        }
        navigate(`/match-view-details/${marketId}/${eventId}`);
    };
    const loggedIn = localStorage.getItem("user");


    return (
        <div className="score-tabcontent-work">
            <div className="tab-content tab-main-work" id="v-pills-tabContent">
                {loggedIn && (
                    <div className="col-md-12 marquee-text">
                        <span className="text-light">
                            {" "}
                            <i className="fa fa-microphone"></i> News{" "}
                        </span>
                    </div>
                )}
                <Slider {...settings} className="tab-content-slider">
                    <div className="item">
                        <img src="images/slide-1.jpg" alt="" />
                    </div>
                    <div className="item">
                        <img src="images/slide-2.jpg" alt="" />
                    </div>
                    <div className="item">
                        <img src="images/slide-3.jpg" alt="" />
                    </div>
                    <div className="item">
                        <img src="images/slide-4.jpg" alt="" />
                    </div>
                    <div className="item">
                        <img src="images/slide-5.jpg" alt="" />
                    </div>
                    <div className="item">
                        <img src="images/slide-6.jpg" alt="" />
                    </div>
                </Slider>
                <div
                    className="tab-pane fade show active "
                    id="v-pills-home"
                    role="tabpanel"
                    aria-labelledby="v-pills-home-tab"
                >
                    <div className="">
                        <div className="highlights ">
                            <div>Highlights</div>
                        </div>

                        <div className="table-container">

                          <SecondBets/>
                            {(!matchlistData || matchlistData.length === 0) && (
                                <NoDataAvailable />
                            )}

                        </div>

                    </div>

                    <Footer />
                </div>
                <MobileMenu />
            </div>
        </div>
    )
}

export default Soccer;