
import React, { createContext, useState, useEffect, useContext } from 'react';
import { getSocket, initSocket } from './SocketConnection';


const BalanceContext = createContext();

export const BalanceProvider = ({ children }) => {
  const [balance, setBalance] = useState({
    coins: JSON.parse(localStorage.getItem("userBalance")) || "",
    exposure: JSON.parse(localStorage.getItem("userExposure")) || ""
  });

  useEffect(() => {
    function updateSocket() {
      let user = JSON.parse(localStorage.getItem("user"));
      let token_Id = user?.token;
      let socket = getSocket();

      if (!socket || socket == null) {
        socket = initSocket(token_Id);
        const loginData = {
          user: user?.data?.userId,
          token: token_Id,
        };
        socket.emit(`login`, loginData);
      }
      socket.on("coinUpdate", (data) => {
        localStorage.setItem("userBalance", JSON.stringify(data.coins));
        localStorage.setItem("userExposure", JSON.stringify(data.exposure));
        setBalance({
          coins: data.coins,
          exposure: data.exposure,
        });
      });
    }
    updateSocket();
  }, []);

  return (
    <BalanceContext.Provider value={{ balance, setBalance }}>
      {children}
    </BalanceContext.Provider>
  );
};

export const useBalance = () => {
  return useContext(BalanceContext);
};
