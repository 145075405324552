export const userConstent = {
    USER_LOGIN_REQUEST : 'USER_LOGIN_REQUEST',
    USER_LOGIN_SUCCESS : 'USER_LOGIN_SUCCESS',
    USER_LOGIN_FAILEAR : 'USER_LOGIN_FAILEAR',

    SPORT_MATCHLIST_REQUEST : 'SPORT_MATCHLIST_REQUEST',
    SPORT_MATCHLIST_SUCCESS : 'SPORT_MATCHLIST_SUCCESS',
    SPORT_MATCHLIST_FAILEAR : 'SPORT_MATCHLIST_FAILEAR',

    LOGOUT: 'USERS_LOGOUT',
}