import React, { useEffect, useState } from 'react';
import ChangePasswordModal from '../../../components/ChangePasswordModal/ChangePasswordModal';
import RollingCommision from '../../../components/RollingCommision/RollingCommision';
import { useDispatch, useSelector } from 'react-redux';

const MyProfile = () => {
const [passwordModal , setPasswordModal] = useState(false)
const [rollingModal , setRollingModal] = useState(false)
const [password, setPassword] = useState(""); 
const userData = JSON.parse(localStorage.getItem("user")).data
const dispatch = useDispatch();

const {updateInfoData } = useSelector(
    (state) => state.authentication
);

// Handle password input change
const handlePasswordChange = (e) => {
    setPassword(e.target.value);  // Update the password state when user types
};

// useEffect(()=>{
//     dispatch(updateInfo())
// },[])


    const handlepasswordModal = ()=>{
        setPasswordModal(modal => !modal)
    }
    const handleRollingModal = ()=>{
        setRollingModal(modal => !modal)
    }
    return (
        <>
        {/* {passwordModal && <ChangePasswordModal handlepasswordModal={handlepasswordModal} setPasswordModal={setPasswordModal} password={password}  />} */}
        {rollingModal && <RollingCommision handleRollingModal={handleRollingModal} />}
            <div className="profile-account-details">
                <div className="profile-heading">
                    <h4>Account Details</h4>
                </div>
                <div className="prfile-account-fiels">
                    <ul>
                        <li className="d-flex flex-wrap align-items-center">
                            <div className="Details-op"><h5>Name</h5></div>
                            <div className="Details-op2"><span>{userData?.user_name ? userData.user_name : "-" }</span></div>
                        </li>
                        <li className="d-flex flex-wrap align-items-center">
                            <div className="Details-op"><h5>Commission</h5></div>
                            <div className="Details-op2"><span>0</span></div>
                        </li>
                        <li className="d-flex flex-wrap align-items-center">
                            <div className="Details-op"><h5>Rolling Commission</h5></div>
                            <div className="Details-op2">
                                <span className="eye-click" onClick={handleRollingModal}>
                                    <i className="fa fa-eye" aria-hidden="true"></i>
                                </span>
                            </div>
                        </li>
                        <li className="d-flex flex-wrap align-items-center">
                            <div className="Details-op"><h5>Exposure Limit</h5></div>
                            <div className="Details-op2"><span>100000</span> </div>
                        </li>
                        <li className="d-flex flex-wrap align-items-center">
                            <div className="Details-op"><h5>Password</h5></div>
                            <div className="Details-op2 d-flex flex-wrap align-items-center">
                                <input 
                                    type="password" 
                                    value={password} 
                                    onChange={handlePasswordChange}
                                />
                                <p className="mb-0">Edit <span><i className="fa fa-pencil-square-o" aria-hidden="true" onClick={handlepasswordModal}></i>
                                </span></p> </div>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default MyProfile
