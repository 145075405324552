import RightSidebar from "../../../components/RightSidebar/RightSidebar"

function TipsAndPreview() {
  return (
    <div className="row mx-0">
      <div className="score-tabcontent-work">
        <div className="container-fluid mt-2">
          <div className="row">
            <div className="col-12 newsContainer">
              <div className="card">
                <div className="card-header newsHeader">NEWS</div>
                <div className="card-body p-0">
                  {/* Main News 1 */}
                  <div className="p-2 mainNews">
                    <a >
                      <div className="d-flex">
                        <div className="width-img">
                          <img className="NewsImg" src="http://market.mgmopr.com/api/trader/tips/images/9c9570f4-0534-4522-9bde-601432365a7e.jpg" alt="News Image" />
                        </div>
                        <div className="width-content">
                          <div>
                            <h2 className="news-heading">Wimbledon 2024: Krejcikova wins second Grand Slam title after beating Paolini in ladies’ singles final</h2>
                            <p className="news-description mt-1">Heading into the Wimbledon final, Barbora Krejcikova was a seven-time Major doubles champion, the 2021 French Open singles winner and an Olympic gold medallist. She had even completed the career Slam in doubles, winning each of the four big titles at least once.
                              {/* Add remaining text here */}
                            </p>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>

                  {/* Main News 2 */}
                  <div className="p-2 mainNews">
                    <a >
                      <div className="d-flex">
                        <div className="width-img">
                          <img className="NewsImg" src="http://market.mgmopr.com/api/trader/tips/images/f90ac0a5-5d03-4e33-8c48-ad7ce9e5d757.jpg" alt="News Image" />
                        </div>
                        <div className="width-content">
                          <div>
                            <h2 className="news-heading">TNPL 2024: Tiruppur Tamizhans posts first win of season; Lyca Kovai Kings registers third straight victory</h2>
                            <p className="news-description mt-1">B. Anirudh Sitaram (52, 28b, 5x4, 3x6) stroked a fine half-century to help IDream Tiruppur Tamizhans beat Siechem Madurai Panthers by four wickets and post its first win of TNPL-8 at the Sri Ramakrishna College ground here on Saturday.
                              {/* Add remaining text here */}
                            </p>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>

                  {/* Main News 3 */}
                  <div className="p-2 mainNews">
                    <a >
                      <div className="d-flex">
                        <div className="width-img">
                          <img className="NewsImg" src="http://market.mgmopr.com/api/trader/tips/images/1c42215d-71d7-479e-9af8-32bac7bf6852.jpg" alt="News Image" />
                        </div>
                        <div className="width-content">
                          <div>
                            <h2 className="news-heading">Samson, Mukesh fire India to 42-run win over Zimbabwe in 5th T20I to seal 4-1 series win</h2>
                            <p className="news-description mt-1">Sanju Samson’s well-crafted fifty and Mukesh Kumar’s disciplined spell helped India wind up its Zimbabwe tour with a comprehensive 42-run victory in the fifth and final T20I here on Sunday.
                              {/* Add remaining text here */}
                            </p>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>

                  {/* Main News 4 */}
                  <div className="p-2 mainNews">
                    <a >
                      <div className="d-flex">
                        <div className="width-img">
                          <img className="NewsImg" src="http://market.mgmopr.com/api/trader/tips/images/39b3ecbf-2fc2-4561-b9a2-67c16eb73eac.jpg" alt="News Image" />
                        </div>
                        <div className="width-content">
                          <div>
                            <h2 className="news-heading">Spain vs England, Euro 2024 final: Lamine Yamal breaks record in European Championship</h2>
                            <p className="news-description mt-1">Spain forward Lamine Yamal broke another record when he was named in the starting line-up of the Euro 2024 final, against England at the Olympiastadion in Berlin, Germany, on Sunday.
                              {/* Add remaining text here */}
                            </p>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>

                  {/* Main News 5 */}
                  <div className="p-2 mainNews">
                    <a >
                      <div className="d-flex">
                        <div className="width-img">
                          <img className="NewsImg" src="http://market.mgmopr.com/api/trader/tips/images/469414a3-271e-4c0b-bf77-22292ab591a9.jpg" alt="News Image" />
                        </div>
                        <div className="width-content">
                          <div>
                            <h2 className="news-heading">Copa America 2024: Argentina beats Colombia to lift record 16th title</h2>
                            <p className="news-description mt-1">Defending champion Argentina won a record 16th Copa America title by beating Colombia 1-0 thanks to an extra-time goal from substitute Lautaro Martinez at the Hard Rock Stadium in Florida on Sunday.
                              {/* Add remaining text here */}
                            </p>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>

                  {/* Main News 6 */}
                  <div className="p-2 mainNews">
                    <a >
                      <div className="d-flex">
                        <div className="width-img">
                          <img className="NewsImg" src="http://market.mgmopr.com/api/trader/tips/images/b4f3213d-01cf-422a-81fc-1e4792297f94.jpg" alt="News Image" />
                        </div>
                        <div className="width-content">
                          <div>
                            <h2 className="news-heading">Wimbledon 2024: Alcaraz beating Djokovic in final proves winds of change beyond the Big Three in men’s tennis</h2>
                            <p className="news-description mt-1">Transitions between sporting eras are generally messy affairs. There is first a vacuum, and then multiple contenders jostle to fill this space before an otherworldly talent descends and wins everything in sight.
                              {/* Add remaining text here */}
                            </p>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="col-lg-3 score-tabbets ">
        <RightSidebar />
      </div> */}
    </div>
  )
}

export default TipsAndPreview
