function VirtualSports() {
    return (
        <div>
<div className="col-md-6 col-12 p-2 ng-star-inserted">
  <div className="casino-icon">
    <div className="d-flex flex-column">
      <img className="img-fluid  w-100 mb-2" style={{height:"230px"}} src="https://t10exchange.com/v1/virtual/virtualimage/virtual-market-image-1.jpeg" />
      <div className="casino-name" style={{
        background: '#f6a21e',
        color: 'black',
        padding: '5px',
        position: 'absolute',
        width: '400px',
        bottom: '0',
        textAlign: 'start',
        textTransform: 'uppercase',
        fontWeight: '700',
        fontSize: '14px',
        borderRadius: '4px'
      }}>Universe T1 League 16/07/2024</div>
    </div>
  </div>
</div>

        </div>
    )
}

export default VirtualSports
