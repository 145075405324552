import CALC from "../../components/CALC/calc";

function NextLottery() {
    const bets = [
      { id: 1, name: 'Bet 1', blueNumber: 1, pinkNumber: 2 },
      { id: 2, name: 'Bet 2', blueNumber: 1, pinkNumber: 2 },
      { id: 3, name: 'Bet 3', blueNumber: 1, pinkNumber: 2 },
      { id: 4, name: 'Bet 4', blueNumber: 1, pinkNumber: 2 },
      { id: 5, name: 'Bet 5', blueNumber: 1, pinkNumber: 2 },
    ];
  
    const mainData = [
      { id: 1, name: 'nextlottery 1', bets: bets },
      { id: 2, name: 'nextlottery 2', bets: bets },
      { id: 3, name: 'nextlottery 2', bets: bets }
    ];

  
    return (
      <div>
        {mainData.map((data) => (
          <div key={data.id}>
            <div className="nextlottery-heading">
              <h4>SINGLE FIGURE [ ANK ] OPEN (ANDAR)</h4>
            </div>
            <div className="lottery-betsdata d-flex flex-wrap">
              {data.bets.map((bet) => (
                <div key={bet.id} className="lottery-bets d-flex flex-wrap">
                  <div className="lottery-blue text-center">{bet.blueNumber}</div>
                  <div className="lottery-pink text-center">{bet.pinkNumber}</div>
                  
                </div>
                
              ))}
            </div>
          </div>
        ))}
      </div>
    );
  }
  
  export default NextLottery;
  