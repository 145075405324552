
const NoDataAvailable = () => {
  return (
    <div className="d-flex justify-content-center align-items-center " style={{ height: '100%', fontSize:"15px" ,  background: "#dddcd6"}}>
      <p className="text-dark">No data available</p>
    </div>
  );
};

export default NoDataAvailable;
