import { useState } from "react";
import { updateInfo } from "../../redux/_reducers/_reducers";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import RightSidebar from "../RightSidebar/RightSidebar";

function ChangePasswordModal({password, setPasswordModal}) {
    const dispatch = useDispatch();
    const [oldPwd, setOldPwd] = useState();
    const [newPwd, setNewPwd] = useState('');
    const [confirmPwd, setConfirmPwd] = useState('');
    const [showOldPwd, setShowOldPwd] = useState(false);
    const [showNewPwd, setShowNewPwd] = useState(false);
    const [showConfirmPwd, setShowConfirmPwd] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [message , setMessage] = useState('')
    const navigate = useNavigate();

    // const togglePasswordVisibility = (type) => {
    //     switch (type) {
    //         case 'old':
    //             setShowOldPwd(!showOldPwd);
    //             break;
    //         case 'new':
    //             setShowNewPwd(!showNewPwd);
    //             break;
    //         case 'confirm':
    //             setShowConfirmPwd(!showConfirmPwd);
    //             break;
    //         default:
    //             break;
    //     }
    // };

    // Function to handle form submission
    const handleFormSubmit = (e) => {
        e.preventDefault();
        if (!oldPwd || !newPwd || !confirmPwd) {
            toast.error('All fields are required.');
            return;
        }
        // Validate if passwords match
        if (newPwd !== confirmPwd) {
            setMessage("New Password and Confirm Password do not match.");
            return;
        }
        const data = {
            "oldPassword": oldPwd,
            "newPassword": newPwd,
            "confirmNewPassword": confirmPwd
            // transactionPassword: fieldslogin.oldPassword,
        };

        try {
            setIsLoading(true);
            const response =  dispatch(updateInfo(data));
            if (response?.error === true) {
                navigate('/');
                toast.success('Password updated successfully.');
                localStorage.clear()
            } else {
                toast.error(`Update failed: ${response?.message || 'Unknown error'}`);
            }
        } catch (error) {
            toast.error('Error updating password: ' + (error.message || 'An unexpected error occurred.'));
        } finally {
            setIsLoading(false);
        }

            // dispatch(updateInfo(data));
        

        // Reset form fields (optional)
        setOldPwd('');
        setNewPwd('');
        setConfirmPwd('');
        setMessage('')
        // handlepasswordModal()
    };


    const handlechangePasswordclose = ()=>{
        setPasswordModal(false)
    }


    
    return (
        <>
            <div className="rolling-commission-form px-2" id="rolling-commission-form">
                <div className="rolling-commission-formsec">
                    <div className="rolling-commission-formh4">
                        <h4 className="d-flex flex-wrap justify-content-between">Change Password 
                            {/* <i className="fa fa-times" aria-hidden="true" onClick={()=>handlechangePasswordclose()}></i> */}

                        </h4>
                    </div>
                    <div className="rolling-commission-form2">
                        <form action="">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 rolling-commission-formfield">
                                    <label for="email">Old Password <span>*</span></label>
                                    <input 
                                    value={oldPwd} 
                                    type={showOldPwd ? "text" : "password"} 
                                    id="oldPwd" 
                                    onChange={(e) => setOldPwd(e.target.value)}  />
                                    <div className="eye-on-off">
                                        <i 
                                            onClick={() => setShowOldPwd(!showOldPwd)} 
                                            className={`fa ${showOldPwd ? "fa-eye" : "fa-eye-slash"}`} 
                                            aria-hidden="true"
                                        ></i>
                                    </div>
                                    {/* <div className="eye-on-off">
                                        {showOldPwd && <i onClick={()=>setShowOldPwd(false)} className="fa fa-eye on" aria-hidden="true"></i>}
                                        {!showOldPwd && <i  onClick={()=>setShowOldPwd(true)} className="fa fa-eye-slash off" aria-hidden="true"></i>}
                                    </div> */}
                                </div>
                                <div className="col-lg-6 col-md-6 rolling-commission-formfield">
                                    <label for="email">New Password <span>*</span></label>
                                    <input 
                                    value={newPwd} 
                                    onChange={(e)=>setNewPwd(e.target.value)} 
                                    type={showNewPwd ? "text" : "password"} 
                                    id="pwd" />
                                    <div className="eye-on-off">
                                        <i 
                                            onClick={() => setShowNewPwd(!showNewPwd)} 
                                            className={`fa ${showNewPwd ? "fa-eye" : "fa-eye-slash"}`} 
                                            aria-hidden="true"
                                        ></i>
                                    </div>
                                    {/* <div className="eye-on-off">
                                    {showNewPwd && <i onClick={()=>setShowNewPwd(false)} className="fa fa-eye on" aria-hidden="true"></i>}
                                    {!showNewPwd && <i  onClick={()=>setShowNewPwd(true)} className="fa fa-eye-slash off" aria-hidden="true"></i>}
                                    </div> */}
                                </div>
                                <div className="col-lg-6 col-md-6 rolling-commission-formfield">
                                    <label for="email">Confirm Password <span>*</span></label>
                                    <input 
                                    value={confirmPwd} 
                                    onChange={(e)=>setConfirmPwd(e.target.value)} 
                                    type={showConfirmPwd ? "text" : "password"}  
                                    id="pwd" />
                                    <div className="eye-on-off">
                                        <i 
                                            onClick={() => setShowConfirmPwd(!showConfirmPwd)} 
                                            className={`fa ${showConfirmPwd ? "fa-eye" : "fa-eye-slash"}`} 
                                            aria-hidden="true"
                                        ></i>
                                    </div>
                                    {/* <div className="eye-on-off">
                                    {showConfirmPwd && <i onClick={()=>setShowConfirmPwd(false)} className="fa fa-eye on" aria-hidden="true"></i>}
                                    {!showConfirmPwd && <i  onClick={()=>setShowConfirmPwd(true)} className="fa fa-eye-slash off" aria-hidden="true"></i>}
                                    </div> */}
                                    {message && <p className="text-danger"> {message}</p>}
                                </div>
                            </div>
                            <div className="commission-btn-submit text-end">
                                <button className="mx-2" onClick={handleFormSubmit} >Submit</button>
                                {/* <button >No</button> */}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {/* <div className="col-lg-3 score-tabbets " style={{top:'100px'}}>
                <RightSidebar/>
            </div> */}
        </>
    )
}

export default ChangePasswordModal
