import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { logout } from "../../redux/_reducers/_reducers";
import { FaCircleUser } from "react-icons/fa6";
import { IoIosArrowForward } from "react-icons/io";

const AccountPage = () => {
    const [accountDropdownOpen, setAccountDropdownOpen] = useState(false);
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const logoutUser = () => {
        dispatch(logout());
        localStorage.removeItem("user");
        setAccountDropdownOpen(false);
        navigate("/")
    };
    return (
        <>
            <div className='drop-my-account position-static accountPageWRapper'>
                <ul className='h-100 overflow-y-auto'>
                    <li className='accountHead'>
                        <div className='d-flex align-items-center gap-2'><FaCircleUser />Exchange</div>
                    </li>
                    <li className='p-2 d-flex align-items-center justify-content-between'>
                        <Link className='accountLink d-flex justify-content-between w-100 align-items-center' to='/app/changePassword' onClick={() =>
                            setAccountDropdownOpen(!accountDropdownOpen)
                        }>Change Password

                            <div className='arrowBtn text-black'>
                                <IoIosArrowForward />
                            </div>
                        </Link>
                    </li>
                    <li className='p-2 d-flex align-items-center justify-content-between'>
                        <Link className='accountLink d-flex justify-content-between w-100 align-items-center' to='/app/account-statement' onClick={() =>
                            setAccountDropdownOpen(!accountDropdownOpen)
                        }>Account Statement
                            <div className='arrowBtn text-black'>
                                <IoIosArrowForward />
                            </div>
                        </Link>
                    </li>
                    <li className='p-2 d-flex align-items-center justify-content-between'>
                        <Link className='accountLink d-flex justify-content-between w-100 align-items-center' to='/app/bet-history/others' onClick={() =>
                            setAccountDropdownOpen(!accountDropdownOpen)
                        }>Bets History

                            <div className='arrowBtn text-black'>
                                <IoIosArrowForward />
                            </div>
                        </Link>
                    </li>
                    <li className='p-2 d-flex align-items-center justify-content-between'>
                        <Link className='accountLink d-flex justify-content-between w-100 align-items-center' to='/app/Profit-loss' onClick={() =>
                            setAccountDropdownOpen(!accountDropdownOpen)
                        }>Profit & Loss

                            <div className='arrowBtn text-black'>
                                <IoIosArrowForward />
                            </div>
                        </Link>
                    </li>
                    <li className='p-2 d-flex align-items-center justify-content-between'>
                        <Link className='accountLink d-flex justify-content-between w-100 align-items-center' to='/app/depositWithdraw' onClick={() =>
                            setAccountDropdownOpen(!accountDropdownOpen)
                        }>D/W History

                            <div className='arrowBtn text-black'>
                                <IoIosArrowForward />
                            </div>
                        </Link>
                    </li>
                    <li className='p-2 d-flex align-items-center justify-content-between'>
                        <Link className='accountLink d-flex justify-content-between w-100 align-items-center' to='/app/settings' onClick={() =>
                            setAccountDropdownOpen(!accountDropdownOpen)
                        }>Setting

                            <div className='arrowBtn text-black'>
                                <IoIosArrowForward />
                            </div>
                        </Link>
                    </li>
                    {/* <li className='p-2 mb5 pb-5'>
                    <button onClick={() => logoutUser()} className="d-flex logAccountBtn">
                    LOGOUT{" "}
                        <i
                            className="fa fa-sign-out text-light"
                            aria-hidden="true"
                        ></i>
                    </button>
                </li> */}
                </ul>
            </div>

            <button onClick={() => logoutUser()} className="d-flex logAccountBtn mb-5">
                LOGOUT{" "}
                <i
                    className="fa fa-sign-out text-light"
                    aria-hidden="true"
                ></i>
            </button>
        </>
    )
}

export default AccountPage