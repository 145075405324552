import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MobileSlideNav from "../../../components/MobileSlideNav/MobileSlideNav";
import CasinoListNav from "../../../components/CasinoListNav/CasinoListNav";
import Footer from "../../../components/Footer/Footer";
import { Link, useLocation, useParams } from "react-router-dom";
import MobileMenu from "../../../components/Mobile-Menu/Mobilemenu";
import LotteryImages from "../../../components/LotteryImages/LotteryImages";
import SecondBets from "../../../components/SecondBets/SecondBets";
import { ourSlides, SportMatchOuterList, matchSeriesList } from "../../../redux/_reducers/_reducers";
import Loader from "../../../components/Loader/Loader";
import Blink from "../../../components/BlinkComponent/Blink";
import RightSidebar from "../../../components/RightSidebar/RightSidebar"


function Dashboard() {
  const [matchlistData, setMatchlistData] = useState("");
  const [matchSeriesData, setMatchSeriesData] = useState(null);
  const [slideData, setslideData] = useState("");
  const [getSportOuterList, setGetSportOuterList] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [filteredMatchData, setFilteredMatchData] = useState([]);
  const [filteredInplayMatchData, setFilteredInplayMatchData] = useState([]);
  const [filteredUpcomingMatchData, setFilteredUpcomingMatchData] = useState([]);
  const [activeNav, setActiveNav] = useState(4);
  const [activeCasinoTab, setActiveCasinoTab] = useState(5000);
  const [loading, setLoading] = useState('');
  const { ourSlidesData, SportMatchOuterListData, matchSeriesListData, defaultSettingData } = useSelector(
    (state) => state.authentication
  );
  const marequeeText = defaultSettingData?.find((item) => item.key === "site.MESSAGE");

  const dispatch = useDispatch();
  const location = useLocation();
  // Check if the URL contains a numeric segment
  const isUrlDynamic = /\d+/.test(location.pathname);
  const { sportsId } = useParams();


  useEffect(() => {
    const payload = { limit: 100, pageno: 1, sport_id: sportsId, series_id: 0, type: 'h' };
    dispatch(matchSeriesList(payload));
  }, [dispatch, sportsId]);

  useEffect(() => {
    const payload = { limit: 100, pageno: 1, sport_id: activeNav, series_id: 0, type: 'h' };
    dispatch(matchSeriesList(payload));
  }, [dispatch, activeNav]);


  useEffect(() => {
    if (matchSeriesListData) {
      setMatchSeriesData(matchSeriesListData);
    }
  }, [matchSeriesListData]);


  useEffect(() => {
    const { InplayMatches = [], UpCommingMatches = [] } = matchSeriesData || {};
    if (!InplayMatches?.length && !UpCommingMatches?.length) {
      return;
    }

    // Filter both Inplay and Upcoming matches d on the activeNav sport
    const filteredInplayMatches = InplayMatches.filter(
      (match) => Number(match?.sport_id) === activeNav
    );
    const filteredUpcomingMatches = UpCommingMatches.filter(
      (match) => Number(match?.sport_id) === activeNav
    );

    setFilteredInplayMatchData(filteredInplayMatches)
    const filteredMatches = [...filteredInplayMatches, ...filteredUpcomingMatches];

    if (filteredMatches.length) {
    } else {
      console.error("No filtered matches found.");
    }

    setFilteredMatchData(filteredMatches);
    setFilteredUpcomingMatchData(filteredUpcomingMatches);
  }, [activeNav, matchSeriesData]);

  useEffect(() => {
    const payload = { domain_id: 1 };
    dispatch(ourSlides(payload));
  }, [dispatch]);

  useEffect(() => {
    if (ourSlidesData) {
      setslideData(ourSlidesData);
    }
  }, [ourSlidesData]);

  useEffect(() => {
    const payload = { limit: 10, pageno: 1 };
    dispatch(SportMatchOuterList(payload));
  }, [dispatch]);

  useEffect(() => {
    if (SportMatchOuterListData) {
      setGetSportOuterList(SportMatchOuterListData);
    }
  }, [SportMatchOuterListData]);

  const handleNavClick = (sport_id) => {
    setActiveNav(sport_id);
    setLoading(true);


    setTimeout(() => {
      const filtered = getSportOuterList.filter((item) => item?.sport_id === sport_id);
      setFilteredData(filtered);
      setLoading(false);
    }, 1000);
  };

  const formatDate = (unixTimestamp) => {
    const date = new Date(unixTimestamp * 1000);
    const options = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric', 
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,
    };
    return date.toLocaleString('en-GB', options);
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  <Slider {...settings} className="tab-content-slider">
    {slideData && slideData.length > 0 ? (
      slideData.map((slide, index) => (
        <div key={index} className="item">
          <img src={slide?.attachment} alt={`Slide ${index + 1}`} />
        </div>
      ))
    ) : (
      <p>No slides available</p>
    )}
  </Slider>;


  const loggedIn = localStorage.getItem("user");

  return (
    <div className="">
      <div className="score-tabcontent-work px-0">
        <div className="tab-content tab-main-work" id="v-pills-tabContent">
          {loggedIn && (
            <div className="col-md-12 text-white font-bold d-flex align-items-center marquee-text">
              <span className="text-light d-flex align-items-center">
                <i className="fa fa-microphone"></i> News{" "}
              </span>
              <marquee class="marq" bgcolor=""
                direction="left" loop="">
                <div class="geek2">
                  {marequeeText?.value}
                </div>
              </marquee>
            </div>
          )}
          <Slider {...settings} className="tab-content-slider">
            {slideData && slideData.length > 0 ? (
              slideData.map((slide, index) => (
                <div key={index} className="item">
                  <img src={slide?.attachment} alt={`Slide ${index + 1}`} />
                </div>
              ))
            ) : (
              <p>No slides available</p>
            )}
          </Slider>
          <div
            className="tab-pane fade show active "
            id="v-pills-home"
            role="tabpanel"
            aria-labelledby="v-pills-home-tab"
          >
            <div className="d-none d-lg-block">
              <div className="highlights ">
                <div>Highlights</div>
              </div>
              <div>
                {!isUrlDynamic && (
                  <>
                    <ul className="d-flex pt-1 mt-0 desktop-nav">
                      <li
                        onClick={() => handleNavClick(4)}
                        className={`nav-link2 ${activeNav === 4 ? "active" : ""}`}
                      >
                        <span>Cricket</span>
                      </li>
                      <li
                        onClick={() => handleNavClick(1)}
                        className={`nav-link2 ${activeNav === 1 ? "active" : ""}`}
                      >
                        <span>Soccer</span>
                      </li>
                      <li
                        onClick={() => handleNavClick(2)}
                        className={`nav-link2 ${activeNav === 2 ? "active" : ""}`}
                      >
                        <span>Tennis</span>
                      </li>
                    </ul>
                  </>
                )}

                {
                  loading ? (
                    <Loader />
                  ) : (
                    <>
                      <div>
                        {filteredInplayMatchData.length > 0 || filteredUpcomingMatchData.length > 0 ? (
                          <>
                            <ul>
                              <table className="bet-table-chart" id="bet-table-chart" >
                                <thead>
                                  <tr>
                                    <th></th>
                                    <th></th>
                                    <th>
                                      <div className="width">
                                        <span>1</span>
                                      </div>
                                    </th>
                                    <th>
                                      <div className="width">
                                        <span>2</span>
                                      </div>
                                    </th>
                                    <th>
                                      <div className="width">
                                        <span>x</span>
                                      </div>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {filteredInplayMatchData.map((item, index) => (
                                    <tr key={index}>
                                      <td className="bet-table-chart-p">
                                        <span>
                                          {loggedIn ?
                                            <Link className="d-flex align-items-center gap-1" to={`/app/view-details/${item?.sport_id}/${item?.match_id}`}>
                                              <span className="fontSize15">{item?.name}</span> <span className="text-secondary font-semibold mx-2">{formatDate(item?.start_date)}</span>
                                              <Blink />
                                            </Link> :
                                            <Link className="d-flex align-items-center gap-1" to={`/viewdetail/${item?.sport_id}/${item?.match_id}`}>
                                              <span className="fontSize15">{item?.name}</span> <span className="text-secondary font-semibold mx-2">{formatDate(item?.start_date)}</span>
                                              <Blink />
                                            </Link>
                                          }
                                        </span>
                                      </td>
                                      <td>
                                        <div className="d-flex flex-wrap gap-2 justify-content-center bm-s-f">
                                          {activeNav === 4 && (
                                            <div className="cricketSpecificUi d-flex align-items-center gap-2">
                                              {/* {item?.IsBetAllow === "Y" && ( */}
                                              <span className="cricketS">M</span>
                                              {/* )} */}
                                              {/* {item?.market_type === "B" && ( */}
                                              <span className="cricketBm">BM</span>
                                              {/* )} */}
                                              {/* {item?.IsFancyAllow === "Y" && ( */}
                                              <span className="cricketF">F</span>
                                              {/* )} */}
                                            </div>
                                          )}
                                        </div>
                                      </td>
                                      <td>
                                        <div className="width">
                                          <span>{Math.floor(Math.random() * 100)}</span>
                                          <span>{Math.floor(Math.random() * 100)}</span>
                                        </div>
                                      </td>
                                      <td>
                                        <div className="width">
                                          <span>{Math.floor(Math.random() * 100)}</span>
                                          <span>{Math.floor(Math.random() * 100)}</span>
                                        </div>
                                      </td>
                                      <td>
                                        <div className="width">
                                          <span>{Math.floor(Math.random() * 100)}</span>
                                          <span>{Math.floor(Math.random() * 100)}</span>
                                        </div>
                                      </td>
                                    </tr>
                                  ))}

                                  {filteredUpcomingMatchData.map((item, index) => (
                                    <tr key={index}>
                                      <td className="bet-table-chart-p">
                                        <span className="">
                                          {loggedIn ?
                                            <Link className="d-flex align-items-center gap-1" to={`/app/view-details/${item?.sport_id}/${item?.match_id}`}>
                                              <span className="fontSize15">{item?.name}</span>
                                              <div className="text-secondary font-semibold mx-2">{formatDate(item?.start_date)}</div>
                                              {/* <Blink /> */}
                                            </Link> :
                                            <Link className="d-flex align-items-center gap-1" to={`/viewdetail/${item?.sport_id}/${item?.match_id}`}>
                                              <span className="fontSize15">{item?.name}</span> <span className="text-secondary font-semibold mx-2">{formatDate(item?.start_date)}</span>
                                              {/* <Blink /> */}
                                            </Link>
                                          }
                                        </span>
                                      </td>
                                      <td>
                                        <div className="d-flex flex-wrap gap-2 justify-content-center bm-s-f">
                                          {activeNav === 4 && (
                                            <div className="cricketSpecificUi d-flex align-items-center gap-2">
                                              {/* {item?.IsBetAllow === "Y" && ( */}
                                              <span className="cricketS">M</span>
                                              {/* )} */}
                                              {/* {item?.market_type === "B" && ( */}
                                              <span className="cricketBm">BM</span>
                                              {/* )} */}
                                              {/* {item?.IsFancyAllow === "Y" && ( */}
                                              <span className="cricketF">F</span>
                                              {/* )} */}
                                            </div>
                                          )}
                                        </div>
                                      </td>
                                      <td>
                                        <div className="width">
                                          <span>{Math.floor(Math.random() * 100)}</span>
                                          <span>{Math.floor(Math.random() * 100)}</span>
                                        </div>
                                      </td>
                                      <td>
                                        <div className="width">
                                          <span>{Math.floor(Math.random() * 100)}</span>
                                          <span>{Math.floor(Math.random() * 100)}</span>
                                        </div>
                                      </td>
                                      <td>
                                        <div className="width">
                                          <span>{Math.floor(Math.random() * 100)}</span>
                                          <span>{Math.floor(Math.random() * 100)}</span>
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>

                            </ul>
                          </>
                        ) : (
                          <p>No matches found</p>
                        )}
                      </div>
                    </>
                  )
                }
              </div>
            </div>
            <div className="d-lg-none d-block">
              <MobileSlideNav activeNav={activeNav} setActiveNav={setActiveNav} />
            </div>

            <div className="tab-content inner-data d-lg-none d-block" id="myTabContent">

              <div className="table-container">
                {
                  loading ? (
                    <Loader />
                  ) : (
                    <>
                      <div style={{ maxHeight: '180px', overflowY: 'auto' }}>
                        {filteredInplayMatchData.length > 0 || filteredUpcomingMatchData.length > 0 ? (
                          <>
                            {filteredInplayMatchData.length > 0 && (
                              <ul>
                                {filteredInplayMatchData.map((item) => (
                                  <>
                                    <div className="table-responsive">
                                      <table className="bet-table-chart" id="bet-table-chart" key={item.id}>
                                        <thead>
                                          <tr>
                                            <th></th>
                                            <th></th>
                                            <th>
                                              <div className="width">
                                                <span>1</span>
                                              </div>
                                            </th>
                                            <th>
                                              <div className="width">
                                                <span>2</span>
                                              </div>
                                            </th>
                                            <th>
                                              <div className="width">
                                                <span>x</span>
                                              </div>
                                            </th>
                                            <th></th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            <td className="bet-table-chart-p ">
                                              <span>
                                                {loggedIn ?
                                                  <Link className="d-flex flex-column align-items-start fontSize15" to={`/app/view-details/${item?.sport_id}/${item?.match_id}`}>
                                                   <span className="fontSize15"> {item?.name}</span> <span className="text-secondary font-semibold ">{formatDate(item?.start_date)} <Blink /></span>

                                                  </Link> :
                                                  <Link className="d-flex flex-column align-items-start fontSize15" to={`/viewdetail/${item?.sport_id}/${item?.match_id}`}>
                                                   <span className="fontSize15"> {item?.name}</span><span className="text-secondary font-semibold ">{formatDate(item?.start_date)}  <Blink /></span>

                                                  </Link>
                                                }
                                              </span>
                                            </td>
                                            <td>
                                              <div className="d-flex flex-wrap gap-2 justify-content-center bm-s-f me-2">
                                                {activeNav === 4 && (
                                                  <div className="cricketSpecificUi d-flex align-items-center gap-2">
                                                    {/* {item?.IsBetAllow === "Y" && ( */}
                                                    <span className="cricketS">M</span>
                                                    {/* )} */}
                                                    {/* {item?.market_type === "B" && ( */}
                                                    <span className="cricketBm">BM</span>
                                                    {/* )} */}
                                                    {/* {item?.IsFancyAllow === "Y" && ( */}
                                                    <span className="cricketF">F</span>
                                                    {/* )} */}
                                                  </div>
                                                )}
                                              </div>
                                            </td>
                                            <td>
                                              <div className="width">
                                                <span>{Math.floor(Math.random() * 100)}</span>
                                                <span>{Math.floor(Math.random() * 100)}</span>
                                              </div>
                                            </td>
                                            <td>
                                              <div className="width">
                                                <span>{Math.floor(Math.random() * 100)}</span>
                                                <span>{Math.floor(Math.random() * 100)}</span>
                                              </div>
                                            </td>
                                            <td>
                                              <div className="width">
                                                <span>{Math.floor(Math.random() * 100)}</span>
                                                <span>{Math.floor(Math.random() * 100)}</span>
                                              </div>
                                            </td>
                                            {/* <td className="pin">
                                          <a>
                                            <i className="fa fa-thumb-tack" aria-hidden="true"></i>
                                          </a>
                                        </td> */}
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </>
                                ))}
                              </ul>
                            )}

                            {filteredUpcomingMatchData.length > 0 && (
                              <ul>
                                {filteredUpcomingMatchData.map((item) => (
                                  <table className="bet-table-chart" id="bet-table-chart" key={item.id}>
                                    <thead>
                                      <tr>
                                        <th></th>
                                        <th></th>
                                        <th>
                                          <div className="width">
                                            <span>1</span>
                                          </div>
                                        </th>
                                        <th>
                                          <div className="width">
                                            <span>2</span>
                                          </div>
                                        </th>
                                        <th>
                                          <div className="width">
                                            <span>x</span>
                                          </div>
                                        </th>
                                        <th></th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td className="bet-table-chart-p">
                                          <span>
                                            {loggedIn ?
                                              <Link className="d-flex flex-column align-items-start fontSize15" to={`/app/view-details/${item?.sport_id}/${item?.match_id}`}>
                                                {item?.name} <span className="text-secondary font-semibold ">{formatDate(item?.start_date)}</span>
                                                {/* <Blink /> */}
                                              </Link> :
                                              <Link className="d-flex flex-column align-items-start fontSize15" to={`/viewdetail/${item?.sport_id}/${item?.match_id}`}>
                                                {item?.name}<span className="text-secondary font-semibold ">{formatDate(item?.start_date)}</span>
                                                {/* <Blink /> */}
                                              </Link>
                                            }
                                          </span>
                                        </td>
                                        <td>
                                          <div className="d-flex flex-wrap gap-2 justify-content-center bm-s-f me-2">
                                            {activeNav === 4 && (
                                              <div className="cricketSpecificUi d-flex align-items-center gap-2">
                                                {/* {item?.IsBetAllow === "Y" && ( */}
                                                <span className="cricketS">M</span>
                                                {/* )} */}
                                                {/* {item?.market_type === "B" && ( */}
                                                <span className="cricketBm">BM</span>
                                                {/* )} */}
                                                {/* {item?.IsFancyAllow === "Y" && ( */}
                                                <span className="cricketF">F</span>
                                                {/* )} */}
                                              </div>
                                            )}
                                          </div>
                                        </td>
                                        <td>
                                          <div className="width">
                                            <span>{Math.floor(Math.random() * 100)}</span>
                                            <span>{Math.floor(Math.random() * 100)}</span>
                                          </div>
                                        </td>
                                        <td>
                                          <div className="width">
                                            <span>{Math.floor(Math.random() * 100)}</span>
                                            <span>{Math.floor(Math.random() * 100)}</span>
                                          </div>
                                        </td>
                                        <td>
                                          <div className="width">
                                            <span>{Math.floor(Math.random() * 100)}</span>
                                            <span>{Math.floor(Math.random() * 100)}</span>
                                          </div>
                                        </td>
                                        {/* <td className="pin">
                                        <a>
                                          <i className="fa fa-thumb-tack" aria-hidden="true"></i>
                                        </a>
                                      </td> */}
                                      </tr>
                                    </tbody>
                                  </table>
                                ))}
                              </ul>
                            )}
                          </>
                        ) : (
                          <p>No matches found</p>
                        )}
                      </div>
                    </>
                  )
                }
                {/* <HomeBets /> */}
                {/* {(!matchlistData || matchlistData.length === 0) && (
                    <NoDataAvailable />
                  )} */}

              </div>

              {/* {activeNav === 2 ?
              <><SecondBets /></> : null
            }

            {activeNav === 1 ?
              <><SecondBets /></> : null
            } */}
              {activeNav === 2005 ?
                <></> : null
              }
              {activeNav === 2003 ?
                <></> : null
              }
              {activeNav === 2001 ?
                <><SecondBets /></> : null
              }
              {activeNav === 2323 ?
                <><LotteryImages /> </> : null
              }
              {activeNav === 2324 ?
                <></> : null
              }
              {activeNav === 321321 ?
                <></> : null
              }
            </div>
            {
              !isUrlDynamic && (
                <CasinoListNav activeCasinoTab={activeCasinoTab} setActiveCasinoTab={setActiveCasinoTab} />
              )
            }
            {/* <CasinoImages activeCasinoTab={activeCasinoTab} /> */}
            <Footer />
          </div>
          <MobileMenu />
        </div>
      </div>
      {/* <div className="col-lg-3 score-tabbets ">
        <RightSidebar />
      </div> */}
    </div>
  );
}

export default Dashboard;
