function RollingCommission() {
    return (
        <>
                <form className="row rollingform1">

                    <div className="col-lg-4 col-md-4 col-6 my-profile-select">
                        <select >
                            <option value="">Data Source</option><option value="LIVE">LIVE DATA</option>
                            <option value="BACKUP">BACKUP DATA</option><option value="OLD">OLD DATA</option>
                        </select>
                    </div>

                    <div className="col-lg-4 col-md-4 col-6 my-profile-select">
                        <select >
                            <option value="">Data Source</option><option value="LIVE">LIVE DATA</option>
                            <option value="BACKUP">BACKUP DATA</option><option value="OLD">OLD DATA</option>
                        </select>
                    </div>

                    <div className="col-lg-4 col-md-4  col-12  my-profile-select">
                        <input className="position-relative abc" type="date" id="date" /> 

                       
                    </div>

                    <div className="col-lg-4 col-md-4 col-12 my-profile-select mt-3">
                        <input className="position-relative abc" type="date" id="date" />
                    </div>

                    <div className="col-lg-4 col-md-4 col-6 my-profile-select mt-3">
                        <button className="form-btn border-0">Get Commission</button>
                    </div>
                   
                </form>

                <div className="roling-table border">
                    <div className="rolling-commission-div">
                        <h4>Rolling Commission</h4>
                    </div>
                    <div className="commission-table">
                        <table className="table mb-0">
                            <thead>
                            <tr>
                                <th scope="col"><p>Type</p></th>
                                <th scope="col"><p>Total Stack</p></th>
                                <th scope="col"><p>Total Commission</p></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td colspan="3" className="text-center"><p>No data!</p></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
         
            </>
    )
}

export default RollingCommission
