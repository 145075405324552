import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
// import { AuthHeader } from "../Features/Helper/AuthHeader";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
// import Loader from "../Components/Loader/Loader";
import { toast } from "react-toastify";
import { authHeader } from "../middelware/Auth";
import RightSidebar from "../components/RightSidebar/RightSidebar";
import { CONST } from "../middelware/Const";

const Iframe = () => {
    const [isFetchingUrl, setIsFetchingUrl] = useState(false);
    const [redirectUrl, setRedirectUrl] = useState("");
    const [isIframe, setIsIframe] = useState(false);
    const location = useLocation();
    const [searchParams] = useSearchParams();
    // Extract query parameters
    const { providerName, gameId } = useParams();
    const gameName = searchParams.get('game_name');
    const id = searchParams.get('id');
    const navigate = useNavigate();
    useEffect(() => {
        test();
    }, [providerName, gameId]);

    useEffect(() => {
        testOne();
    }, [providerName, gameId]);

    const test = async (value) => {
        setIsFetchingUrl(true);
        try {
            const response = await fetch(`${CONST.CASINO_URL}game-login`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    ...authHeader(),
                },
                body: JSON.stringify({
                    providerName: providerName,
                    gameId: gameId,
                }),
            });

            const result = await response.json();
            if (result?.data?.url) {
                setIsIframe(true);
                setRedirectUrl(result?.data?.url);
            }

            // Check for error case with message "valid token" and show a toast message
            // if (result?.error === true) {
            //     // Show success toast
            //     toast.error("Token is invalid");

            //     // Redirect to Dashboard after showing the toast
            //     navigate("/dashboard");
            // }
        } catch (error) {
            console.error(error);
        } finally {
            setIsFetchingUrl(false);
        }
    };

    const testOne = async (value) => {
        setIsFetchingUrl(true);
        try {
            const response = await fetch(`${CONST.CASINO_URL}game-login`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    ...authHeader(),
                },
                body: JSON.stringify({
                    providerName: providerName,
                    gameId: gameId,
                }),
            });

            const result = await response.json();
            if (result?.data?.url) {
                setIsIframe(true);
                setRedirectUrl(result?.data?.url);
            }

            // Check for error case with message "valid token" and show a toast message
            // if (result?.error === true) {
            //     // Show success toast
            //     toast.error("Token is invalid");

            //     // Redirect to Dashboard after showing the toast
            //     navigate("/dashboard");
            // }
        } catch (error) {
            console.error(error);
        } finally {
            setIsFetchingUrl(false);
        }
    };

    return (
        <div className="row mx-0">
            <div
                className="iframe-all iframeWrapper"
                style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#000",
                }}
            >
                {isIframe && (
                    <iframe
                        src={redirectUrl}
                        title="Casino Game"
                        loading="lazy"
                        style={{
                            height: "100%",
                            width: "100%",
                            border: "none",
                        }}
                    />
                )}
            </div>
            {/* <div className="col-lg-3 score-tabbets ">
                <RightSidebar />
            </div> */}
        </div>
    );
};

function mapStateToProps(state) {
    const { users } = state;
    return {
        users,
    };
}

export default connect(mapStateToProps)(Iframe);
