// export const handleResponse = (response) => {
//     return response.text().then(text => {
//         const data = text && JSON.parse(text);

//         if (!response.ok) {
//             if (response.status === 401) {
                
//             }

//             if (response.status === 500 || response.status === 502 || response.status === 505) {
//                 console.error(`Server Error: ${response.status}`);
//                 const error = `Server Error: ${response.status}`;
//                 return Promise.reject(error);
//             }

//             const error = (data && data.message) || response.statusText;
//             return Promise.reject(error);
//         }
//         if (data.error) {
//             if (data.code === 3) {
               
//             }
//             const error = (data && data.message) || response.statusText;
//             return Promise.reject(error);
//         }

//         return data;
//     });
// };


export const handleResponse = async (response) => {
    const text = await response.text();
    const data = text && JSON.parse(text);

    if (!response.ok) {
        if (response.status === 401) {
            // Handle unauthorized error
        }

        if ([500, 502, 505].includes(response.status)) {
            console.error(`Server Error: ${response.status}`);
            throw new Error(`Server Error: ${response.status}`);
        }

        const error = (data && data.message) || response.statusText;
        throw new Error(error);
    }

    if (data.error) {
        if (data.code === 3) {
            // Handle specific error code
        }
        const error = (data && data.message) || response.statusText;
        throw new Error(error);
    }

    return data;
};
