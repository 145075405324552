
import { combineReducers } from '@reduxjs/toolkit';

import authenticationReducer from './_reducers/_reducers';

const rootReducer = combineReducers({
  authentication: authenticationReducer,
});

export default rootReducer;
