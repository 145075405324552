function RollingCommision({handleRollingModal}) {
    return (
        <div className="rolling-commission-sec" id="rolling-commission-sec">
        <div className="rolling-commission-sec2">
            <h4 className="d-flex flex-wrap justify-content-between">Rolling Commission <i className="fa fa-times" aria-hidden="true" onClick={handleRollingModal}></i>
            </h4>
            <ul>
                <li className="d-flex flex-wrap align-items-center border"><p className="mb-0">Fancy</p> <span>0</span></li>
                <li className="d-flex flex-wrap align-items-center border"><p className="mb-0">Fancy</p> <span>0</span></li>
                <li className="d-flex flex-wrap align-items-center border"><p className="mb-0">Fancy</p> <span>0</span></li>
                <li className="d-flex flex-wrap align-items-center border"><p className="mb-0">Fancy</p> <span>0</span></li>
                <li className="d-flex flex-wrap align-items-center border"><p className="mb-0">Fancy</p> <span>0</span></li>
                <li className="d-flex flex-wrap align-items-center border"><p className="mb-0">Fancy</p> <span>0</span></li>
                <li className="d-flex flex-wrap align-items-center border"><p className="mb-0">Fancy</p> <span>0</span></li>
                <li className="d-flex flex-wrap align-items-center border"><p className="mb-0">Fancy</p> <span>0</span></li>
            </ul>
        </div>
    </div>
    )
}

export default RollingCommision
