import React, { useState } from 'react'

import { Link, useNavigate } from 'react-router-dom'

const UserNotLoginPopup = ({setConfirmPopup}) => {
    const [close, setClose] = useState(false)
    const navigate = useNavigate();
    const handleClose = ()=>{}
    return (
        <section className="pop-up-sec text-center text-white ">
            <div className="pop-up-sec-work position-relative">
                <div className="container-fluid">
                    <h2 className="border-bottom">Non-Gambling Territories.</h2>
                    <h4>Connecting to our site from non gambling countries, it will be User's responsibility to ensure that their use of the service is lawful.</h4>
                    <span className="border-bottom">Underage gambling is prohibited.</span>
                    <p>Please confirm if you are 18 years old and above  as of today. </p>
                    <div className="pop-up-btn ">
                        <div className="pop-up-btn flex-wrap flex">
                            <button onClick={()=>{setConfirmPopup(false);navigate("/login")}} >  confirm</button>
                            <button onClick={()=>setConfirmPopup(false)} className="bg-dark text-white">Exit</button>
                        </div>
                    </div>
                    <div  onClick={()=>setConfirmPopup(false)} className="cross-icon-popup position-absolute top-0 end-0"

                    ><i className="fa fa-times" aria-hidden="true"></i></div>

                </div>
            </div>
        </section>
    )
}

export default UserNotLoginPopup