import React from "react";

function DepositModal({ onClose }){
    return(
        <div className="depositmodal position-fixed" onClick={onClose}>
            <div className="depositmodal-data">
                <input type="number" placeholder="Deposit Amount*"/>
                <p>Minimum deposit Amount is 100.</p>
                <button>Deposit</button>
            </div>
        </div>
    )
}

export default DepositModal;