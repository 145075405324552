function PasswordHistory() {
    return (
        <>
            <div className="account-statment-tablwork">
                <div className="account-statment-table">
                    <div className="roling-table border">
                        <div className="rolling-commission-div">
                            <h4>Password Change History</h4>
                        </div>
                        <div className="commission-table account-statment-tables">
                            <div className="account-lable d-flex flex-wrap align-items-center">
                                <p className="mb-0">Show</p>
                                <label className="mx-1">
                                    <select >
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </select>
                                </label>
                                <p className="mb-0">entries</p>
                            </div>
                            <table className="table mb-0">
                                <thead>
                                    <tr>
                                        <th scope="col">
                                            <div className="d-flex flex-wrap align-items-center justify-content-center">
                                                <p>Date/Time</p>
                                                <i className="fa fa-sort" aria-hidden="true"></i>
                                            </div>
                                        </th>

                                        <th scope="col">
                                            <div className="d-flex flex-wrap align-items-center justify-content-center">
                                                <p>Remarks</p>
                                                <i className="fa fa-sort" aria-hidden="true"></i>
                                            </div>
                                        </th>

                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td colspan="6" className="text-center"><p>No data!</p></td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="howing-content row justify-content-between">
                                <div className="showing-content-left col-lg-6 col-md-7 col-sm-12"><p>Showing 0 to 0 of 0 entries</p></div>
                                <div className="showing-content-right col-lg-6 col-md-5 col-sm-12">
                                    <ul className="d-flex align-items-center flex-wrap justify-content-lg-end ">
                                        <li>First</li>
                                        <li>Previous</li>
                                        <li>Next</li>
                                        <li>Last</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default PasswordHistory

