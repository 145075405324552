import Slider from "react-slick"
import LotteryImages from "../../../components/LotteryImages/LotteryImages"
import Footer from "../../../components/Footer/Footer";
import { ourSlides, casinoListByCategory } from "../../../redux/_reducers/_reducers";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Loader from "../../../components/Loader/Loader";
import RightSidebar from "../../../components/RightSidebar/RightSidebar";

function Lottery() {
  const { ourSlidesData, casinoListByCategoryData, loading } = useSelector((state) => state.authentication);
  const [slideData, setSlideData] = useState();
  const [lotteryData, setLotteryData] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(ourSlides())
  }, [])

  useEffect(() => {
    const payload = { category: 'lottery', limit: 1000 }
    dispatch(casinoListByCategory(payload))
  }, [])

  useEffect(() => {
    if (casinoListByCategoryData) {
      setLotteryData(casinoListByCategoryData);
    }
  }, [casinoListByCategoryData]);

  useEffect(() => {
    if (ourSlidesData) {
      setSlideData(ourSlidesData);
    }
  }, [ourSlidesData]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      {loading && <Loader />}
      <div className="row mx-0">
        <div className="score-tabcontent-work">
          <div className="col-md-12 marquee-text">
            <span className="text-light">
              {" "}
              <i className="fa fa-microphone"></i> News{" "}
            </span>
          </div>
          <Slider {...settings} className="tab-content-slider">
            {slideData && slideData.length > 0 ? (
              slideData.map((slide, index) => (
                <div key={index} className="item">
                  <img src={slide?.attachment} alt={`Slide ${index + 1}`} />
                </div>
              ))
            ) : (
              <p>No slides available</p>
            )}
          </Slider>
          <LotteryImages lotteryData={lotteryData} />
          <Footer />
        </div>
        {/* <div className="col-lg-3 score-tabbets ">
          <RightSidebar />
        </div> */}
      </div>
    </>
  )
}

export default Lottery
