import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
function PrivateRoute({ element }) {
    const navigate = useNavigate();
    const loginData = JSON.parse(localStorage.getItem("user"))
  
    useEffect(() => {
      if (!loginData) {
        navigate('/');
      }
    }, []);
  
    return <>{element}</>;
  }
  export default PrivateRoute