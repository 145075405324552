import { useState } from "react";
import { useDispatch } from "react-redux";
import { GameConstants } from "../../middelware/GameConstants";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FaArrowRight, FaRegClock, FaTableTennis } from "react-icons/fa";
import { eventGameList, gameEventList } from "../../redux/_reducers/_reducers";
import { IoIosArrowForward, IoIosTennisball } from "react-icons/io";
import { GiSoccerBall } from "react-icons/gi";
import { toast } from "react-toastify";

const SportsPage = () => {
    const [activeTab, setActiveTab] = useState(1);
    const [sidebarlistData, setSidbarListData] = useState([]);
    const [eventListData, setEventListData] = useState([]);
    const [expandedSportId, setExpandedSportId] = useState(null);
    const [expandedItemId, setExpandedItemId] = useState(null);

    const userLoginData = localStorage.getItem("user")

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleTab1Click = async (id, providerName) => {
        setActiveTab(3);
        setExpandedSportId(id);
        // navigate(`/allsportshighlight/${id}`);

        if (id === 900021 || id === 100049) {
            const isLoggedIn = localStorage.getItem("user") ? true : false;

            if (isLoggedIn) {
                const gameId = id;
                const iframeURL = `/iframe/${providerName}/${gameId}`;
                navigate(iframeURL);
            } else {
                navigate("/login");
            }

            return;
        }

        const payloadd = {
            limit: 20,
            sport_id: id,
            pageno: 1,
        };

        try {
            const result = await dispatch(eventGameList(payloadd));
            if (result?.payload) {
                setEventListData(result?.payload);
            }
        } catch (error) {
            console.error('Error fetching second API data:', error);
        }
    };

    const handleBackClick = () => {
        setActiveTab(1);
        setExpandedSportId(null);
        setSidbarListData([])
        setExpandedItemId()
    };

    const handleSidebarClick = (sportId, matchId) => {
        navigate(`/app/view-details/${sportId}/${matchId}`);
    };


    // const handleGameList = async (item) => {
    //     setSidbarListData([])
    //     setExpandedItemId()
    //     const payloaddd = {
    //         limit: 20,
    //         sport_id: item?.sport_id,
    //         pageno: 1,
    //         series_id: item?.series_id,
    //     };

    //     try {
    //         const result = await dispatch(gameEventList(payloaddd));
    //         if (result?.payload) {
    //         setSidbarListData(result?.payload);
    //         setExpandedItemId(item.id);
    //         }
    //     } catch (error) {
    //         console.error('Error fetching second API data:', error);
    //     }
    // }

    const handleGameList = async (item) => {
        setSidbarListData([]);
        setExpandedItemId(null);

        const payloaddd = {
            limit: 20,
            sport_id: item?.sport_id,
            pageno: 1,
            series_id: item?.series_id,
        };

        try {
            const result = await dispatch(gameEventList(payloaddd));
            if (result?.error) {
                // If the result contains an error, display it in the toaster
                const errorMessage = result?.error?.message || 'An unexpected error occurred.';
                toast.error(errorMessage); // Assuming you are using a library like react-toastify for toasts
                return;
            }

            if (result?.payload) {
                setSidbarListData(result?.payload);
                setExpandedItemId(item.id);
            }
        } catch (error) {
            // Handle unexpected errors and display them in the toaster
            const errorMessage = error?.response?.data?.message || 'An error occurred while fetching data.';
            toast.error(errorMessage);
            console.error('Error fetching second API data:', error);
        }
    };

    return (
        <>
            <div className="sportHeader">
                Quick Links
            </div>
            <div>
                <ul className="d-flex align-items-center sportsUl">
                    <li className="sportsli">
                        <a className="sportsAnchor" href="/inPlay">
                            <div><FaRegClock size={30} /></div>
                            <div>In-Play</div>
                        </a>
                    </li>
                    {/* <li className="sportsli">
                        <a className="sportsAnchor" href="">
                            <div><IoIosTennisball size={30} /></div>
                            <div>Multi Markets</div>
                        </a>
                    </li> */}
                    <li className="sportsli">
                        <div className="sportsAnchor" href="">
                            <div><IoIosTennisball size={30} /></div>
                            <div onClick={() => handleTab1Click(4)}>Cricket</div>
                        </div>
                    </li>
                    <li className="sportsli">
                        <div className="sportsAnchor" href="">
                            <div><GiSoccerBall size={30} /></div>
                            <div onClick={() => handleTab1Click(1)}>Soccer</div>
                        </div>
                    </li>
                    <li className="sportsli">
                        <div className="sportsAnchor" href="">
                            <div><FaTableTennis size={30} /></div>
                            <div onClick={() => handleTab1Click(2)}>Tennis</div>
                        </div>
                    </li>
                </ul>
            </div>
            <div className="score-tab-work d-lg-block">
                <div className="sports-black-ul bg-dark text-light sportHeader">
                    <a onClick={() => handleBackClick()} className="d-flex justify-content-center">
                        {/* <i className="fa fa-ellipsis-v" aria-hidden="true"></i> */}
                        <div className="m-0">All Sports</div>
                    </a>
                </div>
                {activeTab === 1 && expandedSportId === null && (
                    <ul className="list-unstyled">
                        {GameConstants.map((game) => (
                            <>
                                <li className="sportLi cursor-pointer" key={game.sportId}>
                                    {userLoginData ?
                                        <a className="cursor-pointer sportAnchor d-flex justify-content-between align-items-center" onClick={() => handleTab1Click(game?.sportId, game?.providerName)}>
                                            {game.sportName.charAt(0).toUpperCase() + game.sportName.slice(1)}
                                            <div className="nav-position">
                                                <i className="fa fa-angle-right" aria-hidden="true"></i>
                                            </div>

                                            <div className='arrowBtn'>
                                                <IoIosArrowForward className="text-black" />
                                            </div>
                                        </a> :
                                        <a>
                                            {game.sportName.charAt(0).toUpperCase() + game.sportName.slice(1)}
                                            <div className="nav-position">
                                                <i className="fa fa-angle-right" aria-hidden="true"></i>
                                            </div>
                                        </a>
                                    }
                                </li>
                            </>
                        ))}
                    </ul>
                )}

                {activeTab === 3 && (
                    <div className="expanded-event-list">
                        {eventListData.length > 0 ? (
                            eventListData.map((item) => (
                                <ul key={item.id}>
                                    <li className="sportLi" onClick={() => handleGameList(item)}><Link className="sportAnchor">{item?.name}</Link></li>
                                    {expandedItemId === item.id && (
                                        <div className="sidebar-list-data">
                                            {sidebarlistData && sidebarlistData.length > 0 ? (
                                                sidebarlistData.map((match, index) => (
                                                    <li
                                                        key={match.matchId || index}
                                                        className="d-flex sportLi gap-2 justify-content-start align-items-center"
                                                        onClick={() => handleSidebarClick(match.sport_id, match.match_id)}
                                                    >
                                                        <span>
                                                            <FaArrowRight className="" style={{ color: '#2789ce' }} />
                                                        </span>
                                                        <span className="cursor-pointer sportAnchor">{match.name}</span>
                                                    </li>
                                                ))
                                            ) : (
                                                <div>No Data Available</div>
                                            )}
                                        </div>
                                    )}
                                </ul>
                            ))
                        ) : <div>No Data Available</div>}
                    </div>
                )}

            </div>
        </>
    )
}

export default SportsPage