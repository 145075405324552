function ActivityLog() {
    return (
        <div className="score-tabcontent-work">
            <div className="activity-log-sec2">
                <div className="roling-table border">
                    <div className="rolling-commission-div">
                        <h4>Activity Log</h4>
                    </div>
                    <div className="commission-table account-statment-tables ">
                        <table className="table mb-0" >
                            <thead>
                                <tr>
                                    <th scope="col">Login Date & Time</th>
                                    <th scope="col">Login Status</th>
                                    <th scope="col">IP Address</th>
                                    <th scope="col">ISP</th>
                                    <th scope="col" className="activity-log-sec2th">City/State/Country</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <td scope="row">13-06-2024 12:58:45</td>
                                    <td className="text-success ">Login Successful</td>
                                    <td>157.45.131.35</td>
                                    <td>Reliance Jio Infocomm Limited</td>
                                    <td>Bengaluru/Karnataka/India</td>
                                </tr>
                                <tr>
                                    <td scope="row">13-06-2024 12:58:45</td>
                                    <td className="text-success ">Login Successful</td>
                                    <td>157.45.131.35</td>
                                    <td>Reliance Jio Infocomm Limited</td>
                                    <td>Bengaluru/Karnataka/India</td>
                                </tr>
                                <tr>
                                    <td scope="row">13-06-2024 12:58:45</td>
                                    <td className="text-success ">Login Successful</td>
                                    <td>157.45.131.35</td>
                                    <td>Reliance Jio Infocomm Limited</td>
                                    <td>Bengaluru/Karnataka/India</td>
                                </tr>
                                <tr>
                                    <td scope="row">13-06-2024 12:58:45</td>
                                    <td className="text-success ">Login Successful</td>
                                    <td>157.45.131.35</td>
                                    <td>Reliance Jio Infocomm Limited</td>
                                    <td>Bengaluru/Karnataka/India</td>
                                </tr>
                                <tr>
                                    <td scope="row">13-06-2024 12:58:45</td>
                                    <td className="text-success ">Login Successful</td>
                                    <td>157.45.131.35</td>
                                    <td>Reliance Jio Infocomm Limited</td>
                                    <td>Bengaluru/Karnataka/India</td>
                                </tr>
                                <tr>
                                    <td scope="row">13-06-2024 12:58:45</td>
                                    <td className="text-success ">Login Successful</td>
                                    <td>157.45.131.35</td>
                                    <td>Reliance Jio Infocomm Limited</td>
                                    <td>Bengaluru/Karnataka/India</td>
                                </tr>
                                <tr>
                                    <td scope="row">13-06-2024 12:58:45</td>
                                    <td className="text-success ">Login Successful</td>
                                    <td>157.45.131.35</td>
                                    <td>Reliance Jio Infocomm Limited</td>
                                    <td>Bengaluru/Karnataka/India</td>
                                </tr>
                                <tr>
                                    <td scope="row">13-06-2024 12:58:45</td>
                                    <td className="text-success ">Login Successful</td>
                                    <td>157.45.131.35</td>
                                    <td>Reliance Jio Infocomm Limited</td>
                                    <td>Bengaluru/Karnataka/India</td>
                                </tr>
                                <tr>
                                    <td scope="row">13-06-2024 12:58:45</td>
                                    <td className="text-success ">Login Successful</td>
                                    <td>157.45.131.35</td>
                                    <td>Reliance Jio Infocomm Limited</td>
                                    <td>Bengaluru/Karnataka/India</td>
                                </tr>
                                <tr>
                                    <td scope="row">13-06-2024 12:58:45</td>
                                    <td className="text-success ">Login Successful</td>
                                    <td>157.45.131.35</td>
                                    <td>Reliance Jio Infocomm Limited</td>
                                    <td>Bengaluru/Karnataka/India</td>
                                </tr>
                                <tr>
                                    <td scope="row">13-06-2024 12:58:45</td>
                                    <td className="text-success ">Login Successful</td>
                                    <td>157.45.131.35</td>
                                    <td>Reliance Jio Infocomm Limited</td>
                                    <td>Bengaluru/Karnataka/India</td>
                                </tr>
                            </tbody>

                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ActivityLog
