import React, { useState } from "react";
import DepositModal from "../../components/DepositModal/Depositmodal";
import Withdraw from "../Withdraw/withdraw";
import Reward from "../Reward/Reward";
import { Link } from "react-router-dom";

function Payment() {
  const [isDeposit, setIsDeposit] = useState(false);
  

  const depositClick = () => {
    setIsDeposit(!isDeposit);
  };

  const closeModal = () => {
    setIsDeposit(false);
  };

  return (
    <div className="payment">
      <div className="payment-copy">
        <div className="d-flex flex-wrap payment-threedata">
          <div className="payment-option">
            <div className="payment-optioncopy">
              <Link to='/app/deposite'><h3>Deposit</h3></Link>
            </div>
          </div>
          <div className="payment-option">
            <Link to='/app/withdraw'><div className="payment-optioncopy">
              <h3>Withdraw</h3>
            </div></Link>
          </div>
        </div>
      </div>

      {isDeposit && <DepositModal onClose={closeModal} />}

    
    </div>
  );
}

export default Payment;
