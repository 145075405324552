import moment from "moment"

function RightSidebarDetailsPage({ showIframe, MainTV, handleIconClick, sportGraphic, matchBetList, sessionBetList }) {
    return (
        <div>
            <div className="d-none d-lg-block" style={{ background: "black", color: "white", padding: "2px", paddingInline: "5px" }} onClick={() => handleIconClick()}>Live Streaming</div>
            {sportGraphic === "Y" ? (
                <>
                    {showIframe && (
                        <iframe
                            src={MainTV}
                            style={{ width: '100%', height: '280px' }}
                            title="MainTV Content"
                        />
                    )}
                </>) : null}

            <div className="d-none d-lg-block" style={{ background: "black", color: "white", padding: "2px", paddingInline: "5px" , marginTop:"5px" }}>Open Bets</div>
            <div className="detail-hide">
                {sessionBetList && sessionBetList.length > 0 ?
                    <div>
                        <div className="fancy-oddgreen" style={{ backgroundColor: 'rgb(203 209 209)' }}><h4>Fancy</h4></div>
                        <div className="d-flex justify-content-between">
                            <div className="text-start ms-1" style={{ width: '55%' }}>Back (Bet For)</div>
                            <div className="text-center" style={{ width: '15%' }}>Odds</div>
                            <div className="text-center" style={{ width: '15%' }}>Stake</div>
                            <div className="text-center" style={{ width: '15%' }}>Profit</div>
                        </div>
                        <div>
                            {sessionBetList && sessionBetList.length > 0 ? sessionBetList.map((element, index) => (
                                <>
                                    <div
                                        className="d-flex justify-content-start align-items-center ps-1"
                                        style={{
                                            backgroundColor: element.is_back === "1" ? "#beddf4" : "#f3dce2",
                                            color: "#3b5160",
                                            fontSize: "10px",
                                        }}
                                    >
                                        Ref: {moment.unix(element.created_at).format("DD-MM-YYYY HH:MM:SS")}
                                    </div>
                                    <div
                                        className="d-flex justify-content-between teamname-div teamNameFancy"
                                        style={{
                                            backgroundColor: element.is_back === "1" ? "#beddf4" : "#f3dce2",
                                            border: element.is_back === "1" ? "1px solid #7dbbe9" : "1px solid #dfa3b3",
                                        }}
                                    >
                                        <div className={`d-flex gap-2 ${element.is_back === "1" ? "teamname-bet" : "teamname-bet2"
                                            }`}
                                            style={{ width: "55%" }}
                                        >
                                            <div className="d-flex gap-2">
                                                <span className="d-flex align-items-center justify-content-center"
                                                    style={{
                                                        padding: "2px",
                                                        borderRadius: "5px",
                                                        color: "black",
                                                        fontWeight: "bold",
                                                        backgroundColor: element.is_back === "1" ? "#7dbbe9" : "#dfa3b3",
                                                        border: `1px solid ${element.is_back === "1" ? "#7dbbe9" : "#dfa3b3"}`,
                                                    }}
                                                >
                                                    {element.is_back === "1" ? "Back" : "Lay"}
                                                </span>
                                                <span>
                                                    <div><strong>{element?.fancy_name}</strong></div>
                                                    {/* <div>({element?.marketName})</div> */}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-center align-items-center" style={{ width: "15%" }}>
                                            <span className="text-center">{element?.run}/{element?.size}</span>
                                        </div>
                                        <div className="d-flex justify-content-center align-items-center" style={{ width: "15%" }}>
                                            <span className="text-center">{element?.stack}</span>
                                        </div>
                                        <div className="d-flex justify-content-center align-items-center" style={{ width: "15%" }}>
                                            <span className="text-center">{element?.profit}</span>
                                        </div>
                                    </div>
                                    {/* <div className="d-flex justify-content-between teamname-div">
                                        <div className={`${element.is_back === "1" ? "teamname-bet" : "teamname-bet2"}`} style={{ width: "20%" }}><span>{element.is_back === "1" ? "Back" : "Lay"}{" "}({element?.fancy_name})</span></div>
                                        <div style={{ width: "20%" }}>Ref: {moment.unix(element.created_at).format("DD-MM-YYYY HH:MM:SS")}</div>
                                        <div className={`${element.is_back === "1" ? "teamname-bet" : "teamname-bet2"}`} style={{ width: "20%" }}><span>{element.is_back === "1" ? "Back" : "Lay"}{" "}({element?.fancy_name})</span></div>
                                        <div style={{ width: "20%" }}><span className="text-center">{element?.run}/{element?.size}</span></div>
                                        <div style={{ width: "20%" }}><span className="text-center">{element?.stack}</span></div>
                                        <div style={{ width: "20%" }}><span className="text-center">{element?.profit}</span></div>
                                    </div> */}
                                </>
                            )) : null}
                        </div>
                    </div> : null
                }
                {matchBetList && matchBetList.length > 0 ?
                    <div>
                        <div className="fancy-oddgreen" style={{ backgroundColor: 'rgb(203 209 209)' }}><h4>Odds</h4></div>
                        <div className="d-flex justify-content-between text-center">
                            <div className="text-start ms-1" style={{ width: '55%' }}>Back (Bet For)</div>
                            <div style={{ width: '15%' }}>Odds</div>
                            <div style={{ width: '15%' }}>Stake</div>
                            <div style={{ width: '15%' }}>Profit</div>
                        </div>
                        <div>
                            {matchBetList && matchBetList.length > 0 ? matchBetList.map((element, index) => (
                                <>
                                    <div
                                        className="d-flex justify-content-start align-items-center ps-1"
                                        style={{
                                            backgroundColor: element.is_back === "1" ? "#beddf4" : "#f3dce2",
                                            color: "#3b5160",
                                            fontSize: "10px",
                                        }}
                                    >
                                        Ref: {moment.unix(element.created_at).format("DD-MM-YYYY HH:MM:SS")}
                                    </div>
                                    <div
                                        className="d-flex justify-content-between teamname-div teamNameFancy"
                                        style={{
                                            backgroundColor: element.is_back === "1" ? "#beddf4" : "#f3dce2",
                                            border: element.is_back === "1" ? "1px solid #7dbbe9" : "1px solid #dfa3b3",
                                        }}
                                    >
                                        <div className={`d-flex gap-2 ${element.is_back === "1" ? "teamname-bet" : "teamname-bet2"
                                            }`}
                                            style={{ width: "55%" }}
                                        >
                                            <div className="d-flex gap-2">
                                                <span className="d-flex align-items-center justify-content-center"
                                                    style={{
                                                        padding: "2px",
                                                        borderRadius: "5px",
                                                        color: "black",
                                                        fontWeight: "bold",
                                                        backgroundColor: element.is_back === "1" ? "#7dbbe9" : "#dfa3b3",
                                                        border: `1px solid ${element.is_back === "1" ? "#7dbbe9" : "#dfa3b3"}`,
                                                    }}
                                                >
                                                    {element.is_back === "1" ? "Back" : "Lay"}
                                                </span>
                                                <span>
                                                    <div><strong>{element?.selectionName}</strong></div>
                                                    <div>({element?.marketName})</div>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-center align-items-center" style={{ width: "15%" }}>
                                            <span className="text-center">{element?.odds}</span>
                                        </div>
                                        <div className="d-flex justify-content-center align-items-center" style={{ width: "15%" }}>
                                            <span className="text-center">{element?.stack}</span>
                                        </div>
                                        <div className="d-flex justify-content-center align-items-center" style={{ width: "15%" }}>
                                            <span className="text-center">{element?.p_l}</span>
                                        </div>
                                    </div>
                                </>
                            )) : null}
                        </div>
                    </div> : null
                }
            </div>

        </div>
    )
}

export default RightSidebarDetailsPage
