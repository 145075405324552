import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link, useLocation, useParams } from "react-router-dom";
import { ourSlides, SportMatchOuterList, matchSeriesList } from "../../redux/_reducers/_reducers";
import MobileSlideNav from "../../components/MobileSlideNav/MobileSlideNav";
import CasinoListNav from "../../components/CasinoListNav/CasinoListNav";
import Footer from "../../components/Footer/Footer";
import MobileMenu from "../../components/Mobile-Menu/Mobilemenu";
import LotteryImages from "../../components/LotteryImages/LotteryImages";
import SecondBets from "../../components/SecondBets/SecondBets";
import Loader from "../../components/Loader/Loader";
import RightSidebar from "../../components/RightSidebar/RightSidebar";
import Blink from "../../components/BlinkComponent/Blink";


function InplayPage() {
  // const [matchlistData, setMatchlistData] = useState("");
  const [matchSeriesData, setMatchSeriesData] = useState(null);
  const [inplayMatches, setInplayMatches] = useState([]);
  const [slideData, setslideData] = useState("");
  const [getSportOuterList, setGetSportOuterList] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [filteredMatchData, setFilteredMatchData] = useState([]);
  const [filteredInplayMatchData, setFilteredInplayMatchData] = useState([]);
  const [filteredUpcomingMatchData, setFilteredUpcomingMatchData] = useState([]);
  const [activeNav, setActiveNav] = useState(4);
  const [activeCasinoTab, setActiveCasinoTab] = useState(5000);
  const [loading, setLoading] = useState('');
  const { ourSlidesData, SportMatchOuterListData, matchSeriesListData, defaultSettingData } = useSelector(
    (state) => state.authentication
  );
  const marequeeText = defaultSettingData?.find((item) => item.key === "site.MESSAGE");

  const dispatch = useDispatch();
  const location = useLocation();
  // Check if the URL contains a numeric segment
  const isUrlDynamic = /\d+/.test(location.pathname);

  const { sportsId } = useParams();


  useEffect(() => {
    const payload = { limit: 100, pageno: 1, sport_id: 0, series_id: 0, type: 'h' };
    dispatch(matchSeriesList(payload));
  }, [dispatch]);

  useEffect(() => {
    if (matchSeriesListData) {
      setMatchSeriesData(matchSeriesListData);
    }
  }, [matchSeriesListData]);

  useEffect(() => {
    if (matchSeriesData?.InplayMatches) {
      setInplayMatches(matchSeriesData?.InplayMatches);
    }
  }, [matchSeriesData]);



  useEffect(() => {
    const payload = { limit: 10, pageno: 1 };
    dispatch(SportMatchOuterList(payload));
  }, [dispatch]);

  useEffect(() => {
    if (SportMatchOuterListData) {
      setGetSportOuterList(SportMatchOuterListData);
    }
  }, [SportMatchOuterListData]);

  const formatDate = (unixTimestamp) => {
    const date = new Date(unixTimestamp * 1000);
    const options = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric', 
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,
    };
    return date.toLocaleString('en-GB', options);
  };

  // Group matches by sport_id
  const groupedMatches = inplayMatches.reduce((acc, match) => {
    const sportId = match.sport_id; // Assuming `sport_id` exists
    if (!acc[sportId]) {
      acc[sportId] = [];
    }
    acc[sportId].push(match);
    return acc;
  }, {});

  // Sport names for headings
  const sportNames = {
    4: "Cricket",
    2: "Tennis",
    1: "Soccer",
  };


  const loggedIn = localStorage.getItem("user");

  return (
    <div className="row mx-0">
      <div className="score-tabcontent-work">
        <div className="tab-content tab-main-work" id="v-pills-tabContent">
          {loggedIn && (
            <>
              <div className="col-md-12 text-white font-bold d-flex align-items-center marquee-text">
                <span className="text-light d-flex align-items-center">
                  <i className="fa fa-microphone"></i> News{" "}
                </span>
                <marquee class="marq" bgcolor=""
                  direction="left" loop="">
                  <div class="geek2">
                    {marequeeText?.value}
                  </div>
                </marquee>
              </div>
              <div>
                <button className="inplayBtn ms-1">In-Play</button>
              </div>
            </>
          )}
          <div
            className="tab-pane fade show active "
            id="v-pills-home"
            role="tabpanel"
            aria-labelledby="v-pills-home-tab"
          >
            <div className="d-none d-lg-block">
              <div>
                {
                  loading ? (
                    <Loader />
                  ) : (
                    <>
                      <div>
                        {Object.keys(groupedMatches).length > 0 ? (
                          Object.keys(groupedMatches).map((sportId) => (
                            <div key={sportId} className="mb-2 mt-2">
                              <div className="textHeading">{sportNames[sportId] || "Other Sports"}</div>
                              <table className="bet-table-chart" id="bet-table-chart">
                                <thead>
                                  <tr>
                                    <th></th>
                                    <th></th>
                                    <th>
                                      <div className="width">
                                        <span>1</span>
                                      </div>
                                    </th>
                                    <th>
                                      <div className="width">
                                        <span>2</span>
                                      </div>
                                    </th>
                                    <th>
                                      <div className="width">
                                        <span>x</span>
                                      </div>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {groupedMatches[sportId].map((item, index) => (
                                    <tr key={index}>
                                      <td className="bet-table-chart-p">
                                        <span>
                                          {loggedIn ?
                                            <Link className="d-flex align-items-center gap-1" to={`/app/view-details/${item?.sport_id}/${item?.match_id}`}>
                                              {item?.name} <span className="text-secondary font-semibold mx-2">{formatDate(item?.start_date)}</span>
                                              <Blink />
                                            </Link> :
                                            <Link className="d-flex align-items-center gap-1" to={`/viewdetail/${item?.sport_id}/${item?.match_id}`}>
                                              {item?.name} <span className="text-secondary font-semibold mx-2">{formatDate(item?.start_date)}</span>
                                              <Blink />
                                            </Link>
                                          }
                                          {/* <Link
                                              className="d-flex align-items-center gap-1"
                                              to={`/app/view-details/${item?.sport_id}/${item?.match_id}`}
                                            >
                                              {item?.name}
                                              <span className="text-secondary font-semibold mx-2">
                                                {formatDate(item?.start_date)}
                                              </span>
                                              <Blink />
                                            </Link> */}
                                        </span>
                                      </td>
                                      <td>
                                        <div className="d-flex flex-wrap gap-2 cricketSpecificUi justify-content-center bm-s-f">
                                          {Number(item?.sport_id) === 4 && (
                                            <span className="cricketS">M</span>
                                          )}
                                          {Number(item?.sport_id) === 4 && (
                                            <span className="cricketBm">BM</span>
                                          )}
                                          {Number(item?.sport_id) === 4 && (
                                            <span className="cricketF">F</span>
                                          )}
                                        </div>
                                      </td>
                                      <td>
                                        <div className="width">
                                          <span>{Math.floor(Math.random() * 100)}</span>
                                          <span>{Math.floor(Math.random() * 100)}</span>
                                        </div>
                                      </td>
                                      <td>
                                        <div className="width">
                                          <span>{Math.floor(Math.random() * 100)}</span>
                                          <span>{Math.floor(Math.random() * 100)}</span>
                                        </div>
                                      </td>
                                      <td>
                                        <div className="width">
                                          <span>{Math.floor(Math.random() * 100)}</span>
                                          <span>{Math.floor(Math.random() * 100)}</span>
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          ))
                        ) : (
                          <p>No matches found</p>
                        )}
                      </div>
                    </>
                  )
                }
              </div>
            </div>

            <div className="tab-content inner-data d-lg-none d-block" id="myTabContent">
              <div className="table-container">
                {
                  loading ? (
                    <Loader />
                  ) : (
                    <>
                      <div style={{ maxHeight: '180px', overflowY: 'auto' }}>
                        <div>
                          {Object.keys(groupedMatches).length > 0 ? (
                            Object.keys(groupedMatches).map((sportId) => (
                              <div key={sportId} className="mb-2 mt-2">
                                <div className="textHeading">{sportNames[sportId] || "Other Sports"}</div>
                                <div className="table-responsive">
                                  <table className="bet-table-chart" id="bet-table-chart">
                                    <thead>
                                      <tr>
                                        <th></th>
                                        <th></th>
                                        <th>
                                          <div className="width">
                                            <span>1</span>
                                          </div>
                                        </th>
                                        <th>
                                          <div className="width">
                                            <span>2</span>
                                          </div>
                                        </th>
                                        <th>
                                          <div className="width">
                                            <span>x</span>
                                          </div>
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {groupedMatches[sportId].map((item, index) => (
                                        <tr key={index}>
                                          <td className="bet-table-chart-p">
                                            <span>
                                              {loggedIn ?
                                                <Link className="d-flex flex-column align-items-start" to={`/app/view-details/${item?.sport_id}/${item?.match_id}`}>
                                                  {item?.name} <span className="text-secondary font-semibold ">{formatDate(item?.start_date)} <Blink /></span>
                                                  {/* <Blink /> */}
                                                </Link> :
                                                <Link className="d-flex flex-column align-items-start" to={`/viewdetail/${item?.sport_id}/${item?.match_id}`}>
                                                  {item?.name}<span className="text-secondary font-semibold ">{formatDate(item?.start_date)}  <Blink /></span>
                                                  {/* <Blink /> */}
                                                </Link>
                                              }
                                            </span>
                                          </td>
                                          <td>
                                            <div className="d-flex flex-wrap gap-2 cricketSpecificUi justify-content-center bm-s-f me-2">
                                              {Number(item?.sport_id) === 4 && (
                                                <span className="cricketS">M</span>
                                              )}
                                              {Number(item?.sport_id) === 4 && (
                                                <span className="cricketBm">BM</span>
                                              )}
                                              {Number(item?.sport_id) === 4 && (
                                                <span className="cricketF">F</span>
                                              )}
                                            </div>
                                          </td>
                                          <td>
                                            <div className="width">
                                              <span>{Math.floor(Math.random() * 100)}</span>
                                              <span>{Math.floor(Math.random() * 100)}</span>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="width">
                                              <span>{Math.floor(Math.random() * 100)}</span>
                                              <span>{Math.floor(Math.random() * 100)}</span>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="width">
                                              <span>{Math.floor(Math.random() * 100)}</span>
                                              <span>{Math.floor(Math.random() * 100)}</span>
                                            </div>
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            ))
                          ) : (
                            <p>No matches found</p>
                          )}
                        </div>
                      </div>
                    </>
                  )
                }
              </div>
            </div>
            {
              !isUrlDynamic && (
                <CasinoListNav activeCasinoTab={activeCasinoTab} setActiveCasinoTab={setActiveCasinoTab} />
              )
            }
            <Footer />
          </div>
          <MobileMenu />
        </div>
      </div>
      {/* <div className="col-lg-3 score-tabbets ">
        <RightSidebar />
      </div> */}
    </div>
  );
}
export default InplayPage;
