import { useEffect, useState } from "react"
import { myBetList } from "../../../redux/_reducers/_reducers"
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Loader from "../../../components/Loader/Loader";
import RightSidebar from "../../../components/RightSidebar/RightSidebar";
import ReactPaginate from "react-paginate";
import { userchatlist } from "../../../redux/_reducers/_reducers";

function DepositeWithdrawHistory() {
    const dispatch = useDispatch();
    const [fromDate, setFromDate] = useState(moment().subtract(7, 'days').unix());
    const [toDate, setToDate] = useState(moment().unix());
    const [page, setPage] = useState('100');
    const [userChatListData, setUserChatListData] = useState([]);
    const [fetchedData, setFetchedData] = useState([]);
    const [betType, setBetType] = useState('AL');
    const [sports, setSports] = useState("AL");
    const [currentPage, setCurrentPage] = useState(0);

    const itemsPerPage = page;

    const { userchatlistData, loading } = useSelector(
        (state) => state.authentication
    );
    const handleBetTypeChange = (e) => {
        setBetType(e.target.value);
    };

    const handleSportsChange = (e) => {
        setSports(e.target.value);
    };

    useEffect(() => {
        if (userchatlistData) {
            setUserChatListData(userchatlistData);
        }
    }, [userchatlistData]);

    const changeData = (fetchedData) => {
        if (fetchedData) {
            const updatedLedgerData = fetchedData.map((data, index) => {
                return {
                    Date: moment.unix(data.Placed).utcOffset("+05:30").format("DD-MM-YYYY"),
                    SrNo: index + 1,
                    Eventname: data.matchName,
                    Nation: data.SelectionName,
                    Result: data.winResult ? data.winResult : "Not Declered",
                    Side: data.Type,
                    Odds: data.Odds,
                    Stack: data.Stack,
                    PL: data.Liability,
                    BetStatus: data.Liability > 0 ? "Win" : "Loss",
                    IpAddress: data.ipAddress,
                };
            });
            setFetchedData(updatedLedgerData);
        }
    };

    const handleSaveDates = async (e) => {
        if (e) e.preventDefault();
        if (fromDate && toDate) {
            const fetchData = async () => {
                try {
                    let reqData =
                    {
                        // "from_date": fromDate,
                        // "to_date": toDate,
                        "limit": page,
                        "page": 1,
                        "status": sports,
                        "type": betType
                    }
                    // {
                    // from_date: fromDate,
                    // to_date: toDate,
                    // limit: page,
                    // sport_id: sports,
                    // betType: betType,
                    // pageno:1,
                    // market_id:"0",
                    // match_id:0,
                    // };
                    const response = await dispatch(userchatlist(reqData));
                    setFetchedData(response.payload?.data);
                    changeData(response.payload?.data)
                } catch (err) {
                    console.error('Failed to fetch statement:', err);
                }
            };
            fetchData();
        }
    };

    useEffect(() => {
        handleSaveDates();
    }, [page]);

    const handleDateChange = (e, type) => {
        const value = e.target.value;
        const unixTimestamp = Math.floor(new Date(value).getTime() / 1000);
        if (type === 'from') {
            setFromDate(unixTimestamp);
        } else if (type === 'to') {
            setToDate(unixTimestamp);
        }
    };
    const formatDate = (unixTimestamp) => {
        const date = new Date(unixTimestamp * 1000);
        const options = {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric', 
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: true,
        };
        return date.toLocaleString('en-GB', options);
      };


    const handlePageNumber = (event) => {
        setPage(event.target.value);
    };


    return (
        <>
            <div className="score-tabcontent-work p-2">
                {loading && <Loader />}
                <div className="row rollingform1 mx-0">
                    <div className="col-lg-6 col-md-6 col-6 my-profile-select">
                        <select value={betType} onChange={handleBetTypeChange}>
                            <option value="Bet Type">Bet Type</option>
                            <option value="D">Deposit</option>
                            <option value="W">Withdraw</option>
                        </select>
                    </div>
                    <div className="col-lg-6 col-md-6 col-6 my-profile-select  my-profile-select2">
                        <select value={sports} onChange={handleSportsChange}>
                            <option value="AL">Status</option>
                            <option value="P">Pending</option>
                            <option value="C">Cancelled</option>
                            <option value="A">Approved</option>
                            <option value="D">Decline</option>
                        </select>
                    </div>

                    {/* <div className="col-lg-4 col-md-4 col-12 my-profile-select">
                        <select>
                            <option value="">Settle</option><option value="LIVE">LIVE DATA</option>
                            <option value="BACKUP">BACKUP DATA</option><option value="OLD">OLD DATA</option>
                        </select>
                    </div> */}

                    <div className="col-lg-4 col-md-4  col-12  my-profile-select mt-3">
                        <input className="position-relative abc"
                            type="date" id="date"
                            value={moment.unix(fromDate).format("DD-MM-YYYY")}
                            onChange={(e) => handleDateChange(e, 'from')}
                        />
                    </div>

                    <div className="col-lg-4 col-md-4 col-12 my-profile-select mt-3">
                        <input className="position-relative abc" type="date" id="date"
                            value={moment.unix(toDate).format("DD-MM-YYYY")}
                            onChange={(e) => handleDateChange(e, 'to')}
                        />
                    </div>

                    <div className="col-lg-4 col-md-4 col-12 my-profile-select mt-3">
                        <button onClick={handleSaveDates} className="form-btn border-0">Get Commission</button>
                    </div>

                </div>
                <div className="account-statment-tablwork">
                    <div className="account-statment-table">
                        <div className="roling-table border">
                            <div className="rolling-commission-div">
                                <h4>Bet History</h4>
                            </div>
                            <div className="commission-table account-statment-tables">
                                <div className="account-lable d-flex flex-wrap align-items-center">
                                    <p className="mb-0">Show</p>
                                    <label className="mx-1">
                                        <select value={page}
                                            onChange={handlePageNumber}
                                        >
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select>
                                    </label>
                                    <p className="mb-0">entries</p>
                                </div>
                                <table className="table mb-0">
                                    <thead>
                                        <tr>
                                            <th scope="col">
                                                <div className="d-flex flex-nowrap align-items-center justify-content-center">
                                                    <p className="text-nowrap">S No.</p>
                                                    <i className="fa fa-sort" aria-hidden="true"></i>
                                                </div>
                                            </th>
                                            <th scope="col">
                                                <div className="d-flex flex-nowrap align-items-center justify-content-center">
                                                    <p className="text-nowrap">Account Detail</p>
                                                    <i className="fa fa-sort" aria-hidden="true"></i>
                                                </div>
                                            </th>
                                            <th scope="col">
                                                <div className="d-flex flex-nowrap align-items-center justify-content-center">
                                                    <p className="text-nowrap">Payement</p>
                                                    <i className="fa fa-sort" aria-hidden="true"></i>
                                                </div>
                                            </th>
                                            <th scope="col">
                                                <div className="d-flex flex-nowrap align-items-center justify-content-center">
                                                    <p className="text-nowrap">Status</p>
                                                    <i className="fa fa-sort" aria-hidden="true"></i>
                                                </div>
                                            </th>
                                            <th scope="col">
                                                <div className="d-flex flex-nowrap align-items-center justify-content-center">
                                                    <p className="text-nowrap">Reason</p>
                                                    <i className="fa fa-sort" aria-hidden="true"></i>
                                                </div>
                                            </th>
                                            <th scope="col">
                                                <div className="d-flex flex-nowrap align-items-center justify-content-center">
                                                    <p className="text-nowrap">Type</p>
                                                    <i className="fa fa-sort" aria-hidden="true"></i>
                                                </div>
                                            </th>
                                            <th scope="col">
                                                <div className="d-flex flex-nowrap align-items-center justify-content-center">
                                                    <p className="text-nowrap">Amount</p>
                                                    <i className="fa fa-sort" aria-hidden="true"></i>
                                                </div>
                                            </th>
                                            <th scope="col">
                                                <div className="d-flex flex-nowrap align-items-center justify-content-center">
                                                    <p className="text-nowrap">Date</p>
                                                    <i className="fa fa-sort" aria-hidden="true"></i>
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {userChatListData && userChatListData.length > 0 ? (
                                            userChatListData.map((ele, i) => (
                                                <tr key={i}>
                                                    <td className="border text-center">
                                                        {i + 1}
                                                    </td>
                                                    <td
                                                        className="border text-center"
                                                    >
                                                        {ele?.accountHolder ? (<>  Account Holder Name: {ele.accountHolder}  <br />  </>) : null}
                                                        {ele?.accountNumber ? (<>   Account Number : {ele?.accountNumber}  <br />  </>) : null}
                                                        {ele?.ifscCode ? (<>  IFSC Code :{ele?.ifscCode}  </>) : null}
                                                    </td>
                                                    <td
                                                        className="border text-center"
                                                    >
                                                        {ele?.paymentMethod}
                                                    </td>
                                                    <td
                                                        className="border text-center"
                                                    >
                                                        {
                                                            ele?.status === "P" ? "Pending" :
                                                                ele?.status === "A" ? "Approved" :
                                                                    ele?.status === "C" ? "Cancelled" :
                                                                        ele?.status === "D" ? "Declined" :
                                                                            "-"
                                                        }

                                                    </td>
                                                    <td className="border text-center">
                                                        {ele?.reason ? ele?.reason : "-"}
                                                    </td>
                                                    <td className="border text-center"                                                    >
                                                        {ele?.requestMethod === "D" ? "Deposit" : "Withdraw"}
                                                    </td>
                                                    <td className="border text-center"                                                    >
                                                        {ele?.amount}
                                                    </td>
                                                    {/* <td className="border text-center">
                                                {ele?.amount}
                                            </td> */}
                                                    <td className="border text-center">
                                                        {formatDate(ele?.created_at)}
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colspan="8" className="text-center">
                                                    <p>No data!</p>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="col-lg-3 score-tabbets " style={{ top: '100px' }}>
                <RightSidebar />
            </div> */}
        </>
    )
}

export default DepositeWithdrawHistory
