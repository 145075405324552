import React from 'react'

import { Link, useNavigate } from 'react-router-dom'

const ComingSoon = ({handleClose}) => {
    // const handleClose = ()=>{
    //     alert('hyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy')
    // }
    const navigate = useNavigate();
    return (
        <section className="pop-up-sec text-center text-white ">
            <div className="pop-up-sec-work position-relative">
                <div className="container-fluid">
                    <h6>This casino is coming soon</h6>
                </div>
                <div onClick={()=>handleClose()} className="cross-icon-popup position-absolute top-0 end-0">
                    <i className="fa fa-times" aria-hidden="true"></i>
                </div>
            </div>
        </section>
    )
}

export default ComingSoon